import _ from 'lodash';

export class GrammarUtil {
  public static apostrophePossesion(nameToAddTo: string | null): string {
    if (!nameToAddTo) {
      return '';
    }

    const regex = /[s]$/g;
    const found = nameToAddTo.match(regex);
    if (found) {
      return `${nameToAddTo}'`;
    }
    return `${nameToAddTo}'s`;
  }
}
