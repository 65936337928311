import { ApolloQueryResult, FetchResult, gql } from '@apollo/client';
import { useApi } from './use-api';
import { ClientLogger } from '../client-logger';
import {
  loginMutation,
  meQuery,
  refreshTokenMutation,
  passwordResetStartMutation,
  serverInfoQuery,
  resetDataMutation,
  resetDataMutationVariables,
  LoginInput,
} from './api-types';
import { me_me, me_me_personOrgs, me_me_personOrgs_org } from './api-types-parts';
import jwt_decode from 'jwt-decode';
import { getVeridApolloClient } from './apollo-client';
import { useContext } from 'react';
import { blankUserState, UserContext, UserContextUtils } from 'lib/auth/user-context';
import { useErrorHandler } from 'lib/use-error-handler';
import { ROUTES } from 'Routes';

const DEBUG = false;

export const tokenHolder: { accessToken: string | undefined; jwt: JsonWebToken | undefined } = {
  accessToken: undefined,
  jwt: undefined,
};
export interface JsonWebToken {
  email: string;
  exp: number;
  userId: string | undefined;
  sub?: string | undefined; // sub is legacy userId that verid server can accept
}

export type Me = Omit<me_me, '__typename'>;
export type Org = Omit<me_me_personOrgs_org, '__typename'>;
export type PersonOrg = Omit<me_me_personOrgs, '__typename'>;

export const NEW_AUTH_MESSAGE_ID = 'login_state_message';

function decodeToken(token: string) {
  return jwt_decode(token) as JsonWebToken;
}
function updateJwtToken(accessToken: string | undefined): void {
  DEBUG && ClientLogger.debug('AuthClient.updateJwtToken', `accessToken = ${accessToken}`);
  tokenHolder.accessToken = accessToken;
  tokenHolder.jwt = accessToken ? decodeToken(accessToken) : undefined;
  DEBUG && ClientLogger.debug('AuthClient.updateJwtToken', `sending message`);
  window.postMessage(NEW_AUTH_MESSAGE_ID);
}

export async function refreshTokenFct(): Promise<FetchResult<refreshTokenMutation>> {
  const client = getVeridApolloClient();
  const result = await client.mutate({
    mutation: gql`
      mutation refreshToken {
        refreshToken {
          accessToken
        }
      }
    `,
    variables: {},
    context: { noAuth: true },
    errorPolicy: 'all',
  });
  DEBUG && ClientLogger.debug('AuthClient.refreshToken', 'response', { result });
  if (result.data?.refreshToken?.accessToken) {
    updateJwtToken(result.data.refreshToken.accessToken);
  } else {
    ClientLogger.log('refreshTokenFct', 'No or stale refresh token');
    updateJwtToken(undefined);
  }

  return result;
}

export function isJwtExpired(): boolean {
  const expiry = tokenHolder.jwt?.exp;
  if (!expiry) {
    return true;
  }
  const curDate = Date.now();
  const cur = curDate / 1000;
  return expiry < cur;
}

export async function logout(to?: string) {
  const client = getVeridApolloClient();
  DEBUG && ClientLogger.debug('AuthClient', 'logout - started', { tokenHolder });
  const result = await client.mutate({
    mutation: gql`
      mutation logOut {
        logOut
      }
    `,
    variables: {},
    context: { noAuth: true },
  });
  DEBUG && ClientLogger.debug('AuthClient', 'logout - response', result);
  updateJwtToken(undefined);

  const redirect = to || ROUTES.HOME;
  ClientLogger.log('AuthClient.logout', `Logging out...`);
  if (redirect !== window.location.pathname) {
    ClientLogger.log('AuthClient.logout', `redirecting to ${redirect}...`);
    window.location.assign(redirect);
  }
}

export const PERSON_FIELDS = gql`
  fragment PersonFragment on Person {
    id
    createdAt
    updatedAt
    title
    firstName
    middleName
    lastName
    gender
    dateOfBirth
    attributes
    isTest
    status
    currentOrg
    systemAccount
  }
`;

export const PERSON_ORG_FIELDS = gql`
  fragment PersonOrgFragment on PersonOrg {
    id
    email
    role
    orgId
    personId
  }
`;

export const PERSON_VERIFICATION_FIELDS = gql`
  fragment PersonVerificationFragment on PersonVerification {
    id
    createdAt
    updatedAt
    methodId
    personId
    title
    firstName
    middleName
    lastName
    dateOfBirth
    gender
    idTypeId
    idNumber
    idExpiry
    status
  }
`;

export function useAuthService() {
  const api = useApi();
  const userContext = useContext(UserContext);
  const errorHandler = useErrorHandler('login');

  return {
    async login(data: LoginInput): Promise<FetchResult<loginMutation>> {
      const result = await api.mutate({
        mutation: gql`
          mutation login($data: LoginInput!) {
            login(data: $data) {
              accessToken
            }
          }
        `,
        variables: { data },
        context: { noAuth: true },
      });
      DEBUG && ClientLogger.debug('AuthClient.login', 'response', { result });
      if (result.data?.login?.accessToken) {
        updateJwtToken(result.data.login.accessToken);
      } else {
        ClientLogger.error('login', 'Error', result.data?.login?.error);
      }

      return result;
    },

    async passwordResetStart(email: string): Promise<FetchResult<passwordResetStartMutation>> {
      const result = await api.mutate({
        mutation: gql`
          mutation passwordResetStart($email: String!) {
            passwordResetStart(email: $email)
          }
        `,
        variables: { email },
        context: { noAuth: true },
      });
      DEBUG && ClientLogger.debug('AuthClient.passwordResetStart', 'response', { result });

      return result;
    },

    async passwordResetFinish(token: string, newPassword: string): Promise<FetchResult<loginMutation>> {
      const result = await api.mutate({
        mutation: gql`
          mutation passwordResetFinish($token: String!, $newPassword: String!) {
            passwordResetFinish(token: $token, newPassword: $newPassword)
          }
        `,
        variables: { token, newPassword },
        context: { noAuth: true },
      });
      DEBUG && ClientLogger.debug('AuthClient.passwordResetFinish', 'response', { result });

      return result;
    },

    async changePassword(personOrgId: string, oldPassword: string, newPassword: string) {
      return api.mutate({
        mutation: gql`
          mutation changePassword($personOrgId: String!, $newPassword: String!, $oldPassword: String!) {
            changePassword(personOrgId: $personOrgId, data: { newPassword: $newPassword, oldPassword: $oldPassword }) {
              id
            }
          }
        `,
        variables: { data: { personOrgId, newPassword, oldPassword } },
      });
    },

    async getMe(): Promise<ApolloQueryResult<meQuery> | null> {
      try {
        DEBUG && ClientLogger.debug('useCsaApi.getMe', `Called: me`, tokenHolder);
        if (tokenHolder.accessToken) {
          const resp = await api.query({
            query: gql`
              ${PERSON_FIELDS}
              ${PERSON_ORG_FIELDS}
              query me {
                me {
                  ...PersonFragment
                  personOrgs {
                    ...PersonOrgFragment
                    org {
                      id
                      name
                      type
                    }
                  }
                }
              }
            `,
            variables: {},
            fetchPolicy: 'network-only',
          });
          DEBUG && ClientLogger.debug('useCsaApi.getMe', 'Response', resp);
          if (resp && resp.data && resp.data.me) {
            DEBUG && ClientLogger.debug('getMe', 'setting me', resp);
            const theNewMe = resp.data.me;
            userContext.setUserState({ me: theNewMe, isLoggedIn: true, ready: true });
          } else {
            DEBUG && ClientLogger.debug('getMe', 'me retrieval failed', resp);
            errorHandler.handleQueryResponse(resp);
          }
          return resp;
        } else {
          DEBUG && ClientLogger.debug('getMe', 'me called without token - setting to not logged in');
          const newState = { ...blankUserState };
          newState.ready = true;
          userContext.setUserState(newState);
          return null;
        }
      } catch (error) {
        ClientLogger.error('getMe', 'Catch', error);
        return null;
      }
    },

    logout,

    async serverInfo(): Promise<ApolloQueryResult<serverInfoQuery>> {
      DEBUG && ClientLogger.debug('serverInfo', `started`);
      const resp = await api.query<serverInfoQuery, any>({
        query: gql`
          query serverInfo {
            serverInfo {
              branch
              buildDate
              buildId
              commit
              dbConnectTest
              reactAppAddress
            }
          }
        `,
        variables: {},
        fetchPolicy: 'network-only',
        context: { noAuth: true },
      });
      DEBUG && ClientLogger.debug('serverInfo', 'Response', resp);
      return resp;
    },

    async resetData(comment: string, branch: string): Promise<FetchResult<resetDataMutation>> {
      ClientLogger.warning('resetData', `started`);
      const resp = await api.mutate<resetDataMutation, resetDataMutationVariables>({
        mutation: gql`
          mutation resetData($comment: String!, $branch: String!) {
            resetData(comment: $comment, branch: $branch)
          }
        `,
        variables: { comment, branch },
        fetchPolicy: 'network-only',
      });
      DEBUG && ClientLogger.debug('resetData', 'Response', resp);
      return resp;
    },
  };
}
