import { Button, Checkbox, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { OrgType } from 'lib/api/api-types';
import { useOrgService } from 'lib/api/use-org-service';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useRef } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { Identifier } from 'ui-lib';

interface IValues {
  id: string;
  name: string;
  description: string;
  isTest: boolean;
}

interface IProps {
  editingId?: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  orgType: OrgType;
  onClose: () => void;
  initialValues: IValues;
}

const DEBUG = false;

export function EditOrgOverview(props: IProps) {
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const formRef = useRef<FormikProps<IValues>>(null);
  const userContext = useContext(UserContext);
  const dialogTitle = props.editingId ? `Edit ${props.initialValues.name}` : `Adding`;

  async function save(values: IValues) {
    if (props.editingId) {
      const resp = await orgService.orgUpdate(props.editingId, {
        name: values.name,
        description: values.description,
        isTest: values.isTest,
      });
      DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp });
      if (resp.data?.orgUpdate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    } else {
      if (!values.id) {
        errorHandler.handleErrors({ error: 'No id' });
      } else {
        const jurisdictionId = userContext.$.currentOrgId();
        DEBUG && ClientLogger.debug('EditOrg', 'creating org', { jurisdictionId });
        if (!jurisdictionId) {
          errorHandler.handleErrors({ error: 'No current org' });
        } else {
          const resp = await orgService.orgCreate({
            id: values.id.toLowerCase(),
            description: values.description,
            name: values.name,
            jurisdictionId: jurisdictionId,
            type: props.orgType,
            isTest: values.isTest,
          });
          DEBUG && ClientLogger.debug('EditOrg', 'create org', { resp });
          if (resp.data?.orgCreate.id) {
            props.onClose();
          } else {
            errorHandler.handleErrors({ graphQLErrors: resp.errors });
          }
        }
      }
    }
  }

  DEBUG && ClientLogger.debug('EditOrg', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={props.initialValues}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('CreatePatient', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        DEBUG && ClientLogger.debug('EditOrg', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm });
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="name"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="name-textfield"
                sx={dialogClasses.inputField}
              />
              {props.editingId ? (
                <Field
                  component={TextField}
                  name="id"
                  label="Id"
                  placeholder="ide"
                  type="text"
                  fullWidth={true}
                  variant="outlined"
                  data-test="name-textfield"
                  disabled={true}
                  sx={dialogClasses.inputField}
                />
              ) : (
                <Identifier name="id" fullWidth={true} />
              )}
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                placeholder="Enter a description of this organization"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="new-patient-middle-name-textfield"
                sx={dialogClasses.inputField}
              />
              <Field as={FormControlLabel} type="checkbox" name="isTest" control={<Checkbox />} label="Test Organization" />
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
