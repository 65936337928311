import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Link, Typography } from '@mui/material';
import { AgreementStatus } from 'lib/api/api-types';
import { AgreementRequired, useAgreementService } from 'lib/api/use-agreement-service';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { dialogClasses } from 'style/sharedCssClasses';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';

interface IProps {
  logout: () => void;
}

const DEBUG = false;

function calcTitle(agreement: AgreementRequired | undefined) {
  if (!agreement) {
    return '';
  }
  return `${agreement?.agreementName} (v${agreement.version}) for ${agreement.orgName}`;
}

export function AgreementCheck(props: IProps) {
  const agreementSerivce = useAgreementService();
  const userContext = useContext(UserContext);
  const errorHandler = useErrorHandler('AgreementCheck');
  const [agreementsRequired, setAgreementsRequired] = useState([] as AgreementRequired[]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [viewAgreement, setViewAgreement] = useState<AgreementRequired | undefined>(undefined);
  const [rejectAgreement, setRejectAgreement] = useState<AgreementRequired | undefined>(undefined);
  const agreementOpen: boolean = agreementsRequired.length > 0;
  const toastDispatch = useContext(ToastDispatchContext);

  useEffect(() => {
    async function load() {
      const resp = await agreementSerivce.agreementsRequired();
      DEBUG && ClientLogger.debug('AgreementCheck', 'load', { resp });
      if (resp.data?.agreementsRequired) {
        setAgreementsRequired([...resp.data.agreementsRequired]);
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    }
    if (userContext.userState?.me?.id) {
      load();
    }
  }, [userContext.userState?.me?.id]);

  const agreeToAll = async () => {
    for (const agreement of [...agreementsRequired]) {
      await agreementAcceptanceCreate(AgreementStatus.ACCEPTED, agreement);
    }
  };

  const agreementAcceptanceCreate = async (status: AgreementStatus, agreement: AgreementRequired) => {
    setIsSubmitting(true);
    const resp = await agreementSerivce.agreementAcceptanceCreate({ agreementVersionId: agreement.versionId, status });
    DEBUG && ClientLogger.debug('AgreementCheck', 'agreementAcceptanceCreate', { resp });
    if (resp.data?.agreementAcceptanceCreate) {
      // remove the agreement from the list
      agreementsRequired.splice(
        agreementsRequired.findIndex((agreementSearch) => agreementSearch.agreementId === agreement.agreementId),
        1
      );
      setAgreementsRequired([...agreementsRequired]);
      if (status === AgreementStatus.REJECTED) {
        toastDispatch({ severity: SEVERITY.ERROR, msg: 'Agreement Rejected. Logging out...' });
        props.logout();
      } else {
        toastDispatch({
          severity: SEVERITY.SUCCESS,
          msg: `Acceptance of ${agreement.agreementName} for ${agreement.orgName}  Recorded`,
          autoClose: true,
        });
      }
    } else {
      errorHandler.handleErrors({ graphQLErrors: resp.errors });
    }
    setIsSubmitting(false);
  };
  if (!agreementOpen) {
    return null;
  }
  const dialogTitle = `Agreement Acknowledgement Required`;

  DEBUG && ClientLogger.debug('AgreementCheck', 'render', { agreementOpen, agreementsRequired });

  return (
    <>
      <Dialog open={agreementOpen} onClose={() => {}} maxWidth="sm" fullWidth>
        <DialogTitle sx={dialogClasses.dialogTitle}>{dialogTitle}</DialogTitle>
        <DialogContent>
          {agreementsRequired.map((agreement) => {
            return (
              <Card sx={{ minWidth: 275 }} key={agreement.agreementId}>
                <CardContent>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {agreement.orgName}{' '}
                    <Link onClick={() => setViewAgreement(agreement)}>
                      {agreement.agreementName} (v{agreement.version})
                    </Link>
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => setRejectAgreement(agreement)} size="small">
                    Reject
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => {
                      DEBUG && ClientLogger.debug('AgreementCheck', 'save');
                      await agreementAcceptanceCreate(AgreementStatus.ACCEPTED, agreement);
                    }}
                    size="small"
                  >
                    Accept
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={async () => {
              DEBUG && ClientLogger.debug('AgreementCheck', 'save');
              await agreeToAll();
            }}
          >
            Agree to All
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!viewAgreement}
        onClose={() => {
          setViewAgreement(undefined);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={dialogClasses.dialogTitle}>{calcTitle(viewAgreement)}</DialogTitle>
        <DialogContent>
          <Typography dangerouslySetInnerHTML={{ __html: viewAgreement?.content || '' }} />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={async () => {
              DEBUG && ClientLogger.debug('AgreementCheck', 'done reading');
              setViewAgreement(undefined);
            }}
          >
            Done Reading
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={!!rejectAgreement}
        onClose={() => {
          setRejectAgreement(undefined);
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={dialogClasses.dialogTitle}>Confirm</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to reject this agreement? If you do, your {rejectAgreement?.orgName} account will be removed.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            disabled={isSubmitting}
            onClick={() => {
              DEBUG && ClientLogger.debug('AgreementCheck', 'cancel confirm');
              setRejectAgreement(undefined);
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={isSubmitting}
            onClick={async () => {
              DEBUG && ClientLogger.debug('AgreementCheck', 'save');
              await agreementAcceptanceCreate(AgreementStatus.REJECTED, rejectAgreement as AgreementRequired);
              setRejectAgreement(undefined);
            }}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
