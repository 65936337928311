import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import logo from '../../assets/Logo.png';

import { HamburgerMenu } from './HamburgerMenu';

import { ROUTES } from 'Routes';
import makeStyles from '@mui/styles/makeStyles';

const useClasses = makeStyles({
  logo: {
    height: 16,
    cursor: 'pointer',
    verticalAlign: 'middle',
  },
});

export function Navbar() {
  const classes = useClasses();
  const navigate = useNavigate();

  const goHome = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <Box my={0.5} display="flex" flexDirection="row">
      <Box>
        <HamburgerMenu />
      </Box>
      <Box ml={1}>
        <img src={logo} alt="Healix™" onClick={goHome} className={classes.logo} />
      </Box>
    </Box>
  );
}
