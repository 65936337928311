import { Selector } from './index';
import { IdName, Util } from '../util';
import { Role } from '../lib/verid-api/verid-api-types';
import * as React from 'react';

interface IProps {
  name: string;
  selectMultiple?: boolean;
}

export function RoleSelector(props: IProps) {
  const choices: IdName[] = Util.idNamePairsFromEnum(Role);

  return (
    <Selector name={props.name} selectMultiple={props.selectMultiple} items={choices} label={props.selectMultiple ? 'Roles' : 'Role'} />
  );
}
