import { Box, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'Routes';

import healixLogo from '../../assets/Logo.png';

interface IProps {
  signinLink?: boolean;
}

const useClasses = makeStyles({
  container: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    padding: '12px 24px',
    backgroundColor: '#fff',
    zIndex: 1,
    sm: {
      padding: '12px 96px',
    },
  },
  logo: {
    height: 16,
    cursor: 'pointer',
  },
});

export function Header(props: IProps) {
  const classes = useClasses();
  const { signinLink } = props;
  const navigate = useNavigate();

  const goHome = () => {
    navigate(ROUTES.HOME);
  };
  return (
    <Grid container justifyContent="space-between" className={classes.container}>
      <Box>
        <img className={classes.logo} onClick={goHome} src={healixLogo} alt="Healix™" />
      </Box>
      {signinLink && (
        <Box>
          <Link to={ROUTES.LOGIN}>Sign In</Link>
        </Box>
      )}
    </Grid>
  );
}
