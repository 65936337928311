import { useContext } from 'react';
import { OrgListAuth, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import { Grid, Typography, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../Routes';
import { OrgAuthConfigType, AuthConfig } from 'verid-shared-lib';
import { getVeridApolloClientWithoutContext } from 'lib/api/apollo-client';
import { signupClasses } from 'style/sharedCssClasses';
import { theme } from 'style/theme';
import canadaFlag from '../../assets/Canada-Flag.svg';

const useClasses = makeStyles({
  ...signupClasses,
  healixLogo: {
    width: '225px',
  },
  ctaBox: {
    maxWidth: '90vw',
    padding: '24px',
    [theme.breakpoints.up('sm')]: {
      marginTop: '48px',
      width: '380px',
    },
  },
  flag: {
    height: '24px',
    marginRight: '4px',
    verticalAlign: 'middle',
  },
  btnSpacing: {
    margin: '6px auto',
    width: '80%',
    fontWeight: 'bold',
  },
  loginBtns: {
    width: '80%',
    display: 'flex',
    justifyContent: 'space-around',
  },
});

let callbackURL: string;
let currentAppId: string;

export function LoginOrgsList() {
  const classes = useClasses();
  const navigate = useNavigate();
  const toastDispatch = useContext(ToastDispatchContext);
  const apolloClientVerid = getVeridApolloClientWithoutContext();

  const getAuthUrl = (authConfig: any, state: string): string => {
    return (
      authConfig.authorizationEndpoint +
      `?client_id=${authConfig.clientId}` +
      `&redirect_uri=${Util.getEnvVar('REACT_APP_VERID_API_HOST')}/redirect` +
      `&state=${state}` +
      '&scope=openid profile email' +
      '&response_type=code' +
      '&response_mode=form_post' +
      '&nonce=roles'
    );
  };

  const onSelectAuthConfig = async (orgAuthConfig: any | null, currentOrgId: string) => {
    const authConfig = orgAuthConfig.authConfig;
    if (authConfig === null) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Org Auth is not completely configured` });
      return;
    }

    const roles = authConfig.roles;
    const authName = orgAuthConfig.authName;
    if (authConfig.type === OrgAuthConfigType.LOCALLY_PROVIDED || authConfig.type === OrgAuthConfigType.EXTERNALLY_PROVIDED) {
      //Need to configure and after validate user should navigate to their initiate place
      navigate(`${ROUTES.AUTH_ORG}/${currentOrgId}/authConfig/${orgAuthConfig.id}`);
    } else if (authConfig.type === OrgAuthConfigType.OPEN_ID) {
      //CurrentOrgId would be come from initiate app or select org
      const redirect = getAuthUrl(authConfig, JSON.stringify({ currentOrgId, currentAppId, roles, authName, callbackURL }));
      window.open(redirect, 'popup', 'width=600,height=600');
    }
  };

  return (
    <Grid container justifyContent="center" flexDirection="column">
      <Grid container item xs={12} md={6} direction="column" alignItems="center" className={classes.formContainer}>
        <Box className={classes.formHolder}>
          <Box>
            <Grid container alignItems="center" flexDirection="column">
              <Typography variant="h1" align="center">
                Welcome.
              </Typography>
              <OrgListAuth
                veridApolloClient={apolloClientVerid}
                readFromVerid={true}
                theme={theme}
                onSelectAuthConfig={(orgId, authConfig) => onSelectAuthConfig(authConfig, orgId)}
              />
              <Box mt={1}>
                <Box component="img" src={canadaFlag} alt="Canada Flag" className={classes.flag} />
                <Typography component="span" variant="subtitle2">
                  Proudly Canadian
                </Typography>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
