import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useContext, useRef } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useAgreementService } from 'lib/api/use-agreement-service';
import { Role } from 'lib/api/api-types';
import { RoleSelector, Switch } from 'ui-lib';
import { RichTextField } from 'components/form/RichTextField';
import { explanation } from './EditAgreement';

interface IValues {
  name: string;
  roles: Role[];
  content: string;
  requiresAcceptance: boolean;
}

interface IProps {
  orgId: string;
  onClose: () => void;
}

const DEBUG = false;

export function AddAgreement(props: IProps) {
  const agreementSerivce = useAgreementService();
  const errorHandler = useErrorHandler('AddAgreement');
  const formRef = useRef<FormikProps<IValues>>(null);
  const userContext = useContext(UserContext);
  const dialogTitle = `Adding and Agreement`;

  async function save(values: IValues) {
    const resp = await agreementSerivce.agreementCreate({
      orgId: props.orgId,
      name: values.name,
      roles: values.roles,
      content: values.content,
      requiresAcceptance: values.requiresAcceptance,
    });
    DEBUG && ClientLogger.debug('AddAgreement', 'save', { resp });
    if (resp.data?.agreementCreate.id) {
      props.onClose();
    } else {
      errorHandler.handleErrors({ graphQLErrors: resp.errors });
    }
  }

  DEBUG && ClientLogger.debug('AddAgreement', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{ name: '', roles: [Role.USER, Role.STAFF, Role.ADMIN], content: '', requiresAcceptance: true }}
      validate={(values: IValues) => {
        const errors: Partial<IValues> = {};
        if (!values.name) {
          errors.name = 'Required';
        }
        if (!values.content) {
          errors.content = 'Required';
        }
        DEBUG && ClientLogger.debug('AddAgreement', 'validate', { values, errors });
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('AddAgreement', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, isValid, touched, validateForm, dirty, setErrors }) => {
        DEBUG && ClientLogger.debug('EditOrg', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm, isValid });
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="name"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="name-textfield"
                sx={dialogClasses.inputField}
              />
              <RichTextField name="content" label="Agreement Text (required)" />
              <RoleSelector selectMultiple={true} name="roles" />
              <Switch name="requiresAcceptance" label="Requires acceptance" />
              <Typography variant="body1">
                {explanation({
                  saveAsNewVersion: false,
                  currentRequiresAcceptance: values.requiresAcceptance,
                  history: [],
                })}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isSubmitting && !isValid}
                onClick={async () => {
                  const valid = await validateForm(); //Hmm why does the props not work and I have to do this?
                  DEBUG && ClientLogger.debug('AddAgreement', 'save', { valid });
                  if (Object.keys(valid).length === 0) {
                    save(values);
                  } else {
                    setErrors(valid);
                  }
                }}
              >
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
