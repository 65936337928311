import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import React, { useContext } from 'react';

import { tokenHolder } from '../../lib/api/use-auth-service';
import { UserContext } from '../../lib/auth/user-context';
import { Util } from 'ui-lib';

import { ROUTES } from 'Routes';
import { TopBackBtnBar } from 'components/navigation/TopBackBtnBar';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useClasses = makeStyles({
  tableContainer: {
    marginTop: 48,
    padding: '0px 24px',
    minWidth: '850px',
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
    wordBreak: 'break-word',
  },
});

export function UserInfo() {
  const classes = useClasses();
  const userContext = useContext(UserContext);
  const navigate = useNavigate();
  const { userState } = userContext;
  const { me } = userState;
  const currentPersonOrg = userContext.$.currentPersonOrg();
  const jwt = tokenHolder.jwt;

  return (
    <Grid container justifyContent="center">
      <TopBackBtnBar title="User Info" />
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table aria-label="simple table" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Variable</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Name on Server</TableCell>
              <TableCell align="right">{Util.displayName(me)}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell scope="row">
                Email on Server
              </TableCell>
              <TableCell align="right">{me?.email}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">
                Phone # on Server
              </TableCell>
              <TableCell align="right">{me?.phone}</TableCell>
            </TableRow> */}
            <TableRow>
              <TableCell scope="row">User State - ME</TableCell>
              <TableCell align="right">{JSON.stringify(userContext.userState.me)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Current Org</TableCell>
              <TableCell align="right">{JSON.stringify(currentPersonOrg)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">JWT - User Id</TableCell>
              <TableCell align="right">{jwt?.userId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">JWT - Expiry</TableCell>
              <TableCell align="right">{jwt?.exp ? moment(jwt?.exp * 1000).format('YYYY-MMM-DD HH:mm:ss') : ''}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">JWT</TableCell>
              <TableCell align="right">{JSON.stringify(jwt)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Cookies</TableCell>
              <TableCell align="right">{JSON.stringify(document.cookie)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Authorization Header</TableCell>
              <TableCell style={{ maxWidth: '200px', wordWrap: 'break-word' }} align="right">
                "authorization": "Bearer {tokenHolder.accessToken}"
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Grid item xs={12} sm={6}>
        <Box my={2}>
          <Button
            variant="contained"
            onClick={(event) => {
              navigate(ROUTES.SERVER_INFO);
            }}
            fullWidth
          >
            Server Info
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}
