import { ApolloClient } from '@apollo/client';
import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import { useEffect, useState } from 'react';
import { useVeridService } from '../lib/verid-api/use-verid-service';
import { EMAIL_REGEX } from '../util';

interface IStyle {
  formContainer?: any;
  itemHolder?: any;
  form?: any;
}

interface IProps {
  veridApolloClient: ApolloClient<any>;
  orgId: string | null;
  authConfigId: string | null;
  style?: IStyle;
  theme?: any;
  onSubmit: (email: string, password: string, setSubmitting: (isSubmitting: boolean) => void) => void;
  showResetPassword?: boolean;
  showMagicLinkButton?: boolean;
  onClickMagicLink?: () => void;
  showSignUpRedirect?: boolean;
  onClickSignUpRedirect?: () => void;
}

interface IValues {
  email: string;
  password: string;
}

const DEBUG = false;

export function OrgLogin(props: IProps) {
  const {
    veridApolloClient,
    orgId,
    authConfigId,
    style,
    theme,
    onSubmit,
    showResetPassword,
    showMagicLinkButton,
    onClickMagicLink,
    showSignUpRedirect,
    onClickSignUpRedirect,
  } = props;

  const useClasses = makeStyles({
    formContainer: {
      margin: '20vh auto 0 auto',
      width: 400,
      maxWidth: '90vw',
      position: 'relative',
      minHeight: '20vh',
      padding: 24,
      [theme.breakpoints.down('sm')]: {
        margin: '4px auto',
        padding: '48px 16px 16px 16px',
      },
    },
    itemHolder: {
      marginBottom: 16,
      width: '100%',
    },
    formField: {
      marginBottom: 12,
    },
    orgSignIn: {
      marginTop: 12,
    },
    signUpLink: {
      textDecoration: 'none',
      marginLeft: 6,
    },
    btnPrimary: {
      padding: `16px 8px`,
    },
  });

  const classes = useClasses();
  const [showPassword, setShowPassword] = useState(false);
  const [orgAuth, setOrgAuth] = useState<any | null>(null);
  const veridService = useVeridService(veridApolloClient);

  useEffect(() => {
    if (orgId) {
      veridService
        .getOrg(orgId)
        .then((response) => {
          const org = response.data?.org;

          if (org && org.authConfig && org.authConfig.length > 0) {
            const authConfig = org.authConfig.find((item: any) => item.id === authConfigId);
            setOrgAuth(authConfig);
          }
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    }
  }, []);

  const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    if (onSubmit) {
      onSubmit(values.email, values.password, setSubmitting);
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center">
      <Paper className={style?.formContainer || classes.formContainer}>
        <Grid container justifyContent="center">
          <Grid item className={style?.itemHolder || classes.itemHolder}>
            <Grid container direction="column" justifyContent="center">
              <Box mt={1} mb={3}>
                <Typography variant="h3">{orgAuth?.signInPage?.title || 'Sign In'}</Typography>
              </Box>
              <Formik
                initialValues={{
                  email: '',
                  password: '',
                }}
                validate={(values) => {
                  const errors: Partial<IValues> = {};
                  if (!values.email) {
                    errors.email = 'Required';
                  } else if (!EMAIL_REGEX.test(values.email)) {
                    errors.email = 'Invalid email address';
                  }
                  return errors;
                }}
                onSubmit={handleSubmit}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form className={style?.form}>
                    {orgAuth?.signInPage?.formTitle && (
                      <Typography variant="h4" align="center">
                        {orgAuth?.signInPage?.formTitle}
                      </Typography>
                    )}
                    <Grid container direction="column" justifyContent="center" alignItems="center">
                      <FormControl fullWidth required variant="filled">
                        <Field
                          component={TextField}
                          name="email"
                          type="text"
                          label={orgAuth?.signInPage?.inputEmailLabel || 'Email Address'}
                          data-test="input-email"
                          variant="outlined"
                          className={classes.formField}
                        />

                        <Field
                          component={TextField}
                          type={showPassword ? 'text' : 'password'}
                          label={orgAuth?.signInPage?.inputEmailPassword || 'Password'}
                          name="password"
                          variant="outlined"
                          data-test="input-password"
                          className={classes.formField}
                          InputProps={{
                            endAdornment: (
                              <Typography onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} variant="caption">
                                {showPassword ? 'Hide' : 'Show'}
                              </Typography>
                            ),
                          }}
                        />
                      </FormControl>
                      {isSubmitting && <LinearProgress />}
                      {showResetPassword && (
                        <Typography variant="caption" color="textSecondary" paragraph>
                          {orgAuth?.signInPage?.resetPasswordSection?.textPreLink || 'Need Help Signing In?'}{' '}
                          <a href={orgAuth?.signInPage?.resetPasswordSection?.link}>
                            {orgAuth?.signInPage?.resetPasswordSection?.textLink || 'Set or Change your Healix Password'}
                          </a>
                        </Typography>
                      )}
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        fullWidth
                        data-test="button-sign-in"
                        className={classes.btnPrimary}
                      >
                        {orgAuth?.signInPage?.buttonSignIn || 'Sign In'}
                      </Button>
                    </Grid>
                  </Form>
                )}
              </Formik>
              {showMagicLinkButton && (
                <Box mt={2}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClickMagicLink}
                    fullWidth
                    data-test="button-sign-in"
                    className={classes.btnPrimary}
                  >
                    {orgAuth?.signInPage?.buttonMagicLink || 'Sign In with Magic Link'}
                  </Button>
                </Box>
              )}
              {showSignUpRedirect && onClickSignUpRedirect && (
                <Box mt={2} mb={3} textAlign="center">
                  <Typography variant="subtitle2">
                    {orgAuth?.signInPage?.signUpRedirectText || "Don't have an account? "}
                    <span className={classes.signUpLink} onClick={onClickSignUpRedirect}>
                      {orgAuth?.signInPage?.signUpRedirectButton || 'Sign up'}
                    </span>
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
