import { Box, Button, InputAdornment, Tooltip } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import { OrgType } from 'lib/api/api-types';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { IdName, Util, Selector, Switch } from 'ui-lib';
import React, { useEffect, useRef, useState } from 'react';
import { dialogClasses, authConfigClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import InfoIcon from '@mui/icons-material/Info';
import { AuthAlgorithm, AuthConfig, OrgAuthConfigType, DefaultAuthConfig, CookieType } from 'verid-shared-lib';

interface IProps {
  baseName: string;
  values: AuthConfig;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  editingId: string;
}

const DEBUG = true;

function TooltipIcon(props: { text: string }) {
  return (
    <Tooltip title={props.text} arrow placement="top">
      <InfoIcon sx={dialogClasses.infoIcon} fontSize="small" />
    </Tooltip>
  );
}
function SelectorField(props: { name: string; label: string; data: any }) {
  const choices: IdName[] = Util.idNamePairsFromEnum(props.data);
  return <Selector name={props.name} items={choices} label={props.label} />;
}
export function EditAuthConfig(props: IProps) {
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const { baseName, values, setFieldValue, editingId } = props;
  if (!values.type) {
    return null;
  }
  DEBUG && ClientLogger.debug('EditAuthConfig', 'render', { props });
  async function generateHMACSecret(authAlgorithm?: string) {
    if (!authAlgorithm) return;
    const secretResponse = await orgService.generateHMACSecret(editingId, authAlgorithm);
    if (!secretResponse || !secretResponse.data || secretResponse.errors) {
      errorHandler.handleErrors({ graphQLErrors: secretResponse.errors });
      return;
    }
    let { generateHMACSecret } = secretResponse.data;
    return generateHMACSecret;
  }
  return (
    <>
      {values.type && (values.type === OrgAuthConfigType.LOCALLY_PROVIDED || values.type === OrgAuthConfigType.EXTERNALLY_PROVIDED) && (
        <>
          <SelectorField name={`${baseName}.authAlgorithm`} label="Algorithm" data={AuthAlgorithm} />
          <div style={authConfigClasses.secretField}>
            <Field
              component={TextField}
              name={`${baseName}.HMAC_secret`}
              label="Secret (Leave blank for no change)"
              placeholder="Enter HMAC_secret"
              value={values.HMAC_secret || ''}
              type="text"
              fullWidth={true}
              variant="outlined"
              sx={dialogClasses.inputField}
            />
            <Button
              color="primary"
              onClick={async () => setFieldValue(`${baseName}.HMAC_secret`, await generateHMACSecret(values.authAlgorithm))}
              size="small"
              sx={authConfigClasses.generateButton}
              disabled={values.authAlgorithm ? false : true}
            >
              Generate HMAC
            </Button>
          </div>
          {values.type === OrgAuthConfigType.LOCALLY_PROVIDED && (
            <>
              <Field
                component={TextField}
                name={`${baseName}.jwtTokenDurationSeconds`}
                label="JWT Duration (seconds)"
                placeholder="Enter how long the JWT token should be valid"
                type="number"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.refreshTokenDurationMinutes`}
                label="Refresh Token Duration (minutes)"
                placeholder="Enter how long the refresh token should be valid"
                type="number"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name={`${baseName}.passwordResetTokenDurationMinutes`}
                label="Password Reset Link Duration (minutes)"
                placeholder="Enter how long the password reset token should be valid"
                type="number"
                fullWidth={true}
                variant="outlined"
                sx={dialogClasses.inputField}
              />
            </>
          )}
        </>
      )}
      {values.type && values.type === OrgAuthConfigType.OPEN_ID && (
        <>
          <Field
            component={TextField}
            name={`${baseName}.clientId`}
            label="Client Id"
            placeholder="Enter Client Id"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="The OpenId client_id that has been provided by your identity provider" />
                </InputAdornment>
              ),
            }}
          />
          <div style={authConfigClasses.secretField}>
            <Field
              component={TextField}
              name={`${baseName}.clientSecret`}
              label="Client Secret"
              placeholder="Client Secret (Leave blank for no change)"
              value={values.clientSecret || ''}
              type="text"
              fullWidth={true}
              variant="outlined"
              sx={dialogClasses.inputField}
              validateOnBlur
              validateOnChange
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end" disablePointerEvents={false}>
                    <TooltipIcon text="The OpenId client_secret that has been provided by your identity provider" />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              color="primary"
              onClick={async () => setFieldValue(`${baseName}.clientSecret`, await generateHMACSecret(AuthAlgorithm.HMAC_SHA_256))}
              size="small"
              sx={authConfigClasses.generateButton}
            >
              Generate Secret
            </Button>
          </div>
          <Field
            component={TextField}
            name={`${baseName}.authorizationEndpoint`}
            label="Authorization Endpoint"
            placeholder="Enter Authorization Endpoint"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="The full URL to the OpenId Authorize endpoint" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            component={TextField}
            name={`${baseName}.tokenEndpoint`}
            label="Token Endpoint"
            placeholder="Enter Token Endpoint"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="The full URL to the OpenId Token endpoint" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            component={TextField}
            name={`${baseName}.userInfoEndpoint`}
            label="User Info Endpoint"
            placeholder="Enter User Info Endpoint"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="The full URL to the OpenId Userinfo endpoint" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            component={TextField}
            name={`${baseName}.buttonName`}
            label="Button Name"
            placeholder="Enter Button Name"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="The text to display in the login button" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            component={TextField}
            name={`${baseName}.loginPageSlug`}
            label="Login Page Slug"
            placeholder="Enter Login Page Slug"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="Short name shown in the URL / browser address bar" />
                </InputAdornment>
              ),
            }}
          />
          <Field
            component={TextField}
            name={`${baseName}.openIdRoles`}
            label="Open Id Roles"
            placeholder="Enter Open Id Roles"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" disablePointerEvents={false}>
                  <TooltipIcon text="Roles provided by thirdParty" />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      {values.type && values.type === OrgAuthConfigType.IDENTITY_PROVIDER && (
        <>
          <Field
            component={TextField}
            name={`${baseName}.identityProviderId`}
            label="Identity Provider Id"
            placeholder="Enter Identity Provider Id"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
          />
          <Field
            component={TextField}
            name={`${baseName}.identityProviderName`}
            label="Identity Provider Name"
            placeholder="Enter Identity Provider Name"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
          />
          <div style={authConfigClasses.secretField}>
            <Field
              component={TextField}
              name={`${baseName}.identityProviderSecret`}
              label="Identity Provider Secret"
              placeholder="Identity Provider Secret"
              value={values.identityProviderSecret || ''}
              type="text"
              fullWidth={true}
              variant="outlined"
              sx={dialogClasses.inputField}
            />
            <Button
              color="primary"
              onClick={async () =>
                setFieldValue(`${baseName}.identityProviderSecret`, await generateHMACSecret(AuthAlgorithm.HMAC_SHA_256))
              }
              size="small"
              sx={authConfigClasses.generateButton}
            >
              Generate Secret
            </Button>
          </div>
          <Field
            component={TextField}
            name={`${baseName}.identityProviderKeyId`}
            label="Identity Provider KeyId"
            placeholder="Enter Identity Provider KeyId"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
          />
          <Field
            component={TextField}
            name={`${baseName}.identityProviderClientId`}
            label="Identity Provider ClientId"
            placeholder="Enter Identity Provider ClientId"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
          />
          <Field
            component={TextField}
            name={`${baseName}.identityProviderApiKey`}
            label="Identity Provider ApiKey"
            placeholder="Enter Identity Provider ApiKey"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
          />
          <Field
            component={TextField}
            name={`${baseName}.reconcileEndpoint`}
            label="Reconcile Endpoint"
            placeholder="Enter Reconcile Endpoint"
            type="text"
            fullWidth={true}
            variant="outlined"
            sx={dialogClasses.inputField}
            validateOnBlur
            validateOnChange
          />
          <Box mt={2}>
            <Switch name={`${baseName}.reconcileTokenMode`} label="Reconcile Token Mode" />
          </Box>
          <SelectorField name={`${baseName}.cookieType`} label="Set Cookie Type" data={CookieType} />
          {values.cookieType && (
            <Field
              component={TextField}
              name={`${baseName}.cookieName`}
              label="Cookie Name"
              placeholder="Enter Cookie Name"
              type="text"
              fullWidth={true}
              variant="outlined"
              sx={dialogClasses.inputField}
              validateOnBlur
              validateOnChange
            />
          )}
        </>
      )}
    </>
  );
}
