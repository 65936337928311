export interface MailOptions {
  // Subset of nodemailer SMTPConnection.Options
  // A Subset of
  /** the hostname or IP address to connect to (defaults to ‘localhost’) */
  host: string;
  /** the port to connect to (defaults to 25 or 465) */
  port: number;
  /** defines if the connection should use SSL (if true) or not (if false) */
  secure?: boolean;
  auth: {
    user: string;
    pass: string;
  };
}

interface SMSOptions {
  accountSid: string;
  authToken: string;
}

export enum MessageTemplates {
  PASSWORD_RESET = 'PASSWORD_RESET',
  PASSWORD_RESET_CONFIRMATION = 'PASSWORD_RESET_CONFIRMATION',
  PASSWORDLESS_LOGIN = 'PASSWORDLESS_LOGIN',
}

export enum SupportedTemplateLanguages {
  en = 'en',
}
export interface TemplateParams {
  preHeader: string;
  beforeCTA: string;
  ctaText: string;
  ctaUrl: string;
  afterCTA: string;
  companyNameAndAddress: string;
  unsubscribeText?: string;
  unsubscribeUrl?: string;
  unsubscribeButtonText?: string;
  logoUrl?: string;
}

export interface NotificationTemplateText {
  subject: string;
  plainText: string;
  useHTMLTemplate: boolean;
  htmlText?: string;
  htmlTemplate?: TemplateParams;
  messageInputIds: string[];
}

export interface MessageTemplate {
  templateText: {
    [key in SupportedTemplateLanguages]: NotificationTemplateText;
  };
}

export interface EmailConfig {
  testEmailServer: MailOptions;
  productionEmailServer: MailOptions;
}

export interface ExternalMessageConfig {
  mailConfig: EmailConfig;
  smsConfig: SMSOptions;
  templates: {
    [key in MessageTemplates]: MessageTemplate;
  };
  masterTemnplate: string;
  noReplyEmailAddress: string;
}

export interface AvailableInputs {
  orgName: string;
  senderTitle?: string;
  senderFirstName?: string;
  senderMiddleName?: string;
  senderLastName?: string;
  senderFullName?: string;
  senderEmail?: string;
  recipientTitle?: string;
  recipientFirstName?: string;
  recipientMiddleName?: string;
  recipientLastName?: string;
  recipientFullName?: string;
  recipientEmail?: string;
  recipientPhone?: string;
}

export function getInputIdsFromString(text?: string): string[] {
  if (!text) {
    return [];
  }
  // get all the ids in the text where ids are started by {{{ and ended by }}}
  const regex = /\{\{\{([^}]+)\}\}\}/g;
  const ids: string[] = [];
  let match;
  while ((match = regex.exec(text)) !== null) {
    ids.push(match[1]);
  }
  return ids;
}

export function getInputIdsFromDefinition(notificationTemplateText: NotificationTemplateText): string[] {
  const ids: string[] = [];

  const textToSearch = [
    notificationTemplateText.htmlText,
    notificationTemplateText.plainText,
    notificationTemplateText.subject,
    notificationTemplateText.htmlTemplate?.afterCTA,
    notificationTemplateText.htmlTemplate?.beforeCTA,
    notificationTemplateText.htmlTemplate?.companyNameAndAddress,
    notificationTemplateText.htmlTemplate?.ctaText,
    notificationTemplateText.htmlTemplate?.ctaUrl,
    notificationTemplateText.htmlTemplate?.logoUrl,
    notificationTemplateText.htmlTemplate?.preHeader,
    notificationTemplateText.htmlTemplate?.unsubscribeButtonText,
    notificationTemplateText.htmlTemplate?.unsubscribeText,
    notificationTemplateText.htmlTemplate?.unsubscribeUrl,
  ];

  for (const text of textToSearch) {
    const textIds = getInputIdsFromString(text);
    // combine the ids but eliminate duplicates
    textIds.forEach((id) => {
      if (!ids.includes(id)) {
        ids.push(id);
      }
    });
  }

  return ids;
}

export function insertIdsIntoMessageConfig(extMessageConfig: ExternalMessageConfig): ExternalMessageConfig {
  const retVal = { ...extMessageConfig };
  const templates: any = {};
  for (const templateKey in extMessageConfig.templates) {
    const template: MessageTemplate = extMessageConfig.templates[templateKey as MessageTemplates];
    const notificationTemplateText: NotificationTemplateText = { ...template.templateText.en };
    const ids = getInputIdsFromDefinition(notificationTemplateText);
    notificationTemplateText.messageInputIds = ids;
    templates[templateKey as MessageTemplates] = { templateText: { en: notificationTemplateText } };
  }
  retVal.templates = templates;
  return retVal;
}

export function defaultExtMessageConfig(orgName: string, noReplyEmailAddress: string): ExternalMessageConfig {
  const retValNoIds: ExternalMessageConfig = {
    noReplyEmailAddress,
    masterTemnplate: EmailTemplate(),
    mailConfig: {
      testEmailServer: {
        host: 'smtp.mailtrap.io',
        port: 2525,
        auth: {
          user: '',
          pass: '',
        },
      },
      productionEmailServer: {
        host: 'smtp.sendgrid.net',
        port: 465,
        secure: true,
        auth: {
          user: 'apikey',
          pass: process.env.EMAIL_SERVER_PASSWORD || '',
        },
      },
    },
    smsConfig: {
      accountSid: '',
      authToken: '',
    },
    templates: {
      PASSWORD_RESET: {
        templateText: {
          en: {
            plainText: '',
            subject: `Your ${orgName} Password`,
            useHTMLTemplate: true,
            htmlTemplate: {
              companyNameAndAddress: '1235 Wilson Avenue, Toronto, Ontario, M3M 0B2',
              preHeader: 'This email contains a link to change your password',
              beforeCTA: `
          <p> Hi {{{inviteeFirstName}}}, </p>
        <p>We have received a request about your password. Use the link below to set or change your password.</p>
          `,
              ctaText: 'Set New Password',
              ctaUrl: '{{{link}}}', // {{{}}} avoids HTML escaping
              afterCTA: `
          <p>If the above button doesn't work, paste this link into your web browser: <a href={{{link}}} style="word-wrap: break-word;">{{{link}}}</a></p>
          <p>If you do not recognize this request, please ignore this email.</p>
          `,
            },
            messageInputIds: [],
          },
        },
      },
      PASSWORD_RESET_CONFIRMATION: {
        templateText: {
          en: {
            plainText: 'FYI, your password has been changed',
            subject: `Your ${orgName} Password has been changed`,
            useHTMLTemplate: true,
            htmlTemplate: {
              companyNameAndAddress: '1235 Wilson Avenue, Toronto, Ontario, M3M 0B2',
              preHeader: 'This confirms that you have changed your password',
              beforeCTA: `
            <p> Hi {{{inviteeFirstName}}}, </p>
          <p>We have changed your password. If this was not you, please change your password again and contact support.</p>
            `,
              ctaText: 'Set New Password',
              ctaUrl: '{{{link}}}', // {{{}}} avoids HTML escaping
              afterCTA: `
            <p>If the above button doesn't work, paste this link into your web browser: <a href={{{link}}} style="word-wrap: break-word;">{{{link}}}</a></p>
            <p>If you do not recognize this request, please ignore this email.</p>
            `,
            },
            messageInputIds: [],
          },
        },
      },
      PASSWORDLESS_LOGIN: {
        templateText: {
          en: {
            plainText: 'Here is your link {{{link}}}',
            subject: `Your magic link for ${orgName}`,
            useHTMLTemplate: true,
            htmlTemplate: {
              companyNameAndAddress: '1235 Wilson Avenue, Toronto, Ontario, M3M 0B2',
              preHeader: 'This email contains a link to log you in',
              beforeCTA: `
        <h1 class="title">Tap on the button below to sign into Healix.</h1>
        <p>This link expires in 30 minutes and can only be used once.</p>
          `,
              ctaText: 'Sign In',
              ctaUrl: '{{{link}}}', // {{{}}} avoids HTML escaping
              afterCTA: `
        <p>If the above button doesn't work, paste this link into your web browser:</p>
        <p class="link-text">{{{link}}}</p>
        <p>If you do not recognize this request, please ignore this email.</p>
          `,
            },
            messageInputIds: [],
          },
        },
      },
    },
  };
  const retVal = insertIdsIntoMessageConfig(retValNoIds);
  return retVal;
}

function EmailTemplate(): string {
  const params: TemplateParams = {
    preHeader: '{{{preHeader}}}',
    beforeCTA: '{{{beforeCTA}}}',
    ctaText: '{{{ctaText}}}',
    ctaUrl: '{{{ctaUrl}}}',
    afterCTA: '{{{afterCTA}}}',
    companyNameAndAddress: '{{{companyNameAndAddress}}}',
    unsubscribeText: '{{{unsubscribeText}}}',
    unsubscribeUrl: '{{{unsubscribeUrl}}}',
    unsubscribeButtonText: '{{{unsubscribeButtonText}}}',
    logoUrl: '{{{logoUrl}}}',
  };
  const {
    preHeader,
    beforeCTA,
    ctaText,
    ctaUrl,
    afterCTA,
    companyNameAndAddress,
    unsubscribeText,
    unsubscribeUrl,
    unsubscribeButtonText,
    logoUrl,
  } = params;
  return `
<!doctype html>
<html>
  <head>
    <meta name="viewport" content="width=device-width" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Simple Transactional Email</title>
    <style>
      /* -------------------------------------
          GLOBAL RESETS
      ------------------------------------- */
      
      /*All the styling goes here*/
      
      img {
        border: none;
        -ms-interpolation-mode: bicubic;
        max-width: 100%; 
      }

      body {
        background-color: #f6f6f6;
        font-family: sans-serif;
        -webkit-font-smoothing: antialiased;
        font-size: 14px;
        line-height: 1.4;
        margin: 0;
        padding: 0;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%; 
      }

      table {
        border-collapse: separate;
        mso-table-lspace: 0pt;
        mso-table-rspace: 0pt;
        width: 100%; }
        table td {
          font-family: sans-serif;
          font-size: 14px;
          vertical-align: top; 
      }

      /* -------------------------------------
          BODY & CONTAINER
      ------------------------------------- */

      .body {
        background-color: #f6f6f6;
        width: 100%; 
      }

      /* Set a max-width, and make it display as block so it will automatically stretch to that width, but will also shrink down on a phone or something */
      .container {
        display: block;
        margin: 0 auto !important;
        /* makes it centered */
        max-width: 580px;
        padding: 10px;
        width: 580px; 
      }

      /* This should also be a block element, so that it will fill 100% of the .container */
      .content {
        box-sizing: border-box;
        display: block;
        margin: 0 auto;
        max-width: 580px;
        padding: 10px; 
      }

      /* -------------------------------------
          HEADER, FOOTER, MAIN
      ------------------------------------- */
      .main {
        background: #ffffff;
        border-radius: 3px;
        width: 100%; 
      }
      
      .title {
          font-size: 20px;
          font-weight: bold;
      }

      .logo {
          text-align: center;
      }

      .wrapper {
        box-sizing: border-box;
        padding: 20px; 
      }

      .content-block {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .footer {
        clear: both;
        margin-top: 10px;
        text-align: center;
        width: 100%; 
      }
        .footer td,
        .footer p,
        .footer span,
        .footer a {
          color: #999999;
          font-size: 12px;
          text-align: center; 
      }

      /* -------------------------------------
          TYPOGRAPHY
      ------------------------------------- */
      h1,
      h2,
      h3,
      h4 {
        color: #000000;
        font-family: sans-serif;
        font-weight: 400;
        line-height: 1.4;
        margin: 0;
        margin-bottom: 30px; 
      }

      h1 {
        font-size: 35px;
        font-weight: 300;
        text-align: center;
        text-transform: capitalize; 
      }

      p,
      ul,
      ol {
        font-family: sans-serif;
        font-size: 14px;
        font-weight: normal;
        margin: 0;
        margin-bottom: 15px; 
      }
        p li,
        ul li,
        ol li {
          list-style-position: inside;
          margin-left: 5px; 
      }

      a {
        color: #3498db;
        text-decoration: underline; 
      }

      /* -------------------------------------
          BUTTONS
      ------------------------------------- */
      .btn {
        box-sizing: border-box;
        width: 100%; }
        .btn > tbody > tr > td {
          padding-bottom: 15px; }
        .btn table {
          width: auto; 
      }
        .btn table td {
          background-color: #ffffff;
          border-radius: 5px;
          text-align: center; 
      }
        .btn a {
          background-color: #ffffff;
          border: solid 1px #3498db;
          border-radius: 5px;
          box-sizing: border-box;
          color: #3498db;
          cursor: pointer;
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
          margin: 0;
          padding: 12px 25px;
          text-decoration: none;
          text-transform: capitalize; 
      }

      .btn-primary table td {
        background-color: #3498db; 
      }

      .btn-primary a {
        background-color: #3498db;
        border-color: #3498db;
        color: #ffffff; 
      }

      /* -------------------------------------
          OTHER STYLES THAT MIGHT BE USEFUL
      ------------------------------------- */
      .last {
        margin-bottom: 0; 
      }

      .first {
        margin-top: 0; 
      }

      .align-center {
        text-align: center; 
      }

      .align-right {
        text-align: right; 
      }

      .align-left {
        text-align: left; 
      }

      .clear {
        clear: both; 
      }

      .mt0 {
        margin-top: 0; 
      }

      .mb0 {
        margin-bottom: 0; 
      }

      .preheader {
        color: transparent;
        display: none;
        height: 0;
        max-height: 0;
        max-width: 0;
        opacity: 0;
        overflow: hidden;
        mso-hide: all;
        visibility: hidden;
        width: 0; 
      }

      .powered-by a {
        text-decoration: none; 
      }

      hr {
        border: 0;
        border-bottom: 1px solid #f6f6f6;
        margin: 20px 0; 
      }

      /* -------------------------------------
          RESPONSIVE AND MOBILE FRIENDLY STYLES
      ------------------------------------- */
      @media only screen and (max-width: 620px) {
        table[class=body] h1 {
          font-size: 28px !important;
          margin-bottom: 10px !important; 
        }
        table[class=body] p,
        table[class=body] ul,
        table[class=body] ol,
        table[class=body] td,
        table[class=body] span,
        table[class=body] a {
          font-size: 16px !important;
        }
        table[class=body] .wrapper,
        table[class=body] .article {
          padding: 10px !important; 
        }
        table[class=body] .content {
          padding: 0 !important; 
        }
        table[class=body] .container {
          padding: 0 !important;
          width: 100% !important; 
        }
        table[class=body] .main {
          border-left-width: 0 !important;
          border-radius: 0 !important;
          border-right-width: 0 !important;
          width: 100% !important;
          height:auto !important;
        }
        table[class=body] .btn table {
          width: 100% !important; 
        }
        table[class=body] .btn a {
          width: 100% !important; 
        }
        table[class=body] .img-responsive {
          height: auto !important;
          max-width: 100% !important;
          width: auto !important; 
        }
        table[class=main] {width:100% !important; height:auto !important;} 
      }

      @media only screen and (max-width: 414px) {
        table[class=main],
        table[class=main] tbody {width:100% !important; height:auto !important;} 
        table[class=second] {width:100% !important; height:auto !important; table-layout: fixed;}
        table[class=body] h1 {
          font-size: 16px !important;
          margin-bottom: 10px !important; 
        }
        table[class=body] p,
        table[class=body] ul,
        table[class=body] ol,
        table[class=body] td,
        table[class=body] span,
        table[class=body] a {
          font-size: 12px !important;
        }

        .container {
          display: block;
          margin: 0 auto !important;
          /* makes it centered */
          max-width: 320px;
          padding: 10px;
          width: 320px; 
        }
  
        /* This should also be a block element, so that it will fill 100% of the .container */
        .content {
          box-sizing: border-box;
          display: block;
          margin: 0 auto;
          max-width: 320px;
          padding: 10px; 
        }
      }

      /* -------------------------------------
          PRESERVE THESE STYLES IN THE HEAD
      ------------------------------------- */
      @media all {
        .ExternalClass {
          width: 100%; 
        }
        .ExternalClass,
        .ExternalClass p,
        .ExternalClass span,
        .ExternalClass font,
        .ExternalClass td,
        .ExternalClass div {
          line-height: 100%; 
        }
        .apple-link a {
          color: inherit !important;
          font-family: inherit !important;
          font-size: inherit !important;
          font-weight: inherit !important;
          line-height: inherit !important;
          text-decoration: none !important; 
        }
        #MessageViewBody a {
          color: inherit;
          text-decoration: none;
          font-size: inherit;
          font-family: inherit;
          font-weight: inherit;
          line-height: inherit;
        }
        .btn-primary table td:hover {
          background-color: #34495e !important; 
        }
        .btn-primary a:hover {
          background-color: #34495e !important;
          border-color: #34495e !important; 
        } 
      }

    </style>
  </head>
  <body class="">
    <span class="preheader">${preHeader}</span>
    <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="body">
      <tr>
        <td>&nbsp;</td>
        <td class="container">
          <div class="content">

            <!-- START CENTERED WHITE CONTAINER -->
            <table role="presentation" class="main">

              <!-- START MAIN CONTENT AREA -->
              <tr>
                <td class="wrapper">
                  <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="second">
                    <tr>
                        <td>
                          <p class="logo"><img src=${logoUrl} width="152" height="37" alt="logo"></p>
                        </td>
                    </tr>
                    <tr>
                      <td>
                        ${beforeCTA}
                        ${
                          ctaUrl &&
                          ctaText &&
                          `
                        <table role="presentation" border="0" cellpadding="0" cellspacing="0" class="btn btn-primary">
                          <tbody>
                            <tr>
                              <td align="center">
                                <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                                  <tbody>
                                    <tr>
                                      <td> <a data-test="link-email-cta" href="${ctaUrl}" target="_blank">${ctaText}</a> </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        `
                        }
                        ${afterCTA}
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>

            <!-- END MAIN CONTENT AREA -->
            </table>
            <!-- END CENTERED WHITE CONTAINER -->

            <!-- START FOOTER -->
            ${
              unsubscribeText
                ? `
            <div class="footer">
              <table role="presentation" border="0" cellpadding="0" cellspacing="0">
                <tr>
                  <td class="content-block">
                    <span class="apple-link">${companyNameAndAddress}</span>
                    <br> ${unsubscribeText} <a href="${unsubscribeUrl}">${unsubscribeButtonText}</a>.
                  </td>
                </tr>
              </table>
            </div>`
                : ''
            }
            <!-- END FOOTER -->

          </div>
        </td>
        <td>&nbsp;</td>
      </tr>
    </table>
  </body>
</html>
`;
}
