import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';
import { Loading } from 'components/widgets/Loading';

import { ClientLogger } from 'lib/client-logger';
import { Org, useOrgService } from 'lib/api/use-org-service';
import { OrgType, orgsQuery } from 'lib/api/api-types';
import { useErrorHandler } from 'lib/use-error-handler';
import { EditOrgOverview } from './EditOrgOverview';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import { tableClasses } from '../../style/sharedCssClasses';
import { OrgDetails } from './OrgDetails';
import { dateDisplay } from 'lib/util';

interface ButtonColumnData {
  id: string;
  name: string;
}

export interface DisplayRowType extends Org {
  buttons: ButtonColumnData;
}

interface IProps {
  orgType: OrgType;
  title: string;
}

const DEBUG = false;

export const OrgList = (props: IProps) => {
  const orgsService = useOrgService();
  const errorHandler = useErrorHandler('OrgList');
  const [loading, setLoading] = useState(false);
  const [editOrgOpen, setEditOrgOpen] = useState<false | undefined | string>(false); //false not open, undefined open with new, string orgId beiong edited
  const [removeOrgOpen, setRemoveOrgOpen] = useState<false | ButtonColumnData>(false); //false not open, undefined open with new, string orgId beiong edited

  const orgsDataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { label: 'Id' },
    name: { label: 'Name' },
    updatedAt: { display: 'excluded' },
    emailDomain: { display: 'excluded' },
    externalId: { display: 'excluded' },
    isTest: { display: 'excluded' },
    attributes: { display: 'excluded' },
    authConfig: { display: 'excluded' },
    extMessageConfig: { display: 'excluded' },
    type: { display: 'excluded' },
    autoOnboard: { display: 'excluded' },
    description: { display: 'excluded' },
    createdAt: { label: 'Created At', customBodyRender: (value: string) => dateDisplay(value) },
    status: { display: 'excluded' },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="start">
              <IconButton
                onClick={(e) => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  e.stopPropagation();
                  setRemoveOrgOpen(value);
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  const transformResponseToRows = (resp: orgsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.orgs || !resp.orgs?.edges) {
      return [];
    }
    return resp.orgs.edges.map((org) => ({ ...org.node, buttons: { id: org.node.id, name: org.node.name } }));
  };

  const queryMap = {
    [OrgType.PROVIDER]: orgsService.useOrgsQueryProvider,
    [OrgType.APP]: orgsService.useOrgsQueryApp,
    [OrgType.JURISDICTION]: orgsService.useOrgsQueryJurisdiction,
  };
  const rowClicked = (row: any) => {
    setEditOrgOpen(row.id);
  };

  const tableProps: DataTableProps<string, any, orgsQuery, DisplayRowType> = {
    useQuery: queryMap[props.orgType] as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: orgsDataTableOptions,
    onRowClick: rowClicked,
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOrgOpen(undefined);
    },
  };

  async function removeOrg(orgId: string) {
    await orgsService
      .orgDelete(orgId)
      .then((resp) => {
        if (resp.errors) {
          errorHandler.handleErrors({ graphQLErrors: resp.errors });
        }
        setLoading(false);
      })
      .catch((err) => {
        errorHandler.handleErrors({ error: err });
        setLoading(false);
      });
  }

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps });

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Grid container>
        <TopBackBtnBar title={props.title} />
        <Box mx={2} mt={6} sx={tableClasses.tableHolder}>
          <DataTable {...tableProps} />
        </Box>
      </Grid>
      <Dialog open={typeof editOrgOpen === 'string'} onClose={() => setEditOrgOpen(false)} maxWidth="sm" fullWidth>
        <OrgDetails
          onClose={() => {
            setEditOrgOpen(false);
          }}
          editingId={editOrgOpen || ''}
        />
      </Dialog>
      <Dialog open={editOrgOpen === undefined} onClose={() => setEditOrgOpen(false)} maxWidth="sm" fullWidth>
        <EditOrgOverview
          orgType={props.orgType}
          initialValues={{ name: '', description: '', id: '', isTest: false }}
          onClose={() => {
            setEditOrgOpen(false);
          }}
          editingId={typeof editOrgOpen === 'string' ? editOrgOpen : undefined}
        />
      </Dialog>
      <Dialog open={!!removeOrgOpen} onClose={() => setRemoveOrgOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Remove {removeOrgOpen && removeOrgOpen.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to remove {removeOrgOpen && removeOrgOpen.name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveOrgOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(item) => {
              DEBUG && ClientLogger.debug('OrgList', 'org remove related', { item });
              setRemoveOrgOpen(false);
              setLoading(true);
              if (removeOrgOpen && removeOrgOpen.id) {
                removeOrg(removeOrgOpen.id);
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
