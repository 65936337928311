import CheckIcon from '@mui/icons-material/CheckCircle';
import AttentionIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/ReportProblemOutlined';
import { Button, Grid, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { createContext, Dispatch, useEffect, useState } from 'react';

export enum SEVERITY {
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  SUCCESS = 'success',
}

export interface Toast {
  msg: string | JSX.Element;
  severity: SEVERITY;
  title?: string;
  autoClose?: boolean;
  actionBtnText?: string;
  actionBtnFn?: () => void;
}

export const ToastDispatchContext = createContext<Dispatch<Toast | null>>(() => null);

const icon = {
  width: 38,
  height: 38,
  marginRight: 8,
};

const toastText = {
  // [theme.breakpoints.down('md')]: { // Not sure what this does and how to convert https://mui.com/system/the-sx-prop/
  fontSize: '120%',
  // },
};

export const Toaster = ({ children }: { children: JSX.Element }): JSX.Element => {
  const [toast, dispatch] = useState<Toast | null>(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    setOpen(true);
  }, [toast]);

  return (
    <ToastDispatchContext.Provider value={dispatch}>
      <Snackbar
        open={open && !!toast?.msg}
        autoHideDuration={toast?.autoClose ? 6000 : null}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {toast?.title ? (
          <Alert
            data-test="toast-alert"
            sx={{
              width: '100%',
              justifyContent: 'center',
            }}
            severity={toast?.severity}
            icon={false}
          >
            <Grid container alignItems="center">
              <Grid item>{toast.severity === SEVERITY.SUCCESS && <CheckIcon sx={icon} />}</Grid>
              <Grid item>{toast.severity === SEVERITY.ERROR && <AttentionIcon sx={icon} />}</Grid>
              <Grid item>{toast.severity === SEVERITY.INFO && <AttentionIcon sx={icon} />}</Grid>
              <Grid item>{toast.severity === SEVERITY.WARNING && <WarningIcon sx={icon} />}</Grid>
              <Grid item>
                <Typography sx={toastText}>
                  <strong>{toast.title}</strong>
                </Typography>
                <Typography sx={toastText}>{toast.msg}</Typography>
              </Grid>
            </Grid>
          </Alert>
        ) : (
          <Alert data-test="toast-alert" severity={toast?.severity}>
            {toast?.msg}
            {toast?.actionBtnText && toast?.actionBtnFn && (
              <Button onClick={toast.actionBtnFn} color="inherit" size="small">
                {toast?.actionBtnText}
              </Button>
            )}
          </Alert>
        )}
      </Snackbar>
      {children}
    </ToastDispatchContext.Provider>
  );
};
