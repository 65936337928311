import { Button, Grid } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useContext, useEffect, useState } from 'react';
import { Util } from 'ui-lib';

import { serverInfo_serverInfo } from '../../lib/api/api-types-parts';
import { UserContext } from '../../lib/auth/user-context';
import { ClientLogger } from '../../lib/client-logger';

import { TopBackBtnBar } from 'components/navigation/TopBackBtnBar';
import { useAuthService } from 'lib/api/use-auth-service';

const DEBUG = true;
const classes = {
  table: {
    minWidth: 650,
  },
};

export function ServerInfo() {
  const userContext = useContext(UserContext);
  const authService = useAuthService();
  const [serverInfo, setServerInfo] = useState<serverInfo_serverInfo | null | undefined>(null);
  useEffect(() => {
    ClientLogger.debug('ServerInfo.useEffect', 'Calling Server Info', DEBUG);
    authService
      .serverInfo()
      .then((info) => {
        ClientLogger.log('ServerInfo.useEffect', JSON.stringify(info, null, 2));
        setServerInfo(info.data?.serverInfo);
      })
      .catch((e) => {
        ClientLogger.error('ServerInfo.useEffect', 'Error', e);
      });
  }, []);

  return (
    <Grid container justifyContent="center">
      <TopBackBtnBar title="Server Info" />

      <TableContainer component={Paper}>
        <Table sx={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Variable</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell scope="row">Branch</TableCell>
              <TableCell align="right">{serverInfo?.branch}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Build Id</TableCell>
              <TableCell align="right">{serverInfo?.buildId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Commit</TableCell>
              <TableCell align="right">{serverInfo?.commit}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">Build Date</TableCell>
              <TableCell align="right">{serverInfo?.buildDate}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">DB Connect Test</TableCell>
              <TableCell align="right">{serverInfo?.dbConnectTest ? 'OK' : 'Error'}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row" style={{ width: '100%' }}>
                Client REACT_APP variables
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">REACT_APP_BUILD_DATE</TableCell>
              <TableCell align="right">{Util.getEnvVar('REACT_APP_BUILD_DATE')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell scope="row">REACT_APP_VERID_UI</TableCell>
              <TableCell align="right">{Util.getEnvVar('REACT_APP_VERID_UI')}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        onClick={() => {
          ClientLogger.error('ServerInfo', 'generate test error', new Error('Test Error'));
        }}
        variant="contained"
      >
        Generate Test Console Error
      </Button>
    </Grid>
  );
}
