import { Status } from 'lib/api/api-types';
import React from 'react';

import { Me, Org, PersonOrg } from '../api/use-auth-service';
import { ClientLogger } from '../client-logger';

export interface UserState {
  me: Me;
  isLoggedIn: boolean;
  ready: boolean;
}

export interface UserContextType {
  userState: UserState;
  setUserState: (v: UserState) => void | Promise<void>;
  $: UserContextUtils;
}
export class UserContextUtils {
  constructor(private me: Me | undefined) {}
  currentPersonOrg(): PersonOrg | undefined {
    let retVal: Org | undefined = undefined;
    if (this.me) {
      const currentOrgId = this.me.currentOrg || this.getCurrentOrgId() || this.me.personOrgs[0].orgId;
      for (const personOrg of this.me.personOrgs) {
        if (personOrg.orgId === currentOrgId) {
          return personOrg;
        }
      }
    }
    return retVal;
  }

  saveCurrentOrgId(orgId: string) {
    sessionStorage.setItem('currentOrgId', orgId);
  }

  getCurrentOrgId() {
    return sessionStorage.getItem('currentOrgId');
  }

  currentOrgId(): string | undefined {
    const currentPersonOrgVar = this.currentPersonOrg();
    return currentPersonOrgVar ? currentPersonOrgVar.org.id : undefined;
  }

  isJurisdictionAdmin(): boolean {
    const index = this.getPersonOrgs().findIndex((perOrg) => perOrg.org.type === 'JURISDICTION' && perOrg.role === 'ADMIN');
    return index >= 0;
  }

  isProviderOrgAdmin(): boolean {
    const index = this.getPersonOrgs().findIndex((perOrg) => perOrg.org.type === 'PROVIDER' && perOrg.role === 'ADMIN');
    return index >= 0;
  }

  isOrgAdmin(): boolean {
    const index = this.getPersonOrgs().findIndex((perOrg) => perOrg.role === 'ADMIN');
    return index >= 0;
  }

  getOrgs(): Org[] {
    return this.me?.personOrgs.map((personOrg) => personOrg.org) ?? [];
  }

  private getPersonOrgs(): PersonOrg[] {
    return this.me?.personOrgs ?? [];
  }
}

export const blankUserState: UserState = {
  me: {
    createdAt: new Date(),
    updatedAt: new Date(),
    id: '',
    title: null,
    firstName: null,
    middleName: null,
    lastName: null,
    gender: null,
    dateOfBirth: null,
    isTest: false,
    status: Status.DELETED,
    attributes: {},
    personOrgs: [],
    currentOrg: null,
    systemAccount: null,
  },
  isLoggedIn: false,
  ready: false,
};

export const UserContext = React.createContext({
  userState: blankUserState,
  setUserState: (v: UserState) => {
    ClientLogger.error('UserContext.setUserState', 'ignored', v);
  },
  $: new UserContextUtils(undefined),
});
