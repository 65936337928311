import { Box, Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import gfm from 'remark-gfm';

import { ROUTES } from 'Routes';
import { MarkdownStyles } from 'style/markdownStyles';

import helpMd from './content/help.md';
import touMd from './content/tou.md';
import ppMd from './content/pp.md';
import aboutMd from './content/about.md';

export enum RESOURCE_NAMES {
  HELP = 'HELP',
  TOU = 'TOU',
  PP = 'PP',
  ABOUT = 'ABOUT',
}

interface IProps {
  resourceName: RESOURCE_NAMES;
}

export function ResourcePage(props: IProps) {
  const navigate = useNavigate();
  const [clickCount, setClickCount] = React.useState(0);
  const resetScroll = () => {
    window.scrollTo(0, 0);
  };
  const [markdownContent, setMarkdownContent] = useState('');

  // https://stackoverflow.com/questions/65395125/how-to-load-an-md-file-on-build-when-using-create-react-app-and-typescript
  useEffect(() => {
    const markdown = () => {
      switch (props.resourceName) {
        case RESOURCE_NAMES.HELP:
          return helpMd;
        case RESOURCE_NAMES.TOU:
          return touMd;
        case RESOURCE_NAMES.PP:
          return ppMd;
        case RESOURCE_NAMES.ABOUT:
          return aboutMd;
      }
    };
    fetch(markdown())
      .then((response) => response.text())
      .then((text) => {
        resetScroll();
        setMarkdownContent(text);
      });
  }, [props.resourceName]);

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center" margin="auto">
      <Grid item xs={12} sm={10} lg={8}>
        <Box mt={6} mb={2}>
          <MarkdownStyles>
            <ReactMarkdown remarkPlugins={[gfm]} children={markdownContent} />
          </MarkdownStyles>
        </Box>
      </Grid>
      {props.resourceName === RESOURCE_NAMES.ABOUT && (
        <>
          <Button
            style={{ width: '100%', height: '100px' }}
            onClick={() => {
              if (clickCount === 3) {
                navigate(ROUTES.USER_INFO);
                setClickCount(0);
              }
              setClickCount(clickCount + 1);
            }}
          >
            &nbsp;
          </Button>
        </>
      )}
    </Grid>
  );
}
