import { Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  name?: string;
  fullWidth?: boolean;
}

export function Password(props: IProps) {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Field
      component={TextField}
      type={showPassword ? 'text' : 'password'}
      label="Password"
      name={props.name || 'password'}
      variant="outlined"
      data-test="input-password"
      fullWidth={props.fullWidth || false}
      InputProps={{
        endAdornment: (
          <Typography onClick={() => (showPassword ? setShowPassword(false) : setShowPassword(true))} variant="caption">
            {showPassword ? 'Hide' : 'Show'}
          </Typography>
        ),
      }}
    />
  );
}
