import { ApolloQueryResult, FetchResult, gql, QueryHookOptions } from '@apollo/client';
import {
  idTypeQuery,
  idTypeQueryVariables,
  idTypeCreateMutation,
  idTypeCreateMutationVariables,
  IdTypeInput,
  idTypesQuery,
  idTypesQueryVariables,
  idTypeUpdateMutation,
  idTypeUpdateMutationVariables,
} from './api-types';

import { idTypes_idTypes_edges_node } from './api-types-parts';

import { useApi } from './use-api';

export const ID_TYPE_FIELDS = gql`
  fragment IdTypeFragment on IdType {
    id
    createdAt
    updatedAt
    description
    label
    placeHolder
    format
    useExpiryDate
    expiryDateFormat
    expiryDateLabel
    expiryDatePlaceHolder
    status
  }
`;

export type IdType = Omit<idTypes_idTypes_edges_node, '__typename'>;
export type IdTypeUpsert = Omit<IdType, 'createdAt' | 'updatedAt'>;

export function useIdTypeService() {
  const api = useApi();

  const orgsQuery = gql`
    ${ID_TYPE_FIELDS}
    query idTypes($id: String, $orderBy: IdTypeOrder, $first: Int, $after: String) {
      idTypes(query: { id: $id }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...IdTypeFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useIdTypesQuery(options: QueryHookOptions<idTypesQuery, idTypesQueryVariables> | undefined) {
      return api.useQuery(orgsQuery, options);
    },

    async idType(id: string): Promise<ApolloQueryResult<idTypeQuery>> {
      return api.query<idTypeQuery, idTypeQueryVariables>({
        query: gql`
          ${ID_TYPE_FIELDS}
          query idType($id: String!) {
            idType(id: $id) {
              ...IdTypeFragment
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async idTypeCreate(idType: IdTypeInput): Promise<FetchResult<idTypeCreateMutation>> {
      return api.mutate<idTypeCreateMutation, idTypeCreateMutationVariables>({
        mutation: gql`
          mutation idTypeCreate($data: IdTypeInput!) {
            idTypeCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: idType },
        refetchQueries: ['idTypes'],
      });
    },

    async idTypeUpdate(idType: IdTypeInput): Promise<FetchResult<idTypeUpdateMutation>> {
      return api.mutate<idTypeUpdateMutation, idTypeUpdateMutationVariables>({
        mutation: gql`
          mutation idTypeUpdate($data: IdTypeInput!) {
            idTypeUpdate(data: $data) {
              id
            }
          }
        `,
        variables: { data: idType },
        refetchQueries: ['idTypes'],
      });
    },
  };
}
