import { Box } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  dataTestAddButton?: string;
  testLabels?: string;
  name?: string;
  required?: boolean;
}

const styles = {
  contactSelection: {
    width: '100%',
  },
};

export function EmailOnlyField({ dataTestAddButton, name, required, testLabels }: IProps) {
  return (
    <Box my={2} sx={styles.contactSelection}>
      <Field
        id="emailInput"
        component={TextField}
        name={name || 'email'}
        label={`Email Address${required ? ' (required)' : ''}`}
        placeholder="me@domain.com"
        type="email"
        fullWidth={true}
        variant="outlined"
        data-test={dataTestAddButton}
      />
    </Box>
  );
}
