import { Box, Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import { Loading } from 'components/widgets/Loading';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { Status } from 'lib/api/api-types';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util, Switch } from 'ui-lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import { IdType, IdTypeUpsert, useIdTypeService } from 'lib/api/use-id-type-service';

type IValues = Omit<IdType, 'createdAt' | 'updatedAt'>;

interface IProps {
  editingId?: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  onClose: () => void;
}

const DEBUG = false;

export function EditIdType(props: IProps) {
  const idTypeService = useIdTypeService();
  const errorHandler = useErrorHandler('EditOrg');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (formRef.current) {
      if (props.editingId) {
        idTypeService
          .idType(props.editingId)
          .then((resp) => {
            const designation: IdTypeUpsert = Util.convertBlanks(resp.data.idType, null, '');
            delete (designation as any).__typename;
            delete (designation as any).createdAt;
            delete (designation as any).updatedAt;
            DEBUG && ClientLogger.debug('EditIdType', 'load idType', { resp, org: designation });
            if (designation) {
              formRef.current?.setValues(designation);
            } else {
              errorHandler.handleErrors({ graphQLErrors: resp.errors });
            }
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [props.editingId, formRef.current]);

  async function save(values: IValues) {
    if (props.editingId) {
      const resp = await idTypeService.idTypeUpdate(values);
      DEBUG && ClientLogger.debug('IdType', 'update idType', { resp });
      if (resp.data?.idTypeUpdate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    } else {
      const jurisdictionId = userContext.$.currentOrgId();
      if (!jurisdictionId) {
        errorHandler.handleErrors({ error: 'No current org' });
      } else {
        const resp = await idTypeService.idTypeCreate({ ...values });
        DEBUG && ClientLogger.debug('IdType', 'create idType', { resp });
        if (resp.data?.idTypeCreate.id) {
          props.onClose();
        } else {
          errorHandler.handleErrors({ graphQLErrors: resp.errors });
        }
      }
    }
  }

  const dialogTitle = props.editingId ? 'Edit Identification Type' : 'Add Identification Type';

  DEBUG && ClientLogger.debug('editIdType', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        id: '',
        description: '',
        label: '',
        placeHolder: '',
        format: '',
        useExpiryDate: false,
        expiryDateLabel: '',
        expiryDatePlaceHolder: '',
        expiryDateFormat: '',
        status: Status.ACTIVE,
      }}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('CreatePatient', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="id"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth
                variant="outlined"
                sx={dialogClasses.inputField}
                data-test="name-textfield"
                disabled={props.editingId ? true : false}
              />
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                placeholder="Enter a description of this organization"
                type="text"
                fullWidth
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name="label"
                label="Label"
                placeholder="Enter the label used for this id type"
                type="text"
                fullWidth
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name="placeHolder"
                label="Place Holder"
                placeholder="Enter the place holder used for this id type"
                type="text"
                fullWidth
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name="format"
                label="Format (optional)"
                placeholder="Enter a format e.g. 000-000-0000 https://imask.js.org/"
                type="text"
                fullWidth
                variant="outlined"
                sx={dialogClasses.inputField}
              />
              <Box mt={2}>
                <Switch name="useExpiryDate" label="Use Expiry Date" />
              </Box>
              {values.useExpiryDate && (
                <>
                  <Field
                    component={TextField}
                    name="expiryDateLabel"
                    label="Expiry Date Label"
                    placeholder="Enter the label used for this id type's expiry date"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={dialogClasses.inputField}
                  />
                  <Field
                    component={TextField}
                    name="expiryDatePlaceHolder"
                    label="Expiry Place Holder"
                    placeholder="Enter the place holder used for this id type's expiry date"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={dialogClasses.inputField}
                  />
                  <Field
                    component={TextField}
                    name="expiryDateFormat"
                    label="Expiry Format (optional)"
                    placeholder="Enter a format e.g. MM-YY https://imask.js.org/"
                    type="text"
                    fullWidth
                    variant="outlined"
                    sx={dialogClasses.inputField}
                  />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
