import { Box, Grid, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { theme } from 'style/theme';
import { OrgAuth, OrgSignUp, SEVERITY, ToastDispatchContext, Util } from 'ui-lib';
import canadaFlag from '../../assets/Canada-Flag.svg';
import hrhLogo from '../../assets/HRH_Horizontal_Logo_withTag_Blue.png';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'Routes';
import { getVeridApolloClientWithoutContext } from 'lib/api/apollo-client';

export function LandingOrg() {
  const navigate = useNavigate();
  const toastDispatch = useContext(ToastDispatchContext);
  const { orgId, authConfigId } = useParams();
  const veridApolloClient = getVeridApolloClientWithoutContext();

  const spacing = theme.spacing(2);

  const cssClasses = {
    formHolder: {
      position: 'relative',
    },
    formContainer: {
      margin: '20vh auto 0 auto',
      width: '400px',
      maxWidth: '90vw',
      position: 'relative',
      minHeight: '40vh',
      padding: '24px',
      [theme?.breakpoints.down('sm')]: {
        margin: '4px auto',
        padding: '48px 16px 16px 16px',
      },
    },
    btnHolder: {
      width: '100%',
      marginBottom: spacing,
    },
    orgLogo: {
      width: '100%',
    },
    orgSubtitle: {
      width: '100%',
      position: 'absolute',
      left: 0,
      right: 0,
    },
    ctaBox: {
      maxWidth: '90vw',
      padding: '24px',
      [theme?.breakpoints.up('sm')]: {
        marginTop: '48px',
        width: '380px',
      },
    },
    hrhLogo: {
      width: '200px',
    },
    lpTR: {
      pointerEvents: 'none',
      position: 'fixed',
      top: 0,
      right: 0,
      width: '250px',
      [theme?.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    lpBL: {
      pointerEvents: 'none',
      position: 'fixed',
      left: '20px',
      bottom: '76px',
      width: '250px',
      [theme?.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    lpBR: {
      pointerEvents: 'none',
      position: 'fixed',
      right: '4px',
      bottom: '90px',
      width: '260px',
      [theme?.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    flag: {
      height: '24px',
      marginRight: '4px',
      verticalAlign: 'middle',
    },
    orContainer: {
      width: '100%',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    },
    btnPrimary: {
      padding: `16px 8px`,
    },
    containerTitle: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
  };

  useEffect(() => {
    const queryStrings = window.location.search;
    const errorMessage = Util.parseQueryString(queryStrings, 'errorMessage') || '';
    if (errorMessage) {
      toastDispatch({
        severity: SEVERITY.ERROR,
        msg: errorMessage,
      });
    }
  });

  const onClickSignIn = (role: string) => {
    navigate(`${ROUTES.LOGIN}?orgId=${orgId}&authConfigId=${authConfigId}&role=${role}`);
  };

  return (
    <>
      <Box sx={cssClasses.formHolder}>
        <Box sx={cssClasses.formContainer}>
          <Grid container alignItems="center" flexDirection="column">
            <Box my={2} component="img" src={hrhLogo} alt="Healix™" sx={cssClasses.orgLogo} />
            {orgId && authConfigId && (
              <OrgAuth
                orgId={orgId}
                orgAuthId={authConfigId}
                veridApolloClient={veridApolloClient}
                theme={theme}
                onClickSignIn={onClickSignIn}
              />
            )}
            <Box mt={1}>
              <Box component="img" src={canadaFlag} alt="Canada Flag" sx={cssClasses.flag} />
              <Typography component="span" variant="subtitle2">
                Proudly Canadian
              </Typography>
            </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
}
