import { theme } from '../../style/theme';

export const layoutStyles: any = {
  baseBg: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundImage: 'url("/assets/lp-bg.svg")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  wrapperPadding: {
    height: 'calc(100vh - 48px)',
    width: 'calc(100vw - 48px)',
    margin: '24px',
    overflowY: 'auto',
  },
  scroller: {
    scrollbarWidth: 'none',
  },
  contents: {
    marginTop: '10vh',
  },
  hamburgerMenuWrapper: {
    width: '100vw',
    padding: '40px 0 0 0',
    [theme.breakpoints.down('sm')]: {
      padding: '40px 24px 0 24px',
    },
  },
  hamburgBar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    zIndex: 1200,
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px',
    },
  },
  appWrapper: {
    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      top: 40,
      left: 0,
      right: 0,
    },
  },
};
