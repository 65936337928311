import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { teamsQuery } from 'lib/api/api-types';
import { EditTeam } from './EditTeam';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { Team, useTeamService } from 'lib/api/use-team-service';
import { SelectorIdName, SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { Loading } from 'components/widgets/Loading';
import { TeamMemberList } from './TeamMemberList';

interface ButtonColumnData {
  id: string;
  name: string;
}

export interface DisplayRowType extends Team {
  buttons: ButtonColumnData;
}

interface IProps {
  title: string;
}
const useClasses = makeStyles({ ...tableClasses });

const DEBUG = false;

export const TeamList = (props: IProps) => {
  const classes = useClasses();
  const teamService = useTeamService();
  const [editOrgOpen, setEditOrgOpen] = useState<false | undefined | string>(false); //false not open, undefined open with new, string teamId beiong edited
  const [memberEdit, setMemberEdit] = useState<false | string>(false); //false not open,  string teamId beiong edited
  const userContext = useContext(UserContext);
  const orgs = userContext.$.getOrgs();
  const orgsSelectList: SelectorIdName[] = orgs.map((org) => ({ id: org.id, name: org.name }));
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>(userContext.$.currentOrgId());
  const [teamName, setTeamName] = useState<string>('');

  const setSelectedOrgId = (orgId: string) => {
    setCurrentOrgId(orgId);
    userContext.$.saveCurrentOrgId(orgId);
  };

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    name: { label: 'Name' },
    description: { display: 'excluded' },
    orgId: { display: 'excluded' },
    updatedAt: { display: 'excluded' },
    createdAt: { label: 'Created At' },
    status: { label: 'Status' },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setTeamName(value.name);
                  setMemberEdit(value.id);
                }}
              >
                Members
              </Button>
            </Box>
          );
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  const transformResponseToRows = (resp: teamsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.teams || !resp.teams?.edges) {
      return [];
    }
    return resp.teams.edges.map((team) => ({ ...team.node, buttons: { id: team.node.id, name: team.node.name } }));
  };

  const rowClicked = (row: any) => {
    setEditOrgOpen(row.id);
  };

  const tableProps: DataTableProps<string, any, teamsQuery, DisplayRowType> = {
    useQuery: teamService.useTeamQuery({ variables: { orgId: currentOrgId || '' } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    emptyMessage: 'No teams found. Click the + button to create a new team.',
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOrgOpen(undefined);
    },
    onRowClick: rowClicked,
  };

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title={props.title} />
        <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
          <SelectorNoForm items={orgsSelectList} value={currentOrgId} onChange={(item) => setSelectedOrgId(item.id)} />
          {!currentOrgId ? (
            <Loading />
          ) : (
            <>
              <DataTable {...tableProps} />
            </>
          )}
        </Box>
      </Grid>
      <Dialog open={!(editOrgOpen === false)} onClose={() => setEditOrgOpen(false)} maxWidth="sm" fullWidth>
        <EditTeam
          onClose={() => {
            setEditOrgOpen(false);
          }}
          editingId={typeof editOrgOpen === 'string' ? editOrgOpen : undefined}
          dialogTitle={props.title}
          orgId={currentOrgId}
        />
      </Dialog>
      <Dialog open={!memberEdit === false} onClose={() => setMemberEdit(false)} maxWidth="sm" fullWidth>
        <TeamMemberList
          onClose={() => {
            setMemberEdit(false);
          }}
          teamId={memberEdit || ''}
          title={`Edit Team Members for team ${teamName}`}
          orgId={currentOrgId || ''}
        />
      </Dialog>
    </>
  );
};
