import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { agreementsQuery } from 'lib/api/api-types';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { SelectorIdName, SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { Loading } from 'components/widgets/Loading';
import { Agreement, useAgreementService } from 'lib/api/use-agreement-service';
import { AddAgreement } from './AddAgreement';
import { EditAgreement } from './EditAgreement';
import { dateDisplay } from 'lib/util';

interface ButtonColumnData {
  id: string;
  name: string;
}

export interface DisplayRowType extends Agreement {}

interface IProps {}
const useClasses = makeStyles({ ...tableClasses });

const DEBUG = false;

export const AgreementList = (props: IProps) => {
  const classes = useClasses();
  const agreementService = useAgreementService();
  const [addOpen, setAddOpen] = useState<boolean>(false);
  const [editOpenWithId, setEditOpenWithId] = useState<string | undefined>(undefined); // agreement id
  const userContext = useContext(UserContext);
  const orgs = userContext.$.getOrgs();
  const orgsSelectList: SelectorIdName[] = orgs.map((org) => ({ id: org.id, name: org.name }));
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>(userContext.$.currentOrgId());

  const setSelectedOrgId = (orgId: string) => {
    setCurrentOrgId(orgId);
    userContext.$.saveCurrentOrgId(orgId);
  };

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    content: { display: 'excluded' },
    name: { label: 'Name' },
    updatedAt: { display: 'excluded' },
    createdAt: { label: 'Created At', customBodyRender: (value: string) => dateDisplay(value) },
    latestVersion: { label: 'Latest' },
    roles: {
      label: 'Roles',
      customBodyRender: (value) => value.map((x) => `${x}`).join(', '),
    },
    requiresAcceptance: { display: 'excluded' },
    status: { display: 'excluded' },
  };

  const transformResponseToRows = (resp: agreementsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.agreements || !resp.agreements?.edges) {
      return [];
    }
    return resp.agreements.edges.map((agreement) => ({ ...agreement.node }));
  };

  const tableProps: DataTableProps<string, any, agreementsQuery, DisplayRowType> = {
    useQuery: agreementService.useAgreementQuery({ variables: { orgId: currentOrgId || '' } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    emptyMessage: 'No agreements found. Click the + button to create a new agreement.',
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setAddOpen(true);
    },
    onRowClick(row) {
      DEBUG && ClientLogger.debug('onRowClick', '', { row });
      setEditOpenWithId(row.id);
    },
  };

  DEBUG && ClientLogger.debug('AgreementsList', 'render', { tableProps });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title={'Agreements'} />
        <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
          <SelectorNoForm items={orgsSelectList} value={currentOrgId} onChange={(item) => setSelectedOrgId(item.id)} />
          {!currentOrgId ? (
            <Loading />
          ) : (
            <>
              <DataTable {...tableProps} />
              <Dialog open={!(addOpen === false)} onClose={() => setAddOpen(false)} maxWidth="sm" fullWidth>
                <AddAgreement
                  onClose={() => {
                    setAddOpen(false);
                  }}
                  orgId={currentOrgId}
                />
              </Dialog>
              <Dialog open={!!editOpenWithId} onClose={() => setEditOpenWithId(undefined)} maxWidth="sm" fullWidth>
                <EditAgreement
                  onClose={() => {
                    setEditOpenWithId(undefined);
                  }}
                  agreementId={editOpenWithId || ''}
                />
              </Dialog>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};
