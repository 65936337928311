import { ApolloClient } from '@apollo/client';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { OrgSummary, useVeridService } from '../lib/verid-api/use-verid-service';
import { Org, OrgType } from '../lib/verid-api/verid-api-types';

interface IProps {
  apolloClientVerid: ApolloClient<any>;
  readFromVerid?: boolean;
  selectOrg: (orgId: string) => void;
}

const cssClasses = {
  btnPrimary: {
    padding: `16px 8px`,
  },
  btnHolder: {
    width: '100%',
    marginBottom: '16px',
  },
};

export function OrgSelector(props: IProps) {
  const { apolloClientVerid, readFromVerid, selectOrg } = props;
  const [orgs, setOrgs] = useState<OrgSummary[]>([]);

  const veridService = useVeridService(apolloClientVerid);

  useEffect(() => {
    veridService
      .getOrgs({
        type: OrgType.PROVIDER,
      })
      .then((response) => {
        const result = response.data?.getAllOrgs;

        if (result && result.length > 0) {
          setOrgs(result);
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  }, []);
  return (
    <>
      {orgs.map((org) => {
        return (
          <Grid container flexDirection="column" alignItems="center" key={org.id}>
            <Box my={1} sx={cssClasses.btnHolder}>
              <Button sx={cssClasses.btnPrimary} variant="contained" fullWidth={true} color="primary" onClick={() => selectOrg(org.id)}>
                {org.name}
              </Button>
            </Box>
          </Grid>
        );
      })}
    </>
  );
}
