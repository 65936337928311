import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { EditIdType } from './EditIdType';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import { tableClasses } from '../../style/sharedCssClasses';
import { IdType, useIdTypeService } from 'lib/api/use-id-type-service';
import { idTypesQuery } from 'lib/api/api-types';

const DEBUG = false;

export const IdTypeList = () => {
  const idTypeService = useIdTypeService();
  const [editOpen, setEditOpen] = useState<boolean | undefined | string>(false); //false not open, undefined open with new, string orgId beiong edited

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { label: 'Name (id)' },
    updatedAt: { display: 'excluded' },
    description: { label: 'Description' },
    createdAt: { display: 'excluded' },
    label: { display: 'excluded' },
    placeHolder: { display: 'excluded' },
    format: { display: 'excluded' },
    useExpiryDate: { display: 'excluded' },
    expiryDateFormat: { display: 'excluded' },
    expiryDateLabel: { display: 'excluded' },
    expiryDatePlaceHolder: { display: 'excluded' },
    status: { label: 'Status' },
  };

  const transformResponseToRows = (resp: idTypesQuery | undefined): IdType[] => {
    if (!resp || !resp.idTypes || !resp.idTypes?.edges) {
      return [];
    }
    return resp.idTypes.edges.map((idType) => ({ ...idType.node }));
  };

  const rowClicked = (row: any) => {
    setEditOpen(row.id);
  };

  type NewType = DataTableProps<string, any, idTypesQuery, IdType>;

  const tableProps: NewType = {
    useQuery: idTypeService.useIdTypesQuery as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOpen(undefined);
    },
    onRowClick: rowClicked,
  };

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title="Identification Types" />
        <Box mx={2} mt={6} mb={1} sx={tableClasses.tableHolder}>
          <DataTable {...tableProps} />
        </Box>
      </Grid>
      <Dialog open={!(editOpen === false)} onClose={() => setEditOpen(false)} maxWidth="sm" fullWidth>
        <EditIdType
          onClose={() => {
            setEditOpen(false);
          }}
          editingId={typeof editOpen === 'string' ? editOpen : undefined}
        />
      </Dialog>
    </>
  );
};
