import { Grid, Typography } from '@mui/material';
import { Form } from 'formik';
import React, { ReactNode } from 'react';

import { FormError } from './FormError';

interface Props {
  children?: ReactNode;
  errorMessage?: ReactNode;
  title?: string;
  className?: string;
}

/**
 * A wrapper for forms to enforce standard borders, headers and form level errors
 *
 * @param children
 * @param errorMessage
 * @constructor
 */
export const FormContainer: React.FC<Props> = ({ children, errorMessage, title, className }) => {
  return (
    <Form className={className}>
      {title ? (
        <Typography variant="h4" align="center">
          {title}
        </Typography>
      ) : (
        <></>
      )}
      {errorMessage && <FormError errorMessage={errorMessage} />}
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {children}
      </Grid>
    </Form>
  );
};
