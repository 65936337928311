export enum OrgAuthConfigType {
  LOCALLY_PROVIDED = 'LOCALLY_PROVIDED',
  EXTERNALLY_PROVIDED = 'EXTERNALLY_PROVIDED',
  IDENTITY_PROVIDER = 'IDENTITY_PROVIDER',
  OPEN_ID = 'OPEN_ID',
  SAML = 'SAML',
}

export enum AuthAlgorithm {
  HMAC_SHA_256 = 'HMAC_SHA_256',
  HMAC_SHA_512 = 'HMAC_SHA_512',
  HMAC_SHA_384 = 'HMAC_SHA_384',
}

export enum CookieType {
  SET_COOKIE = 'SET_COOKIE',
  SET_HEADER = 'SET_HEADER',
  SET_URL_PARAMS = 'SET_URL_PARAMS',
}

export const HMACAlgorithm = {
  HMAC_SHA_256: 'sha256',
  HMAC_SHA_512: 'sha512',
  HMAC_SHA_384: 'sha384',
};

export interface AuthConfig {
  type: OrgAuthConfigType;
  authAlgorithm?: AuthAlgorithm;
  HMAC_secret?: string;
  jwtTokenDurationSeconds?: number;
  refreshTokenDurationMinutes?: number;
  passwordResetTokenDurationMinutes?: number;
  clientId?: string;
  clientSecret?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userInfoEndpoint?: string;
  buttonName?: string;
  loginPageSlug?: string;
  identityProviderId?: string;
  identityProviderName?: string;
  identityProviderSecret?: string;
  identityProviderKeyId?: string;
  identityProviderClientId?: string;
  identityProviderApiKey?: string;
  reconcileEndpoint?: string;
  reconcileTokenMode?: boolean;
  cookieType?: CookieType;
  cookieName?: string;
  openIdRoles?: string[];
}

export interface OrgAuthConfig {
  authName: string;
  veridRoles: string[];
  authConfig: AuthConfig | null;
  id: string;
}

export interface AuthConfigInput {
  type?: OrgAuthConfigType;
  authAlgorithm?: AuthAlgorithm;
  HMAC_secret?: string;
  jwtTokenDurationSeconds?: number;
  refreshTokenDurationMinutes?: number;
  passwordResetTokenDurationMinutes?: number;
  clientId?: string;
  clientSecret?: string;
  authorizationEndpoint?: string;
  tokenEndpoint?: string;
  userInfoEndpoint?: string;
  buttonName?: string;
  loginPageSlug?: string;
  openIdRoles?: string[];
  identityProviderId?: string;
  identityProviderName?: string;
  identityProviderSecret?: string;
  identityProviderKeyId?: string;
  identityProviderClientId?: string;
  identityProviderApiKey?: string;
  reconcileEndpoint?: string;
  reconcileTokenMode?: boolean;
  cookieType?: string;
  cookieName?: string;
}

export interface OrgAuthConfigInput {
  ADMIN?: AuthConfig;
  STAFF?: AuthConfig;
  USER?: AuthConfig;
}

export const OrgAuthConfigTypes = ['ADMIN', 'STAFF', 'USER'];

export enum OrgAuthTypes {
  ADMIN = 'ADMIN',
  STAFF = 'STAFF',
  USER = 'USER',
}

export const DefaultAuthConfig = {
  LOCALLY_PROVIDED: {
    type: OrgAuthConfigType.LOCALLY_PROVIDED,
    authAlgorithm: AuthAlgorithm.HMAC_SHA_256,
    jwtTokenDurationSeconds: null,
    refreshTokenDurationMinutes: null,
    passwordResetTokenDurationMinutes: null,
  },
  EXTERNALLY_PROVIDED: {
    type: OrgAuthConfigType.EXTERNALLY_PROVIDED,
    authAlgorithm: AuthAlgorithm.HMAC_SHA_256,
  },
  OPEN_ID: {
    type: OrgAuthConfigType.OPEN_ID,
  },
  IDENTITY_PROVIDER: {
    type: OrgAuthConfigType.IDENTITY_PROVIDER,
    reconcileTokenMode: true,
  },
  SAML: {},
};

export interface OpenIDParams {
  client_id: string;
  client_secret: string;
  scope: string;
  code: string;
  grant_type: string;
  redirect_uri: string;
}
