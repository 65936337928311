import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Loading } from 'components/widgets/Loading';
import { Formik, FormikProps } from 'formik';
import { Status, TeamRole } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import React, { useEffect, useRef, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useTeamMemberService } from 'lib/api/use-team-member-service';
import { TeamRoleSelector } from 'components/form/TeamRoleSelector';
import { StatusSelector } from 'components/form/StatusSelector';

interface IValues {
  role: TeamRole;
  status: Status;
}

interface IProps {
  editingId: string;
  onClose: () => void;
  dialogTitle: string;
}

const DEBUG = false;

export function EditTeamMember(props: IProps) {
  const teamMemberService = useTeamMemberService();
  const errorHandler = useErrorHandler('EditTeamMember');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (formRef.current) {
      if (props.editingId) {
        teamMemberService
          .teamMember(props.editingId)
          .then((resp) => {
            const teamMember = resp.data.teamMember;
            formRef.current?.setValues({ status: teamMember.status, role: teamMember.role });
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [props.editingId, formRef.current]);

  async function save(values: IValues) {
    const resp = await teamMemberService.teamMemberUpdate(props.editingId, values);
    DEBUG && ClientLogger.debug('EditOrg', 'save', { resp });
    if (resp.data?.teamMemberUpdate.id) {
      props.onClose();
    } else {
      errorHandler.handleErrors({ graphQLErrors: resp.errors });
    }
  }

  DEBUG && ClientLogger.debug('EditTeam', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        status: Status.ACTIVE,
        role: TeamRole.STAFF,
      }}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('EditTeam', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        DEBUG && ClientLogger.debug('EditTeam', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm });
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {props.dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <TeamRoleSelector name={'role'} />
              <StatusSelector name={'status'} />
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
