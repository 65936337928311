import { ApolloQueryResult, DocumentNode, FetchResult, gql, OperationVariables, QueryHookOptions, QueryResult } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import {
  personOrgQuery,
  personOrgCreateConnectPersonMutation,
  PersonOrgCreateConnectPersonInput,
  personOrgCreateConnectPersonMutationVariables,
  personOrgCreateNewPersonMutation,
  PersonOrgCreateNewPersonInput,
  personOrgCreateNewPersonMutationVariables,
  personOrgQueryVariables,
  personsQuery,
  personsQueryVariables,
  personUpdateMutation,
  personUpdateMutationVariables,
  personVerificationsQuery,
  personVerificationsQueryVariables,
  personOrgDeleteMutation,
  personDeleteMutation,
  PersonUpdateInput,
  personOrgUpsertMutation,
  personOrgUpsertMutationVariables,
  PersonOrgUpsertInput,
  PersonOrgChangeRoleInput,
  personOrgChangeRoleMutation,
  personOrgChangeRoleMutationVariables,
} from './api-types';
import { persons_persons_edges_node, personOrg_personOrg, personOrgs_personOrgs_edge_node } from './api-types-parts';
import { useApi } from './use-api';
import { PERSON_FIELDS, PERSON_ORG_FIELDS, PERSON_VERIFICATION_FIELDS, useAuthService } from './use-auth-service';
import { VERIFICATION_METHOD_FIELDS } from './use-verification-method-service';

const DEBUG = true;

export type Person = Omit<persons_persons_edges_node, '__typename'>;
export type PersonOrg = Omit<personOrg_personOrg, '__typename' | 'person'>;
export type PersonOrgsNode = Omit<personOrgs_personOrgs_edge_node, '__typename'>;

export function usePersonOrgService() {
  const api = useApi();
  const authService = useAuthService();

  const personsQuery = gql`
    ${PERSON_ORG_FIELDS}
    ${PERSON_FIELDS}
    query persons($orderBy: PersonOrder, $first: Int, $after: String, $query: PersonSearchInput!) {
      persons(query: $query, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...PersonFragment
            personOrgs {
              ...PersonOrgFragment
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;

  return {
    usePersonsQuery(options: QueryHookOptions<personsQuery, personsQueryVariables> | undefined) {
      function fct(): QueryResult<personsQuery, personsQueryVariables> {
        return api.useQuery(personsQuery, options);
      }
      return fct;
    },

    async personOrg(id: string): Promise<ApolloQueryResult<personOrgQuery>> {
      return api.query<personOrgQuery, personOrgQueryVariables>({
        query: gql`
          ${PERSON_FIELDS}
          ${PERSON_ORG_FIELDS}
          query personOrg($id: String!) {
            personOrg(id: $id) {
              ...PersonOrgFragment
              person {
                ...PersonFragment
              }
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async personVerifications(orgId: string, id: string): Promise<ApolloQueryResult<personVerificationsQuery>> {
      return api.query<personVerificationsQuery, personVerificationsQueryVariables>({
        query: gql`
          ${PERSON_VERIFICATION_FIELDS}
          ${PERSON_FIELDS}
          ${PERSON_ORG_FIELDS}
          ${VERIFICATION_METHOD_FIELDS}
          query personVerifications($orgId: String!, $id: String!) {
            personVerifications(orgId: $orgId, id: $id) {
              ...PersonVerificationFragment
              createdBy {
                ...PersonOrgFragment
              }
              person {
                ...PersonFragment
              }
              method {
                ...VerificationMethodFragment
              }
            }
          }
        `,
        variables: { orgId, id },
        fetchPolicy: 'network-only',
      });
    },

    async personOrgCreateNewPerson(personOrg: PersonOrgCreateNewPersonInput): Promise<FetchResult<personOrgCreateNewPersonMutation>> {
      return api.mutate<personOrgCreateNewPersonMutation, personOrgCreateNewPersonMutationVariables>({
        mutation: gql`
          mutation personOrgCreateNewPerson($data: PersonOrgCreateNewPersonInput!) {
            personOrgCreateNewPerson(data: $data) {
              id
            }
          }
        `,
        variables: { data: personOrg },
        refetchQueries: ['persons'],
      });
    },
    async personOrgCreateConnectPerson(
      personOrg: PersonOrgCreateConnectPersonInput
    ): Promise<FetchResult<personOrgCreateConnectPersonMutation>> {
      return api.mutate<personOrgCreateConnectPersonMutation, personOrgCreateConnectPersonMutationVariables>({
        mutation: gql`
          mutation personOrgCreateConnectPerson($data: PersonOrgCreateConnectPersonInput!) {
            personOrgCreateConnectPerson(data: $data) {
              id
            }
          }
        `,
        variables: { data: personOrg },
        refetchQueries: ['persons'],
      });
    },

    async personOrgUpsert(data: PersonOrgUpsertInput): Promise<FetchResult<personOrgUpsertMutation>> {
      return api.mutate<personOrgUpsertMutation, personOrgUpsertMutationVariables>({
        mutation: gql`
          mutation personOrgUpsert($data: PersonOrgUpsertInput!) {
            personOrgUpsert(data: $data) {
              created
              entity {
                id
              }
              success
              updated
            }
          }
        `,
        variables: { data },
        refetchQueries: ['persons'],
      });
    },

    async personOrgChangeRole(data: PersonOrgChangeRoleInput): Promise<FetchResult<personOrgChangeRoleMutation>> {
      return api.mutate<personOrgChangeRoleMutation, personOrgChangeRoleMutationVariables>({
        mutation: gql`
          mutation personOrgChangeRole($data: PersonOrgChangeRoleInput!) {
            personOrgChangeRole(data: $data) {
              created
              entity {
                id
              }
              success
              updated
            }
          }
        `,
        variables: { data },
        refetchQueries: ['persons'],
      });
    },

    async personUpdate(data: PersonUpdateInput): Promise<FetchResult<personUpdateMutation>> {
      return api.mutate<personUpdateMutation, personUpdateMutationVariables>({
        mutation: gql`
          mutation personUpdate($data: PersonUpdateInput!) {
            personUpdate(data: $data) {
              id
            }
          }
        `,
        variables: { data },
        refetchQueries: ['persons'],
      });
    },

    async personOrgDelete(personOrgId: string): Promise<FetchResult<personOrgDeleteMutation>> {
      const deletedPersonOrg = await api.mutate({
        mutation: gql`
          mutation personOrgDelete($id: String!) {
            personOrgDelete(id: $id) {
              id
            }
          }
        `,
        variables: { id: personOrgId },
        refetchQueries: ['persons'],
      });
      await authService.getMe();
      return deletedPersonOrg;
    },

    async personDelete(personId: string): Promise<FetchResult<personDeleteMutation>> {
      const deletePerson = await api.mutate({
        mutation: gql`
          mutation personDelete($id: String!) {
            personDelete(id: $id) {
              id
            }
          }
        `,
        variables: { id: personId },
        refetchQueries: ['persons'],
      });
      await authService.getMe();
      return deletePerson;
    },

    personOrgCalc(person: Person, orgId: string | undefined): PersonOrg | undefined {
      if (!orgId) {
        return undefined;
      }
      const personOrg = person.personOrgs.find((p) => p.orgId === orgId);
      return personOrg;
    },

    sysAccountsForOrg(orgId: string) {
      return api.query({
        query: gql`
          query personOrgs($orderBy: PersonOrgOrder, $first: Int, $after: String, $query: PersonOrgSearchInput!) {
            personOrgs(query: $query, orderBy: $orderBy, first: $first, after: $after) {
              edges {
                cursor
                node {
                  id
                  person {
                    firstName
                    lastName
                    id
                  }
                }
              }
              pageInfo {
                endCursor
                hasNextPage
                hasPreviousPage
                startCursor
              }
              totalCount
            }
          }
        `,
        variables: { query: { orgId: orgId } },
      });
    },
  };
}
