import { ApolloLink, Observable, Operation, NextLink, FetchResult } from '@apollo/client';

import { ApolloClientContext } from '../api/apollo-client';
import { isJwtExpired, logout, refreshTokenFct, tokenHolder } from '../api/use-auth-service';
import { ClientLogger } from '../client-logger';

import { OperationQueuing } from './queuing';

const DEBUG = false;

export function authHeader() {
  const { accessToken } = tokenHolder;
  const jwtHeader = accessToken ? `Bearer ${accessToken}` : '';
  const ret = {
    headers: {
      authorization: jwtHeader,
    },
  };
  DEBUG && ClientLogger.debug('TokenRefreshLink.authHeader', 'returning', JSON.stringify({ ret }));
  return ret;
}

// Adopted from https://github.com/newsiberian/apollo-link-token-refresh/tree/master/src
export class TokenRefreshLink extends ApolloLink {
  private queue: OperationQueuing;
  private fetching: boolean;

  constructor() {
    super();
    this.queue = new OperationQueuing();
    this.fetching = false;
  }

  public request(operation: Operation, forward: NextLink): Observable<FetchResult> {
    DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'start', { operation });

    if (typeof forward !== 'function') {
      throw new Error('[Token Refresh Link]: Token Refresh Link is non-terminating link and should not be the last in the composed chain');
    }
    // If token does not exists, which could means that this is a not registered
    // user request, or if it is does not expired -- act as always

    const context: ApolloClientContext = operation.getContext();

    if (context.noAuth) {
      DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'No Auth Needed for this request. Forwarding', { operation, context });
      return forward(operation);
    }
    if (!this.fetching) {
      if (!isJwtExpired()) {
        DEBUG &&
          ClientLogger.debug('TokenRefreshLink.request', 'Jwt is OK. Forwarding', {
            operation,
            context,
            expiryReadable: tokenHolder.jwt?.exp ? new Date(tokenHolder.jwt?.exp * 1000) : 'undefined',
          });
        operation.setContext({ ...authHeader() });
        return forward(operation);
      }
      DEBUG &&
        ClientLogger.debug('TokenRefreshLink.request', 'Jwt is NOT OK. Requesting', {
          operation,
          context,
          expiryReadable: tokenHolder.jwt?.exp ? new Date(tokenHolder.jwt?.exp * 1000) : 'undefined',
        });

      this.fetching = true;
      DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Calling exchangeRefreshTokenForJwtToken');
      refreshTokenFct()
        .then((refreshResp) => {
          DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'Refresh Token Response', { refreshResp });
          if (refreshResp) {
            this.queue.consumeQueue();
            DEBUG && ClientLogger.debug('TokenRefreshLink.request', `successful exchangeRefreshTokenForJwtToken`);
          } else {
            ClientLogger.error('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Token not renewed');
            logout();
            this.queue.nukeQueue();
          }
        })
        .catch((e) => {
          ClientLogger.error('TokenRefreshLink.exchangeRefreshTokenForJwtToken', 'Error renewing token', e);
          logout();
          this.queue.nukeQueue();
        })
        .finally(() => {
          this.fetching = false;
          DEBUG && ClientLogger.debug('TokenRefreshLink.request', `finally`);
        });
    }
    DEBUG && ClientLogger.debug('TokenRefreshLink.request', 'end');

    return this.queue.enqueueRequest({
      operation,
      forward,
    });
  }
}
