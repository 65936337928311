import { Selector, IdName } from 'ui-lib';
import { useIdTypeService } from 'lib/api/use-id-type-service';
import { ClientLogger } from 'lib/client-logger';

interface IProps {
  name: string;
  selectMultiple?: boolean;
}

const DEBUG = false;

export function IdTypeSelector(props: IProps) {
  const idTypeService = useIdTypeService();
  const idTypesQuery = idTypeService.useIdTypesQuery({});
  const data = idTypesQuery.data?.idTypes.edges || [];
  const choices: IdName[] = data.map((r) => ({ id: r.node.id, name: r.node.id })) || [];
  const loading = idTypesQuery.loading;

  DEBUG &&
    ClientLogger.debug('IdTypeSelector', 'render', {
      choices,
    });

  if (loading) {
    return <> </>;
  }
  return (
    <>
      <Selector selectMultiple={props.selectMultiple} name={`${props.name}`} items={choices} label="Id Type" />
    </>
  );
}
