import { ApolloQueryResult, FetchResult, gql, QueryHookOptions } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import {
  orgQuery,
  getAllOrgsQuery,
  orgCreateMutation,
  OrgCreateInput,
  orgCreateMutationVariables,
  orgOrgCreateMutation,
  OrgRelation,
  OrgRelationInput,
  orgsQuery,
  orgsQueryVariables,
  OrgType,
  orgUpdateMutation,
  OrgUpdateInput,
  orgUpdateMutationVariables,
  orgQueryVariables,
  generateHMACSecretMutation,
  generateHMACSecretMutationVariables,
  getAllOrgsQueryVariables,
  orgDeleteMutation,
} from './api-types';
import { orgs_orgs_edges_node } from './api-types-parts';
import { useApi } from './use-api';
import { useAuthService } from './use-auth-service';

const DEBUG = true;

export type Org = Omit<orgs_orgs_edges_node, '__typename'>;
export type OrgEditData = Omit<Org, 'id' | 'createdAt' | 'updatedAt'> & { jurisdictionId?: string };

const STANDARD_FIELDS = gql`
  fragment StandardFragment on Org {
    id
    createdAt
    updatedAt
  }
`;

export const ORG_FIELDS = gql`
  fragment OrgFragment on Org {
    name
    attributes
    authConfig
    extMessageConfig
    autoOnboard
    description
    emailDomain
    externalId
    isTest
    status
    type
  }
`;
export function useOrgService() {
  const api = useApi();
  const authService = useAuthService();
  const orgsQuery = gql`
    ${ORG_FIELDS}
    ${STANDARD_FIELDS}
    query orgs($name: String, $relation: OrgRelationSearchInput, $type: OrgType, $orderBy: OrgOrder, $first: Int, $after: String) {
      orgs(query: { name: $name, type: $type, relation: $relation }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...StandardFragment
            ...OrgFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useOrgsQuery(options: QueryHookOptions<orgsQuery, orgsQueryVariables> | undefined) {
      return api.useQuery(orgsQuery, options);
    },

    useOrgsQueryFactory(options: QueryHookOptions<orgsQuery, orgsQueryVariables> | undefined) {
      return () => api.useQuery(orgsQuery, options);
    },

    useOrgsQueryProvider(options: QueryHookOptions<orgsQuery, orgsQueryVariables> | undefined) {
      if (options?.variables) {
        options.variables.type = OrgType.PROVIDER;
      } else {
        ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
      }
      return api.useQuery(orgsQuery, options);
    },

    useOrgsQueryApp(options: QueryHookOptions<orgsQuery, orgsQueryVariables> | undefined) {
      if (options?.variables) {
        options.variables.type = OrgType.APP;
      } else {
        ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
      }
      return api.useQuery(orgsQuery, options);
    },

    useOrgsQueryJurisdiction(options: QueryHookOptions<orgsQuery, orgsQueryVariables> | undefined) {
      if (options?.variables) {
        options.variables.type = OrgType.JURISDICTION;
      } else {
        ClientLogger.error('useOrgService.useOrgsQuery', 'options.variables is undefined');
      }
      return api.useQuery(orgsQuery, options);
    },

    async orgs() {
      DEBUG && ClientLogger.debug('useOrgService.orgs', `started`);
      const resp = await api.query<orgsQuery, orgsQueryVariables>({
        query: orgsQuery,
        variables: {},
        fetchPolicy: 'network-only',
      });
      DEBUG && ClientLogger.debug('useCsaApi.getMe', 'Response', resp);
      return resp;
    },

    async org(id: string): Promise<ApolloQueryResult<orgQuery>> {
      return api.query<orgQuery, orgQueryVariables>({
        query: gql`
          ${STANDARD_FIELDS}
          ${ORG_FIELDS}
          query org($id: String!) {
            org(id: $id) {
              ...StandardFragment
              ...OrgFragment
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async getAllOrgs(orgType: OrgType): Promise<ApolloQueryResult<getAllOrgsQuery>> {
      return api.query<getAllOrgsQuery, getAllOrgsQueryVariables>({
        query: gql`
          query getAllOrgs($data: OrgType!) {
            getAllOrgs(data: $data) {
              id
              name
              authConfig
            }
          }
        `,
        variables: { data: orgType },
        fetchPolicy: 'network-only',
      });
    },

    async orgCreate(org: OrgCreateInput): Promise<FetchResult<orgCreateMutation>> {
      const retVal = await api.mutate<orgCreateMutation, orgCreateMutationVariables>({
        mutation: gql`
          mutation orgCreate($data: OrgCreateInput!) {
            orgCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: org },
        refetchQueries: ['orgs'],
      });
      await authService.getMe();
      return retVal;
    },

    async orgUpdate(orgId: string, org: OrgUpdateInput): Promise<FetchResult<orgUpdateMutation>> {
      return api.mutate<orgUpdateMutation, orgUpdateMutationVariables>({
        mutation: gql`
          mutation orgUpdate($id: String!, $data: OrgUpdateInput!) {
            orgUpdate(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: { data: org, id: orgId },
        refetchQueries: ['orgs'],
      });
    },

    async generateHMACSecret(orgId: string, authAlgorithm: string): Promise<FetchResult<generateHMACSecretMutation>> {
      return api.mutate<generateHMACSecretMutation, generateHMACSecretMutationVariables>({
        mutation: gql`
          mutation generateHMACSecret($id: String!, $authAlgorithm: String!) {
            generateHMACSecret(id: $id, authAlgorithm: $authAlgorithm)
          }
        `,
        variables: { authAlgorithm, id: orgId },
        refetchQueries: ['orgs'],
      });
    },

    async orgOrgCreate(orgRelation: OrgRelation, org1Id: string, org2Id: string): Promise<FetchResult<orgOrgCreateMutation>> {
      const orgRelationInput: OrgRelationInput = {
        type: orgRelation,
        org1Id,
        org2Id,
      };
      return api.mutate({
        mutation: gql`
          mutation orgOrgCreate($data: OrgRelationInput!) {
            orgOrgCreate(data: $data) {
              id
              relation
              status
            }
          }
        `,
        variables: { data: orgRelationInput },
        refetchQueries: ['orgs'],
      });
    },

    async orgOrgDelete(orgRelation: OrgRelation, org1Id: string, org2Id: string): Promise<FetchResult<orgOrgCreateMutation>> {
      const orgRelationInput: OrgRelationInput = {
        type: orgRelation,
        org1Id,
        org2Id,
      };
      return api.mutate({
        mutation: gql`
          mutation orgOrgDelete($data: OrgRelationInput!) {
            orgOrgDelete(data: $data) {
              id
              relation
              status
              org1Id
              org2Id
            }
          }
        `,
        variables: { data: orgRelationInput },
        refetchQueries: ['orgs'],
      });
    },

    async orgDelete(orgId: string): Promise<FetchResult<orgDeleteMutation>> {
      const deletedOrg = await api.mutate({
        mutation: gql`
          mutation orgDelete($id: String!) {
            orgDelete(id: $id) {
              id
            }
          }
        `,
        variables: { id: orgId },
        refetchQueries: ['orgs'],
      });
      await authService.getMe();
      return deletedOrg;
    },
  };
}
