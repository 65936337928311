import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Navbar } from 'components/navigation/Navbar';
import React from 'react';
import { LayoutProps } from './layout-props';
import { layoutStyles } from './layout-styles';

const useClasses = makeStyles({ ...layoutStyles });

export const LayoutHamburgerMenuBar = ({ children }: LayoutProps) => {
  const classes = useClasses();
  return (
    <Box className={classes.hamburgerMenuWrapper}>
      <Box className={classes.hamburgBar}>
        <Navbar />
      </Box>
      <Box className={classes.baseBg} />
      <Box className={classes.appWrapper}>{children}</Box>
    </Box>
  );
};
