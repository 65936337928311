import {
  ApolloQueryResult,
  OperationVariables,
  QueryOptions,
  MutationOptions,
  FetchResult,
  NetworkStatus,
  useMutation,
  useQuery,
  gql,
} from '@apollo/client';
import { MutationHookOptions, MutationTuple, QueryHookOptions, QueryResult } from '@apollo/client/react/types/types';
import { DocumentNode } from 'graphql';

import { getVeridApolloClient, getVeridApolloClientWithoutContext } from './apollo-client';
import { UserContext } from '../auth/user-context';
import { useContext } from 'react';

export function useApi() {
  const userContext = useContext(UserContext);

  return {
    query<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables>): Promise<ApolloQueryResult<T>> {
      return getVeridApolloClient().query({ errorPolicy: 'all', context: userContext, ...options });
    },

    queryWithoutContext<T = any, TVariables = OperationVariables>(options: QueryOptions<TVariables>): Promise<ApolloQueryResult<T>> {
      return getVeridApolloClientWithoutContext().query({ errorPolicy: 'all', ...options });
    },

    mutate<T = any, TVariables = OperationVariables>(options: MutationOptions<T, TVariables>): Promise<FetchResult<T>> {
      return getVeridApolloClient().mutate({ errorPolicy: 'all', context: userContext, ...options });
    },

    useMutation<T = any, TVariables = OperationVariables>(
      mutation: DocumentNode,
      options?: MutationHookOptions<T, TVariables>
    ): MutationTuple<T, TVariables> {
      return useMutation(mutation, { client: getVeridApolloClient(), context: userContext, ...options });
    },

    useQuery<TData = any, TVariables = OperationVariables>(
      query: DocumentNode,
      options: QueryHookOptions<TData, TVariables> | undefined
    ): QueryResult<TData, TVariables> {
      return useQuery(query, { client: getVeridApolloClient(), context: userContext, ...options });
    },

    generateErrorResponse(error: any) {
      return {
        errors: [error],
        loading: false,
        networkStatus: NetworkStatus.error,
        stale: false,
      };
    },

    userContext,
  };
}
