import { FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, Typography, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { getYear } from 'date-fns';
import { ErrorMessage } from 'formik';
import _ from 'lodash';
import { useEffect, useState } from 'react';

import makeStyles from '@mui/styles/makeStyles';
import { datePartsToDate, IDateParts, splitDateToParts } from '../date-util';

interface IProps {
  theme: any;
  dateFieldName: string;
  value: string;
  errors: any;
  touched: any;
  readonly: boolean;
  setFieldValue: (name: string, value: any, shouldValidate?: boolean) => void;
}

interface IVal {
  name: string;
  value: string;
}

export function YearMonthDay(props: IProps) {
  const { theme } = props;
  const spacing = theme.spacing;

  const useClasses = makeStyles({
    fieldLabel: {
      zIndex: 10,
      backgroundColor: '#fff',
    },
    fieldLabelError: {
      zIndex: 10,
      backgroundColor: '#fff',
      color: '#B00020',
    },
    yearField: {
      width: 90,
      marginRight: spacing(1),
    },
    monthField: {
      width: 130,
      marginRight: spacing(1),
    },
    dateField: {
      width: 70,
    },
    genderField: {
      width: '100%',
    },
    caption: {
      width: '100%',
    },
    selectComponent: {
      '& legend': {
        visibility: 'hidden',
        maxWidth: 0.01,
      },
    },
    errorBorder: {
      border: '1px solid #B00020',
    },
    errorMessage: {
      color: '#B00020',
    },
    touched: {
      '& $label': {
        color: 'inherit !important',
      },
      '& [id^="date"]': {
        color: 'inherit !important',
      },
      '& [class*="notchedOutline"]': {
        borderColor: 'inherit !important',
      },
    },
  });
  const classes = useClasses();
  const { readonly, dateFieldName, value, errors, touched, setFieldValue } = props;
  const [dateParts, setDateParts] = useState<IDateParts>({ dateYear: '', dateMonth: '', dateDate: '' });

  const year = getYear(new Date());
  const yearRange = { start: year - 100, stop: year };
  const showSmDn = useMediaQuery(theme.breakpoints.down('sm'));

  const createNumberSeries = (start: number, stop: number, pad?: number) => {
    let items: any[] = [];
    if (showSmDn) {
      items.push(
        <option key={-1} value={-1}>
          —
        </option>
      );
    }

    let vals: string[] = [];
    if (start < stop) {
      for (let i = start; i <= stop; i++) {
        vals.push(i.toString());
      }
    } else {
      for (let i = start; i >= stop; i--) {
        vals.push(i.toString());
      }
    }
    for (let i = 0; i < vals.length; i++) {
      let val = vals[i];
      let label = val;
      if (pad) {
        while (val.length < pad) {
          val = '0' + val;
        }
      }
      if (showSmDn) {
        items.push(
          <option key={i} value={val}>
            {label}
          </option>
        );
      } else {
        items.push(
          <MenuItem key={i} value={val}>
            {label}
          </MenuItem>
        );
      }
    }
    return items;
  };

  const createMonths = () => {
    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let items: any[] = [];
    if (showSmDn) {
      items.push(
        <option key={-1} value={-1}>
          —
        </option>
      );
    }
    for (let i = 1; i <= 12; i++) {
      let val = i.toString();
      while (val.length < 2) {
        val = '0' + val;
      }
      if (showSmDn) {
        items.push(
          <option key={i} value={val}>
            {months[i - 1]}
          </option>
        );
      } else {
        items.push(
          <MenuItem key={i} value={val}>
            {months[i - 1]}
          </MenuItem>
        );
      }
    }
    return items;
  };

  useEffect(() => {
    const date = splitDateToParts(value);
    setDateParts(date);
  }, []);

  const updateValues = (val: IVal) => {
    let updated: IDateParts = { ...dateParts };
    const key = val.name;
    _.set(updated, `${key}`, val.value);
    const dob = datePartsToDate(updated);
    setDateParts(updated);
    setFieldValue(dateFieldName, dob?.toISOString() || '', true);
  };

  return (
    <>
      <Grid container wrap="nowrap" direction="row">
        <Grid item>
          <FormControl className={clsx([classes.yearField, dateParts.dateYear && classes.touched])}>
            <InputLabel className={classes.fieldLabel}>Year</InputLabel>
            {showSmDn ? (
              <NativeSelect
                id="dateYear"
                name="dateYear"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateYear}
                onChange={(val) => updateValues(val.target as IVal)}
              >
                {createNumberSeries(yearRange.stop, yearRange.start)}
              </NativeSelect>
            ) : (
              <Select
                id="dateYear"
                name="dateYear"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateYear}
                onChange={(val) => updateValues(val.target as IVal)}
                data-test="new-patient-year-of-birth-textfield"
              >
                {createNumberSeries(yearRange.stop, yearRange.start)}
              </Select>
            )}

            <ErrorMessage component={Typography} className={errors.dateYear && touched.dateYear && classes.errorMessage} name="dateYear" />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={clsx([classes.monthField, dateParts.dateMonth && classes.touched])}>
            <InputLabel className={classes.fieldLabel} htmlFor="dateMonth">
              Month
            </InputLabel>
            {showSmDn ? (
              <NativeSelect
                id="dateMonth"
                name="dateMonth"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateMonth}
                onChange={(val) => updateValues(val.target as IVal)}
              >
                {createMonths()}
              </NativeSelect>
            ) : (
              <Select
                id="dateMonth"
                name="dateMonth"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateMonth}
                onChange={(val) => updateValues(val.target as IVal)}
                data-test="new-patient-month-of-birth-textfield"
              >
                {createMonths()}
              </Select>
            )}

            <ErrorMessage component={Typography} className={classes.errorMessage} name="dateMonth" />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl className={clsx([classes.dateField, dateParts.dateDate && classes.touched])}>
            <InputLabel className={classes.fieldLabel} htmlFor="dateDay">
              Day
            </InputLabel>
            {showSmDn ? (
              <NativeSelect
                id="dateDate"
                name="dateDate"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateDate}
                onChange={(val) => updateValues(val.target as IVal)}
              >
                {createNumberSeries(1, 31, 2)}
              </NativeSelect>
            ) : (
              <Select
                id="dateDate"
                name="dateDate"
                variant="outlined"
                className={classes.selectComponent}
                disabled={readonly}
                value={dateParts.dateDate}
                onChange={(val) => updateValues(val.target as IVal)}
                data-test="new-patient-day-of-birth-textfield"
              >
                {createNumberSeries(1, 31, 2)}
              </Select>
            )}

            <ErrorMessage component={Typography} className={classes.errorMessage} name="dateDay" />
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
}
