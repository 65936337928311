import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import CloseIcon from '@mui/icons-material/Clear';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps, EmailOnlyField, Password, SEVERITY, ToastDispatchContext } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { personOrgCreateConnectPersonMutation, personOrgCreateNewPersonMutation, personsQuery } from 'lib/api/api-types';
import { Person, usePersonOrgService } from 'lib/api/use-person-org-serivce';
import { IdName } from 'ui-lib';
import { dialogClasses } from 'style/sharedCssClasses';
import { Formik, FormikProps } from 'formik';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'verid-shared-lib';
import { FetchResult } from '@apollo/client';

interface ButtonColumnData {
  person: Person;
}

export interface DisplayRowType extends Person {
  buttons: ButtonColumnData;
}

export interface SearchCriteria {
  lastName?: string;
  firstName?: string;
}

interface IProps {
  searchCriteria: SearchCriteria;
  selected: (person: Person, id?: string) => void;
  buttons?: IdName[];
  role?: any;
  onClose: () => void;
  org: any;
}

interface IValues {
  email?: string;
  password?: string;
}

const DEBUG = true;

export const PersonOrgSearchResult = (props: IProps) => {
  const personOrgService = usePersonOrgService();
  const formRef = useRef<FormikProps<IValues>>(null);
  const errorHandler = useErrorHandler('EditOrg');
  const [emailModal, setEmailModal] = useState(false);
  const [personData, setPersonData] = useState<any>();
  const toastDispatch = useContext(ToastDispatchContext);

  const dataTableOptions: MUIDataTableOptions = {
    pagination: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: false,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    firstName: { label: 'First Name' },
    lastName: { label: 'Last Name' },
    updatedAt: { display: 'excluded' },
    createdAt: { display: 'excluded' },
    personOrgs: { display: 'excluded' },
    id: { display: 'excluded' },
    attributes: { display: 'excluded' },
    currentOrg: { display: 'excluded' },
    isTest: { display: 'excluded' },
    status: { display: 'excluded' },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          const buttons: React.ReactElement[] = [];
          const buttonSpec = props.buttons || [{ id: 'use', name: 'Use' }];
          for (const button of buttonSpec) {
            buttons.push(
              <Button
                key={button.id}
                color="primary"
                variant="contained"
                onClick={() => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setEmailModal(true);
                  setPersonData(value.person);
                }}
              >
                {button.name}
              </Button>
            );
          }
          return buttons;
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  async function save(values: IValues) {
    DEBUG && ClientLogger.debug('Add Existing person Org', 'save', { values });
    const role = props.role;
    let resp: FetchResult<personOrgCreateNewPersonMutation | personOrgCreateConnectPersonMutation>;
    let id;
    if (!values.email) {
      errorHandler.handleErrors({ error: 'Email ID is required' });
      return;
    }

    if (props.org && !Util.checkValidEmailDomain(props.org.emailDomain, role, values.email)) {
      errorHandler.handleErrors({ error: `Email domain must end with ${props.org.emailDomain[role]}` });
      return;
    }
    if (personData.id) {
      const respConnect = await personOrgService.personOrgCreateConnectPerson({
        orgId: props.org.id,
        role,
        existingPersonId: personData.id,
        email: values.email,
        password: values.password,
      });
      id = respConnect.data?.personOrgCreateConnectPerson?.id;
      resp = respConnect;

      if (id) {
        if (personData.id) {
          toastDispatch({
            severity: SEVERITY.SUCCESS,
            msg: `Existing person record for ${personData.firstName} ${personData.lastName} as added to this organization`,
          });
        }
        DEBUG && ClientLogger.debug('AddPersonOrg', 'closing');
        setEmailModal(false);
        props.onClose();
      } else {
        setEmailModal(false);
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    }
  }

  const transformResponseToRows = (resp: personsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.persons || !resp.persons?.edges) {
      return [];
    }
    return resp.persons.edges.map((person) => ({
      ...person.node,
      buttons: { person: person.node },
    }));
  };

  type NewType = DataTableProps<string, any, personsQuery, DisplayRowType>;

  const tableProps: NewType = {
    useQuery: personOrgService.usePersonsQuery({
      errorPolicy: 'all',
      variables: { query: { firstName: props.searchCriteria.firstName, lastName: props.searchCriteria.lastName } },
    }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
  };

  DEBUG && ClientLogger.debug('PersonOrgSearchResult', 'render', { props });

  if (!props.searchCriteria || JSON.stringify(props.searchCriteria) === '{}') {
    return <></>;
  }
  return (
    <>
      <DataTable {...tableProps} />
      <Formik
        innerRef={formRef}
        initialValues={{
          email: '',
          password: '',
        }}
        onSubmit={(values, actions) => {
          DEBUG && ClientLogger.debug('Create Email for existing user', `onSubmit values`, values);
          save(values);
        }}
      >
        {({ values }) => {
          return (
            <>
              <Dialog open={emailModal} fullWidth>
                <DialogTitle sx={dialogClasses.dialogTitle}>
                  Add Email
                  <IconButton size="large">
                    <CloseIcon sx={dialogClasses.closeIcon} onClick={() => setEmailModal(false)} />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <EmailOnlyField />
                  <Password name="password" fullWidth={true} />
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button color="primary" onClick={() => setEmailModal(false)}>
                      Cancel
                    </Button>
                    <Button color="primary" onClick={() => save(values)}>
                      Submit
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </>
  );
};
