import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { EditDesignation } from 'verid-ui/src/pages/designation/EditDesignation';
import { Designation, useDesignationService } from 'lib/api/use-designation-service';
import { designationsQuery } from 'lib/api/api-types';
import { TopBackBtnBar } from 'verid-ui/src/components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from 'verid-ui/src/style/sharedCssClasses';

const useClasses = makeStyles({ ...tableClasses });

const DEBUG = true;

export const DesignationList = () => {
  const classes = useClasses();
  const designationService = useDesignationService();
  const [editOpen, setEditOpen] = useState<boolean | undefined | string>(false); //false not open, undefined open with new, string orgId beiong edited

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { label: 'Name (id)' },
    updatedAt: { display: 'excluded' },
    description: { label: 'Description' },
    createdAt: { label: 'Created At' },
    status: { label: 'Status' },
  };

  const transformResponseToRows = (resp: designationsQuery | undefined): Designation[] => {
    if (!resp || !resp.designations || !resp.designations?.edges) {
      return [];
    }
    return resp.designations.edges.map((designations) => ({ ...designations.node }));
  };

  const rowClicked = (row: any) => {
    setEditOpen(row.id);
  };

  type NewType = DataTableProps<string, any, designationsQuery, Designation>;

  const tableProps: NewType = {
    useQuery: designationService.useDesignationsQuery as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOpen(undefined);
    },
    onRowClick: rowClicked,
  };

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title="Designations" />
        <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
          <DataTable {...tableProps} />
        </Box>
      </Grid>
      <Dialog open={!(editOpen === false)} onClose={() => setEditOpen(false)} maxWidth="sm" fullWidth>
        <EditDesignation
          onClose={() => {
            setEditOpen(false);
          }}
          editingId={typeof editOpen === 'string' ? editOpen : undefined}
        />
      </Dialog>
    </>
  );
};
