import React, { useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { FormContainer } from 'components/form/FormContainer';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Selector, Util } from 'ui-lib';
import CloseIcon from '@mui/icons-material/Clear';
import { dialogClasses } from '../../style/sharedCssClasses';
import { DefaultAuthConfig, OrgAuthTypes, OrgAuthConfigType, OrgAuthConfig } from 'verid-shared-lib';
import { EditAuthConfig } from './EditAuthConfig';
import { ValidateSchema } from '../../lib/AuthConfigValidateSchema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { theme } from 'style/theme';
import makeStyles from '@mui/styles/makeStyles';

const DEBUG = true;

const padding = theme.spacing(2);

const useClasses = makeStyles({
  dialogContent: {
    width: `calc(100% - ${padding} * 4)`,
  },
});

enum PanelsEnum {
  LANDING_PAGE = 'LANDING_PAGE',
  SIGNIN_PAGE = 'SIGNIN_PAGE',
  SIGNUP_PAGE = 'SIGNUP_PAGE',
}

function AuthConfigTypeSelector(props: {
  baseName: string;
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) {
  const choices: any = Util.idNamePairsFromEnum(OrgAuthConfigType);

  return (
    <Selector
      name={props.name}
      items={choices}
      label="Authentication Type"
      onChange={(type) => props.setFieldValue(`${props.baseName}`, DefaultAuthConfig[type])}
    />
  );
}

function VeridRoleTypeSelector(props: {
  name: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}) {
  const choices: any = Util.idNamePairsFromEnum(OrgAuthTypes);

  return <Selector name={props.name} items={choices} label="Verid Role" selectMultiple={true} />;
}

export const AuthConfigModal = (props: {
  editingId: string;
  isUpdate: boolean;
  initialValues: OrgAuthConfig;
  onSubmit: (value: any, isUpdateConfig: boolean) => void;
  onClose: () => void;
}) => {
  const formikRef = useRef<any>();
  const [panelOpen, setPanelOpen] = useState<PanelsEnum | null>(null);
  const classes = useClasses();

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        onSubmit={async (values, actions) => {
          props.onSubmit(values, props.isUpdate);
        }}
        validationSchema={ValidateSchema}
        innerRef={formikRef}
      >
        {({ submitForm, values, setFieldValue, errors, touched, validateForm, setFieldTouched }) => {
          return (
            <Box>
              <IconButton style={{ float: 'right' }} onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
              <FormContainer>
                <DialogTitle>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{props.isUpdate ? ' Update Org AuthConfig' : 'Create Org AuthConfig'}</Typography>
                  </Box>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="id"
                      label={`Auth Id`}
                      placeholder="Enter Auth ID"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Box>
                  <Box my={1}>
                    <Field
                      component={TextField}
                      name="authName"
                      label={`Auth Name`}
                      placeholder="Enter AuthName"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Box>
                  <Grid item xs={12}>
                    <VeridRoleTypeSelector name="veridRoles" setFieldValue={setFieldValue} />
                  </Grid>
                  <Grid item xs={12}>
                    <AuthConfigTypeSelector baseName="authConfig" name="authConfig.type" setFieldValue={setFieldValue} />
                    {values.authConfig && values.authConfig.type && (
                      <EditAuthConfig
                        baseName="authConfig"
                        setFieldValue={setFieldValue}
                        values={values.authConfig}
                        editingId={props.editingId}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Accordion expanded={panelOpen === PanelsEnum.LANDING_PAGE} onChange={() => setPanelOpen(PanelsEnum.LANDING_PAGE)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6">Landing Page Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`landingPage.logoSubTitle`}
                          label="Logo Subtitle"
                          placeholder="Enter logo subtitle"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`landingPage.title`}
                          label="Title"
                          placeholder="Enter title"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`landingPage.subTitle`}
                          label="Subtitle"
                          placeholder="Enter subtitle"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`landingPage.buttonCreateAccount`}
                          label="Button Create Account"
                          placeholder="Enter button create account text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`landingPage.buttonSignIn`}
                          label="Button Sign In"
                          placeholder="Enter button sign in text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Accordion expanded={panelOpen === PanelsEnum.SIGNIN_PAGE} onChange={() => setPanelOpen(PanelsEnum.SIGNIN_PAGE)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6">SignIn Page Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.title`}
                          label="Title"
                          placeholder="Enter title"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.formTitle`}
                          label="Form Title"
                          placeholder="Enter form title"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.inputEmailLabel`}
                          label="Input Email Label"
                          placeholder="Enter input email label"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.inputPasswordLabel`}
                          label="Input Password Label"
                          placeholder="Enter input password label"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.resetPasswordSection.textPreLink`}
                          label="Reset Password Section - Text Pre Link"
                          placeholder="Enter text before link in password reset section"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.resetPasswordSection.textLink`}
                          label="Reset Password Section - Text Link"
                          placeholder="Enter link text in password reset section"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.resetPasswordSection.link`}
                          label="Reset Password Section - Link"
                          placeholder="Enter link in password reset section"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.buttonSignIn`}
                          label="Button Sign In"
                          placeholder="Enter button signin text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.buttonMagicLink`}
                          label="Button Magic Link"
                          placeholder="Enter button magic link text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.signUpRedirectText`}
                          label="Sign Up Redirect"
                          placeholder="Enter signup redirect text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signInPage.signUpRedirectButton`}
                          label="Button Sign Up Redirect"
                          placeholder="Enter signup redirect button text"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Accordion expanded={panelOpen === PanelsEnum.SIGNUP_PAGE} onChange={() => setPanelOpen(PanelsEnum.SIGNUP_PAGE)}>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                        <Typography variant="h6">SignUp Page Configuration</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.textTopRight`}
                          label="Top Right Text"
                          placeholder="Enter text in top right"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.privacyNote.title`}
                          label="Privacy Note - Title"
                          placeholder="Enter title for privacy note section"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.privacyNote.description`}
                          label="Privacy Note - Description"
                          placeholder="Enter description for privacy note section"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step1.title`}
                          label="Step 1 - Title"
                          placeholder="Enter title in step 1"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step1.textFieldTop.label`}
                          label="Step 1 - Top Text Field - Label"
                          placeholder="Enter label for top text field in step 1"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step1.textFieldTop.placeholder`}
                          label="Step 1 - Top Text Field - Placeholder"
                          placeholder="Enter placeholder for top text field in step 1"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step1.textFieldBottom.label`}
                          label="Step 1 - Bottom Text Field - Label"
                          placeholder="Enter label for bottom text field in step 1"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step1.textFieldBottom.placeholder`}
                          label="Step 1 - Bottom Text Field - Placeholder"
                          placeholder="Enter placeholder for bottom text field in step 1"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step2.title`}
                          label="Step 2 - Title"
                          placeholder="Enter title in step 2"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step2.textField.label`}
                          label="Step 2 - Text Field - Label"
                          placeholder="Enter label for text field in step 2"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.step2.textField.placeholder`}
                          label="Step 2 - Text Field - Placeholder"
                          placeholder="Enter placeholder for text field in step 2"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepSubmit.title`}
                          label="Step Submit - Title"
                          placeholder="Enter title in step submit"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepSubmit.resendSection.title`}
                          label="Step Submit - Resend Section - Title"
                          placeholder="Enter title for resend section in step submit"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepSubmit.resendSection.subtitle`}
                          label="Step Submit - Resend Section - Subtitle"
                          placeholder="Enter subtitle for resend section in step submit"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepSubmit.resendSection.button`}
                          label="Step Submit - Resend Section - Button"
                          placeholder="Enter button text for resend section in step submit"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorOnboard.title`}
                          label="Step Error Onboard - Title"
                          placeholder="Enter title in step error onboard"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorOnboard.subtitle`}
                          label="Step Error Onboard - Subtitle"
                          placeholder="Enter subtitle in step error onboard"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorOnboard.buttonPrimary`}
                          label="Step Error Onboard - Primary Button"
                          placeholder="Enter primary button text in step error onboard"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorOnboard.buttonSecondary`}
                          label="Step Error Onboard - Secondary Button"
                          placeholder="Enter secondary button text in step error onboard"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepCheckEmail.title`}
                          label="Step Check Email - Title"
                          placeholder="Enter title in step check email"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepCheckEmail.subtitle`}
                          label="Step Check Email - Subtitle"
                          placeholder="Enter subtitle in step check email"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepCheckEmail.textBottom`}
                          label="Step Check Email - Bottom Text"
                          placeholder="Enter bottom text in step check email"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorLinkExpired.title`}
                          label="Step Error Link Expired - Title"
                          placeholder="Enter title in step error link expired"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorLinkExpired.subtitle`}
                          label="Step Error Link Expired - Subtitle"
                          placeholder="Enter subtitle in step error link expired"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                      <AccordionDetails>
                        <Field
                          component={TextField}
                          name={`signUpPage.stepErrorLinkExpired.buttonPrimary`}
                          label="Step Error Link Expired - Primary Button"
                          placeholder="Enter primary button text in step error link expired"
                          type="text"
                          fullWidth={true}
                          variant="outlined"
                        />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button color="primary" onClick={() => props.onClose()}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        submitForm();
                      }}
                    >
                      {' '}
                      {props.isUpdate ? ' Update' : 'Create'}
                    </Button>
                  </Box>
                </DialogActions>
              </FormContainer>
            </Box>
          );
        }}
      </Formik>
    </>
  );
};
