import { Field, FieldProps } from 'formik';
import { ClientLogger } from 'lib/client-logger';
import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'style/reactQuill/style.css';
import { dialogClasses } from 'style/sharedCssClasses';

interface IProps {
  name: string;
  label: string;
}

const DEBUG = false;

export function RichTextField(props: IProps) {
  const { label, name } = props;
  return (
    <>
      <Field name={name} sx={dialogClasses.inputField} type="text" fullWidth={true} variant="outlined">
        {(formikProps: FieldProps) => {
          const { values, setFieldValue } = formikProps.form;
          DEBUG && ClientLogger.debug('RichTextField', 'render', { formikProps, values, setFieldValue });
          return (
            <>
              <label>{label}</label>
              {/* Odd behavior with space.  Without pre-wrap first space ignored due to setFieldValue.  With pre-wrap , first enter ignored. */}
              <ReactQuill
                style={{ whiteSpace: 'pre-wrap', height: '200px', marginBottom: '30px' }}
                value={values[name] || ''}
                formats={['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image']}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                  ],
                }}
                onChange={(value: any) => {
                  DEBUG && ClientLogger.debug('RichTextField', 'ReactQuill onChange', { value });
                  setFieldValue(name, value);
                }}
              />
            </>
          );
        }}
      </Field>
    </>
  );
}
