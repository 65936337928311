import { ApolloQueryResult, FetchResult, gql, QueryHookOptions, QueryResult } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import {
  Agreement as AgreementGenerated,
  AgreementAcceptanceCreateInput,
  agreementAcceptanceCreateMutation,
  agreementAcceptanceCreateMutationVariables,
  AgreementCreateInput,
  agreementCreateMutation,
  agreementCreateMutationVariables,
  AgreementFragmentFragment,
  agreementQuery,
  agreementQueryVariables,
  AgreementRequiredFragmentFragment,
  agreementsQuery,
  agreementsQueryVariables,
  agreementsRequiredQuery,
  agreementsRequiredQueryVariables,
  AgreementUpdateInput,
  agreementUpdateMutation,
  agreementUpdateMutationVariables,
} from './api-types';
import { useApi } from './use-api';

const DEBUG = false;

export type Agreement = Omit<AgreementFragmentFragment, '__typename'>;
export type AgreementRequired = Omit<AgreementRequiredFragmentFragment, '__typename'>;

export const AGREEMENT_FIELDS = gql`
  fragment AgreementFragment on Agreement {
    id
    createdAt
    updatedAt
    name
    status
    latestVersion
    roles
    content
    requiresAcceptance
  }
`;
export const AGREEMENT_VERSION_FIELDS = gql`
  fragment AgreementVersionFragment on AgreementVersion {
    id
    createdAt
    version
    requiresAcceptance
    content
  }
`;
export const AGREEMENT_REQURIED_FIELDS = gql`
  fragment AgreementRequiredFragment on AgreementRequired {
    agreementId
    agreementName
    content
    orgName
    version
    versionId
  }
`;

export function useAgreementService() {
  const api = useApi();
  const agreementssQuery = gql`
    ${AGREEMENT_FIELDS}
    query agreements($name: String, $orgId: String!, $orderBy: AgreementOrder, $first: Int, $after: String) {
      agreements(query: { name: $name, orgId: $orgId }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...AgreementFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useAgreementQuery(options: QueryHookOptions<agreementsQuery, agreementsQueryVariables> | undefined) {
      function fct(): QueryResult<agreementsQuery, agreementsQueryVariables> {
        return api.useQuery(agreementssQuery, options);
      }
      return fct;
    },

    async agreement(id: string): Promise<ApolloQueryResult<agreementQuery>> {
      return api.query<agreementQuery, agreementQueryVariables>({
        query: gql`
          ${AGREEMENT_FIELDS}
          ${AGREEMENT_VERSION_FIELDS}
          query agreement($id: String!) {
            agreement(id: $id) {
              ...AgreementFragment
              versions {
                ...AgreementVersionFragment
              }
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async agreementCreate(data: AgreementCreateInput): Promise<FetchResult<agreementCreateMutation>> {
      const retVal = await api.mutate<agreementCreateMutation, agreementCreateMutationVariables>({
        mutation: gql`
          mutation agreementCreate($data: AgreementCreateInput!) {
            agreementCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data },
        refetchQueries: ['agreements'],
      });
      return retVal;
    },

    async agreementUpdate(agreementId: string, data: AgreementUpdateInput): Promise<FetchResult<agreementUpdateMutation>> {
      return api.mutate<agreementUpdateMutation, agreementUpdateMutationVariables>({
        mutation: gql`
          mutation agreementUpdate($id: String!, $data: AgreementUpdateInput!) {
            agreementUpdate(data: $data, id: $id) {
              id
            }
          }
        `,
        variables: { data, id: agreementId },
        refetchQueries: ['agreements'],
      });
    },

    async agreementsRequired(): Promise<ApolloQueryResult<agreementsRequiredQuery>> {
      return api.query<agreementsRequiredQuery, agreementsRequiredQueryVariables>({
        query: gql`
          ${AGREEMENT_REQURIED_FIELDS}
          query agreementsRequired {
            agreementsRequired {
              ...AgreementRequiredFragment
            }
          }
        `,
      });
    },

    async agreementAcceptanceCreate(data: AgreementAcceptanceCreateInput): Promise<FetchResult<agreementAcceptanceCreateMutation>> {
      return api.mutate<agreementAcceptanceCreateMutation, agreementAcceptanceCreateMutationVariables>({
        mutation: gql`
          mutation agreementAcceptanceCreate($data: AgreementAcceptanceCreateInput!) {
            agreementAcceptanceCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data },
        refetchQueries: ['agreementsRequired'],
      });
    },
  };
}
