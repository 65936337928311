import { FabProps } from '@mui/material';
import Fab from '@mui/material/Fab';
import React from 'react';

const cssClasses = {
  floatingButton: {
    position: 'fixed',
    bottom: '40px',
    right: '40px',
  },
};

interface IProps extends FabProps {
  children: any;
}

export function FABBottomRight(props: IProps) {
  return (
    <Fab
      color="primary"
      data-test={props.children.testLabels}
      sx={cssClasses.floatingButton}
      {...Object.assign({}, props, { classes: undefined })}
    >
      {props.children}
    </Fab>
  );
}
