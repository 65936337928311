import { Box, Button, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, MenuItem, Typography } from '@mui/material';
import { Loading } from 'components/widgets/Loading';
import { Field, Formik, FormikProps } from 'formik';
import { Select, TextField } from 'formik-mui';
import { Status, TeamUpdateInput } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'ui-lib';
import React, { useEffect, useRef, useState } from 'react';
import { dialogClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { Team, TeamEditData, useTeamService } from 'lib/api/use-team-service';

type IValues = Omit<Team, 'id' | 'createdAt' | 'updatedAt'>;

interface IProps {
  editingId?: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  onClose: () => void;
  dialogTitle: string;
  orgId?: string;
}

const DEBUG = false;

export function EditTeam(props: IProps) {
  const teamService = useTeamService();
  const errorHandler = useErrorHandler('EditTeam');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);
  const dialogTitle = props.editingId ? `Edit ${props.dialogTitle} item` : `Add to ${props.dialogTitle}`;

  useEffect(() => {
    if (formRef.current) {
      if (props.editingId) {
        teamService
          .team(props.editingId)
          .then((resp) => {
            const team: TeamEditData = Util.convertBlanks(resp.data.team, null, '');
            delete (team as any).__typename;
            DEBUG && ClientLogger.debug('EditTeam', 'load team', { resp, org: team });
            if (team) {
              formRef.current?.setValues(team);
            } else {
              errorHandler.handleErrors({ graphQLErrors: resp.errors });
            }
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [props.editingId, formRef.current]);

  async function save(values: IValues) {
    if (props.editingId) {
      const data: TeamUpdateInput = { name: values.name, status: values.status, description: values.description };
      const resp = await teamService.teamUpdate(props.editingId, data);
      DEBUG && ClientLogger.debug('EditTean', 'update', { resp });
      if (resp.data?.teamUpdate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    } else {
      DEBUG && ClientLogger.debug('EditTean', 'creating org');
      const resp = await teamService.teamCreate({ ...values });
      DEBUG && ClientLogger.debug('EditTean', 'create', { resp });
      if (resp.data?.teamCreate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    }
  }

  DEBUG && ClientLogger.debug('EditTeam', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        name: '',
        description: '',
        status: Status.ACTIVE,
        orgId: props.orgId || '',
      }}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('EditTeam', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        DEBUG && ClientLogger.debug('EditTeam', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm });
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="name"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="name-textfield"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                placeholder="Enter a description of this organization"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="new-patient-middle-name-textfield"
                sx={dialogClasses.inputField}
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
