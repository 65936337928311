import React from 'react';
import { Box } from '@mui/material';

export const BlankHeaderCell = () => {
  return (
    <Box
      component="th"
      sx={{ backgroundColor: '#eee', zIndex: 100, position: 'sticky', top: 0, borderBottom: '1px solid #E0E0E0' }}
      className="MuiTableCell-head"
    >
      &nbsp;
    </Box>
  );
};
