import { SelectorIdName, SelectorNoForm, IdName } from 'ui-lib';
import { OrgType } from 'lib/api/api-types';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import * as React from 'react';

interface IProps {
  orgType: OrgType;
  onChange: (item: SelectorIdName) => void;
}

const DEBUG = false;

export function OrgSelector(props: IProps) {
  const orgService = useOrgService();
  const orgsQuery = orgService.useOrgsQuery({ variables: { type: props.orgType } });
  const data = orgsQuery.data?.orgs.edges || [];
  const choices: IdName[] = data.map((r) => ({ id: r.node.id, name: r.node.name })) || [];
  const loading = orgsQuery.loading;
  const [selected, setSelected] = React.useState<string>('');
  const label = ((orgType) => {
    switch (orgType) {
      case OrgType.APP:
        return 'Application';
      case OrgType.JURISDICTION:
        return 'Jurisdiction';
      case OrgType.PROVIDER:
        return 'Provider';
      default:
        throw new Error(`Unknown org type: ${orgType}`);
    }
  })(props.orgType);
  DEBUG &&
    ClientLogger.debug('OrgSelector', 'render', {
      choices,
      label,
    });

  if (loading) {
    return <> </>;
  }
  return (
    <>
      <SelectorNoForm
        items={choices}
        label={label}
        onChange={(newVal) => {
          props.onChange(newVal);
          setSelected(newVal.id);
        }}
        value={selected}
      />
    </>
  );
}
