import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import React, { ReactNode, useState } from 'react';

interface IProps {
  title: string;
  message: string | ReactNode;
  showDialog: boolean;
  confirmed: (confirm: boolean, input?: string) => void;
  isVerifiableInput?: boolean;
  btnCancel?: string;
  btnConfirm?: string;
  disabled?: boolean;
}

export function ConfirmDialog(props: IProps) {
  const { title, message, showDialog, confirmed, btnCancel, btnConfirm, isVerifiableInput, disabled } = props;
  const [verifiableInput, setVerifiableInput] = useState<string>('');

  const disableBtns = disabled || false;

  return (
    <Dialog disableEnforceFocus open={showDialog} onClose={(event, reason) => reason !== 'backdropClick' && confirmed}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {typeof message === 'string' ? <DialogContentText>{message}</DialogContentText> : message}
        {isVerifiableInput && (
          <TextField
            autoFocus
            margin="dense"
            id="verifiableInput"
            data-test="confirm-dialog-input"
            type="text"
            fullWidth
            onChange={(event) => setVerifiableInput(event.target.value)}
            variant="standard"
            value={verifiableInput}
          />
        )}
      </DialogContent>

      <DialogActions>
        {btnCancel && (
          <Button onClick={() => confirmed(false, verifiableInput)} color="primary" disabled={disableBtns}>
            {btnCancel}
          </Button>
        )}
        {btnConfirm && (
          <Button
            onClick={() => confirmed(true, verifiableInput)}
            color="primary"
            disabled={disableBtns}
            data-test="button-confirm-dialog-ok"
          >
            {btnConfirm}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
