import BackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Clear';
import { Box, Paper, Typography } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import makeStyles from '@mui/styles/makeStyles';

// label is clickable, title is not
interface IProps {
  label?: string | null;
  title?: string | null;
  btnType?: 'back' | 'close';
  goBack: () => void;
}

const useClasses = makeStyles({
  btnLabel: {
    display: 'inline-block',
    fontWeight: 'bold',
    marginRight: 6,
  },
  btnIcon: {
    marginRight: 8,
  },
  closeIcon: {
    marginRight: 8,
    color: '#000',
  },
});
export function BackButton(props: IProps) {
  const classes = useClasses();

  const linkClicked = (event: SyntheticEvent) => {
    event.preventDefault();
    props.goBack();
  };

  return (
    <Box display="flex">
      <a onClick={(event) => linkClicked(event)} href="">
        {props.btnType && props.btnType === 'close' ? (
          <CloseIcon className={classes.closeIcon} />
        ) : (
          <BackIcon color="primary" className={classes.btnIcon} />
        )}
        {props.label && (
          <Typography color="primary" display="inline" className={classes.btnLabel}>
            {props.label}
          </Typography>
        )}
      </a>
      {props.title && <Typography display="inline">{props.title}</Typography>}
    </Box>
  );
}
