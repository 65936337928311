import { ApolloClient } from '@apollo/client';
import { placeholder } from '@babel/types';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';
import { Alert, Box, Button, Checkbox, Grid, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { ReactNode, useContext, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormContainer } from '../form';
import { useVeridService } from '../lib/verid-api/use-verid-service';
import { SEVERITY, ToastDispatchContext } from '../toast/Toast';
import { EMAIL_REGEX, Util } from '../util';

interface IStyle {
  formContainer?: any;
  privacy?: any;
  formInputs?: any;
  inputField?: any;
  formSignin?: any;
  emailImg?: any;
}

interface IValues {
  firstName: string;
  lastName: string;
  email: string;
  token: string | null;
  text?: string;
  acknowledged?: boolean;
}

interface IProps {
  veridApolloClient: ApolloClient<any>;
  theme: any;
  style?: IStyle;
  orgId: string | null;
  authConfigId: string | null;
  recaptchaEnabled?: boolean;
  requestCode?: (email: string, mobile: string) => void;
  sectionTOSPolicy?: ReactNode;
  sectionAlreadyHaveAccount?: ReactNode;
  onSubmitFormLinkExpired?: (email: string) => void;
  imgVerifyEmailSrc?: string;
  imgSystemErrorSrc?: string;
  imgHourGlassSrc?: string;
  signUp?: (
    firstName: string,
    lastName: string,
    email: string,
    token: string | null,
    acknowledged?: boolean
  ) => Promise<{ success: boolean; errorMessages?: any }>;
}

interface AuthConfigSignUpPage {
  textTopRight?: string;
  step1?: {
    title?: string;
    textFieldTop?: {
      label?: string;
      placeholder?: string;
    };
    textFieldBottom?: {
      label?: string;
      placeholder?: string;
    };
  };
  step2?: {
    title?: string;
    textField?: {
      label?: string;
      placeholder?: string;
    };
  };
  stepSubmit?: {
    title?: string;
    resendSection?: {
      title?: string;
      subtitle?: string;
      button?: string;
    };
  };
  stepErrorOnboard?: {
    title?: string;
    subtitle?: string;
    buttonPrimary?: string;
    buttonSecondary?: string;
  };
  stepCheckEmail?: {
    title?: string;
    subtitle?: string;
    textBottom?: string;
  };
  stepErrorLinkExpired?: {
    title?: string;
    subtitle?: string;
    buttonPrimary?: string;
  };
  privacyNote?: {
    title?: string;
    description?: string;
  };
}

const authConfigSignUpPageDefaultValues: AuthConfigSignUpPage = {
  textTopRight: '2 min to complete',
  privacyNote: {
    title: 'Privacy Note:',
    description: 'All the data you share with Healix is private and can only be shared with your consent.',
  },
  step1: {
    title: 'What is your name?',
    textFieldTop: {
      label: 'First Name (required)',
      placeholder: 'Enter first name',
    },
    textFieldBottom: {
      label: 'Last Name (required)',
      placeholder: 'Enter last name',
    },
  },
  step2: {
    title: 'What is your email address?',
    textField: {
      label: 'Email (required)',
      placeholder: 'Enter email',
    },
  },
  stepSubmit: {
    title: 'Verify your Email Address',
    resendSection: {
      title: 'Didn’t get the email?',
      subtitle: "Check your Spam, Promotions, or search for an email from Healix. Still don't see it? Click the button below.",
      button: 'Resend Verification Email',
    },
  },
  stepErrorOnboard: {
    title: 'Hmm, looks like you already have an account in Healix!',
    subtitle: 'Do you want to receive a login link to',
    buttonPrimary: 'Send me a magic link',
    buttonSecondary: 'Try with another email',
  },
  stepCheckEmail: {
    title: 'Check Your Inbox',
    subtitle: 'Click on the magic link we sent to the following email address to sign in to your account.',
    textBottom: 'Try with another email',
  },
  stepErrorLinkExpired: {
    title: 'Your email verification link has expired.',
    subtitle: 'To log in to your account, request a new magic link.',
    buttonPrimary: 'Send Me a Magic Link',
  },
};

enum EnumSteps {
  NAME,
  EMAIL,
  SUBMIT,
  CHECK_EMAIL,
  ERROR_ONBOARDED,
  ERROR_LINKEXPIRED,
}

export function OrgSignUp(props: IProps) {
  const {
    veridApolloClient,
    orgId,
    authConfigId,
    theme,
    style,
    recaptchaEnabled,
    requestCode,
    sectionTOSPolicy,
    sectionAlreadyHaveAccount,
    onSubmitFormLinkExpired,
    imgVerifyEmailSrc,
    imgSystemErrorSrc,
    imgHourGlassSrc,
    signUp,
  } = props;

  const spacing = theme.spacing(2);

  const useClasses = makeStyles({
    formContainer: {
      margin: '0 auto 64px auto',
      width: '400px',
      maxWidth: '90vw',
      position: 'relative',
      minHeight: '40vh',
      padding: '24px',
      [theme.breakpoints.down('sm')]: {
        margin: '4px auto',
        padding: '48px 16px 16px 16px',
      },
    },
    inputField: {
      width: '100%',
      marginBottom: spacing,
    },
    emailImg: {
      width: '100px',
      marginBottom: '12px',
    },
    formInputs: {
      height: 320,
    },
    formSignin: {
      height: 50,
    },
    privacy: {
      backgroundColor: '#EDF1F3',
      borderRadius: 10,
    },
  });
  const classes = useClasses();
  const newUser: IValues = {
    firstName: '',
    lastName: '',
    email: '',
    token: null,
    acknowledged: false,
  };
  const [submittedValues, setSubmittedValues] = useState<IValues>(newUser);
  const [step, setStep] = useState<EnumSteps>(EnumSteps.NAME);
  const [pswdlessRequested, setPswdlessRequested] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [recaptchaFailed, setRecaptchaFailed] = useState(false);
  const [resending, setResending] = useState(false);
  const [signUpPage, setSignUpPage] = useState<AuthConfigSignUpPage>(authConfigSignUpPageDefaultValues);
  const toastDispatch = useContext(ToastDispatchContext);
  const veridService = useVeridService(veridApolloClient);

  useEffect(() => {
    const queryStrings = location.search;
    if (queryStrings) {
      if (Util.parseQueryString(queryStrings, 'linkfail') === '1') {
        setStep(EnumSteps.ERROR_LINKEXPIRED);
      }
    }
    window.scrollTo(0, 0);

    if (orgId) {
      veridService
        .getOrg(orgId)
        .then((response: any) => {
          const org = response.data?.org;

          if (org && org.authConfig && org.authConfig.length > 0) {
            const authConfig = org.authConfig.find((item: any) => item.id === authConfigId);
            setSignUpPage({
              textTopRight: authConfig?.signUpPage?.textTopRight || authConfigSignUpPageDefaultValues.textTopRight,
              privacyNote: {
                title: authConfig?.signUpPage?.privacyNote?.title || authConfigSignUpPageDefaultValues.privacyNote?.title,
                description: authConfig?.signUpPage?.privacyNote?.description || authConfigSignUpPageDefaultValues.privacyNote?.description,
              },
              step1: {
                title: authConfig?.signUpPage?.step1?.title || authConfigSignUpPageDefaultValues.step1?.title,
                textFieldTop: {
                  label: authConfig?.signUpPage?.step1?.textFieldTop?.label || authConfigSignUpPageDefaultValues.step1?.textFieldTop?.label,
                  placeholder:
                    authConfig?.signUpPage?.step1?.textFieldTop?.placeholder ||
                    authConfigSignUpPageDefaultValues.step1?.textFieldTop?.placeholder,
                },
                textFieldBottom: {
                  label:
                    authConfig?.signUpPage?.step1?.textFieldBottom?.label ||
                    authConfigSignUpPageDefaultValues.step1?.textFieldBottom?.label,
                  placeholder:
                    authConfig?.signUpPage?.step1?.textFieldBottom?.placeholder ||
                    authConfigSignUpPageDefaultValues.step1?.textFieldBottom?.placeholder,
                },
              },
              step2: {
                title: authConfig?.signUpPage?.step2?.title || authConfigSignUpPageDefaultValues.step2?.title,
                textField: {
                  label: authConfig?.signUpPage?.step2?.textField?.label || authConfigSignUpPageDefaultValues.step2?.textField?.label,
                  placeholder:
                    authConfig?.signUpPage?.step2?.textField?.placeholder ||
                    authConfigSignUpPageDefaultValues.step2?.textField?.placeholder,
                },
              },
              stepSubmit: {
                title: authConfig?.signUpPage?.stepSubmit?.title || authConfigSignUpPageDefaultValues.stepSubmit?.title,
                resendSection: {
                  title:
                    authConfig?.signUpPage?.stepSubmit?.resendSection?.title ||
                    authConfigSignUpPageDefaultValues.stepSubmit?.resendSection?.title,
                  subtitle:
                    authConfig?.signUpPage?.stepSubmit?.resendSection?.subtitle ||
                    authConfigSignUpPageDefaultValues.stepSubmit?.resendSection?.subtitle,
                  button:
                    authConfig?.signUpPage?.stepSubmit?.resendSection?.button ||
                    authConfigSignUpPageDefaultValues.stepSubmit?.resendSection?.button,
                },
              },
              stepErrorOnboard: {
                title: authConfig?.signUpPage?.stepErrorOnboard?.title || authConfigSignUpPageDefaultValues.stepErrorOnboard?.title,
                subtitle:
                  authConfig?.signUpPage?.stepErrorOnboard?.subtitle || authConfigSignUpPageDefaultValues.stepErrorOnboard?.subtitle,
                buttonPrimary:
                  authConfig?.signUpPage?.stepErrorOnboard?.buttonPrimary ||
                  authConfigSignUpPageDefaultValues.stepErrorOnboard?.buttonPrimary,
                buttonSecondary:
                  authConfig?.signUpPage?.stepErrorOnboard?.buttonSecondary ||
                  authConfigSignUpPageDefaultValues.stepErrorOnboard?.buttonSecondary,
              },
              stepCheckEmail: {
                title: authConfig?.signUpPage?.stepCheckEmail?.title || authConfigSignUpPageDefaultValues.stepCheckEmail?.title,
                subtitle: authConfig?.signUpPage?.stepCheckEmail?.subtitle || authConfigSignUpPageDefaultValues.stepCheckEmail?.subtitle,
                textBottom:
                  authConfig?.signUpPage?.stepCheckEmail?.textBottom || authConfigSignUpPageDefaultValues.stepCheckEmail?.textBottom,
              },
              stepErrorLinkExpired: {
                title: authConfig?.signUpPage?.stepErrorLinkExpired?.title || authConfigSignUpPageDefaultValues.stepErrorLinkExpired?.title,
                subtitle:
                  authConfig?.signUpPage?.stepErrorLinkExpired?.subtitle ||
                  authConfigSignUpPageDefaultValues.stepErrorLinkExpired?.subtitle,
                buttonPrimary:
                  authConfig?.signUpPage?.stepErrorLinkExpired?.buttonPrimary ||
                  authConfigSignUpPageDefaultValues.stepErrorLinkExpired?.buttonPrimary,
              },
            });
          }
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    }
  }, []);

  const startSignup = async (values: IValues, setSubmitting: (isSubmitting: boolean) => void, stepID: EnumSteps) => {
    if (signUp) {
      const response = await signUp(values.firstName, values.lastName, values.email, values.token, values.acknowledged);

      setSubmitting(false);
      if (response.success) {
        setRecaptchaFailed(true);
        setStep(EnumSteps.SUBMIT);
        if (resending) {
          toastDispatch({ msg: 'New verification email has been sent.', severity: SEVERITY.SUCCESS, autoClose: true });
          setResending(false);
        }
      } else {
        if (
          response.errorMessages?.length &&
          response.errorMessages.find((msg: any) => msg.errorCode === 'ERROR_SELF_SIGNUP_ALREADY_ONBOARDED')
        ) {
          setStep(EnumSteps.ERROR_ONBOARDED);
        } else if (response.errorMessages?.length && response?.errorMessages.find((msg: any) => msg.errorCode === 'INVALID_EMAIL_DOMAIN')) {
          let message: any = response.errorMessages && response.errorMessages[0].errorMessage;
          toastDispatch({ msg: message, severity: SEVERITY.ERROR, autoClose: true });
        } else {
          setRecaptchaFailed(true);
          setStep(EnumSteps.EMAIL);
        }
      }
    }
  };

  const handleToken = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const goStep = (stepID: EnumSteps, values: Partial<IValues>, actions: any) => {
    window.scrollTo(0, 0);
    switch (stepID) {
      case EnumSteps.EMAIL:
        setStep(stepID);
        setSubmittedValues({ ...submittedValues, ...values });
        break;
      case EnumSteps.SUBMIT:
        const token = recaptchaEnabled ? recaptchaToken : 'disabled';
        const merged = { ...submittedValues, email: values.email || '', token };
        setSubmittedValues(merged);
        startSignup(merged, actions.setSubmitting, stepID);
        break;
    }
  };

  const goPasswordless = () => {
    setPswdlessRequested(true);
    if (requestCode) {
      requestCode(submittedValues.email, '');
    }
  };

  const restart = () => {
    setSubmittedValues(newUser);
    setStep(EnumSteps.NAME);
  };

  const currentStep = (step: EnumSteps) => {
    return step < 3 ? step + 1 : step;
  };

  return (
    <Paper className={style?.formContainer || classes.formContainer}>
      {step <= 3 && (
        <Grid container justifyContent="space-between">
          <Typography>Step {currentStep(step)} of 3</Typography>
          <Typography variant="body2" color="primary">
            {signUpPage.textTopRight}
          </Typography>
        </Grid>
      )}

      <Grid container alignItems="center" flexDirection="column">
        <Grid item>
          {step === EnumSteps.NAME && (
            <Formik
              initialValues={submittedValues}
              validate={(values: Partial<IValues>) => {
                let errors: Partial<IValues> = {};
                if (!values.firstName) {
                  errors.firstName = 'First Name is required';
                }
                if (!values.lastName) {
                  errors.lastName = 'Last Name is required';
                }
                if (sectionTOSPolicy && !values.acknowledged) {
                  errors.acknowledged = true;
                }
                return errors;
              }}
              onSubmit={(values, actions) => {
                goStep(EnumSteps.EMAIL, values, actions);
              }}
            >
              {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
                return (
                  <FormContainer>
                    <Grid
                      container
                      flexDirection="column"
                      justifyContent="flex-start"
                      flexWrap="nowrap"
                      className={style?.formInputs || classes.formInputs}
                    >
                      <Box mt={3} mb={1}>
                        <Typography variant="h3">{signUpPage.step1?.title}</Typography>
                      </Box>

                      <Grid item className={style?.inputField || classes.inputField}>
                        <Field
                          component={TextField}
                          name="firstName"
                          id="firstName"
                          label={signUpPage.step1?.textFieldTop?.label}
                          placeholder={signUpPage.step1?.textFieldTop?.placeholder}
                          type="text"
                          variant="outlined"
                          fullWidth
                          autoFocus
                        />
                      </Grid>
                      <Grid item className={style?.inputField || classes.inputField}>
                        <Field
                          component={TextField}
                          name="lastName"
                          id="lastName"
                          label={signUpPage.step1?.textFieldBottom?.label}
                          placeholder={signUpPage.step1?.textFieldBottom?.placeholder}
                          type="text"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      {sectionTOSPolicy && (
                        <Box display="flex" flexDirection="column" mb={2}>
                          <Box display="flex" alignItems="center">
                            <Field
                              component={Checkbox}
                              name="acknowledged"
                              onChange={(evt: any) => setFieldValue('acknowledged', evt.target.checked)}
                            />
                            {sectionTOSPolicy}
                          </Box>
                          {errors.acknowledged && (
                            <Box mx={2}>
                              <Typography variant="caption" sx={{ color: theme.palette.error.main }}>
                                Please acknowledge your agreement.
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid item className={style?.inputField || classes.inputField}>
                        <Button variant="contained" fullWidth color="primary" onClick={submitForm}>
                          Continue <ArrowForwardIcon />
                        </Button>
                      </Grid>
                      {sectionAlreadyHaveAccount && (
                        <Grid container className={style?.formSignin || classes.formSignin}>
                          <Box my={1} display="flex" justifyContent="center">
                            {sectionAlreadyHaveAccount}
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Box my={1} p={1} className={style?.privacy || classes.privacy}>
                        <Typography variant="body2">
                          <strong>{signUpPage.privacyNote?.title}</strong>
                        </Typography>
                        <Typography variant="body2">{signUpPage.privacyNote?.description}</Typography>
                      </Box>
                    </Grid>
                  </FormContainer>
                );
              }}
            </Formik>
          )}
          {step === EnumSteps.EMAIL && (
            <Formik
              initialValues={submittedValues}
              validate={(values: IValues) => {
                let errors: Partial<IValues> = {};
                if (!values.email) {
                  errors.email = 'Email is required';
                }
                if (!EMAIL_REGEX.test(values.email)) {
                  errors.email = 'Please input a valid email address';
                }
                return errors;
              }}
              onSubmit={(values, actions) => {
                goStep(EnumSteps.SUBMIT, values, actions);
              }}
            >
              {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
                const valid = values.email.length > 1 && (recaptchaToken || !recaptchaEnabled);
                return (
                  <FormContainer>
                    <Grid container flexDirection="column" justifyContent="flex-start" className={style?.formInputs || classes.formInputs}>
                      <Box mt={3} mb={1}>
                        <Typography variant="h3">{signUpPage.step2?.title}</Typography>
                      </Box>
                      <Grid item className={style?.inputField || classes.inputField}>
                        <Field
                          component={TextField}
                          name="email"
                          id="email"
                          label={signUpPage.step2?.textField?.label}
                          placeholder={signUpPage.step2?.textField?.placeholder}
                          type="text"
                          fullWidth
                          autoFocus
                          variant="outlined"
                        />
                      </Grid>
                      {recaptchaEnabled && (
                        <Grid item>
                          {recaptchaFailed ? (
                            <Alert severity="error">ReCAPTCHA validation failed. Please try again.</Alert>
                          ) : (
                            <ReCAPTCHA
                              size="normal"
                              sitekey={Util.getEnvVar('REACT_APP_RE_CAPTCHA_V2_SITE_KEY', false, true)}
                              onChange={(token) => handleToken(token)}
                              onExpired={() => handleToken(null)}
                            />
                          )}
                        </Grid>
                      )}
                    </Grid>
                    <Grid container>
                      <Grid item className={style?.inputField || classes.inputField}>
                        <Button type="submit" variant="contained" fullWidth color="primary" disabled={!valid}>
                          Continue <ArrowForwardIcon />
                        </Button>
                      </Grid>
                      <Grid className={style?.formSignin || classes.formSignin}>&nbsp;</Grid>
                      <Grid item>
                        <Box my={1} p={1} className={style?.privacy || classes.privacy}>
                          <Typography variant="body2">
                            <strong>{signUpPage.privacyNote?.title}</strong>
                          </Typography>
                          <Typography variant="body2">{signUpPage.privacyNote?.description}</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </FormContainer>
                );
              }}
            </Formik>
          )}
          {step === EnumSteps.SUBMIT && (
            <Grid container>
              <Grid container flexDirection="column" justifyContent="flex-start">
                <Box mt={3} mb={1}>
                  <Typography variant="h3">{signUpPage.stepSubmit?.title}</Typography>
                </Box>
                <Box>
                  <Typography>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: `Please check your email, <span style="color: ${theme.palette.primary.main}">${submittedValues.email}</span>,
 and follow the instructions to verify your email address.<br>This link will expire after 30 minutes.`,
                      }}
                    />
                  </Typography>
                </Box>
                <Grid container flexDirection="column" alignContent="center" alignItems="center">
                  <img className={style?.emailImg || classes.emailImg} src={imgVerifyEmailSrc} alt="" />
                  <Typography>{signUpPage.stepSubmit?.resendSection?.title}</Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid>
                  <Box my={1} display="flex" justifyContent="center">
                    <Typography variant="body2">{signUpPage.stepSubmit?.resendSection?.subtitle}</Typography>
                  </Box>
                </Grid>
                <Grid item className={style?.inputField || classes.inputField}>
                  <Button
                    type="button"
                    variant="contained"
                    fullWidth={true}
                    color="primary"
                    onClick={() => {
                      if (setResending) {
                        setResending(true);
                      }
                      setStep(EnumSteps.EMAIL);
                    }}
                  >
                    {signUpPage.stepSubmit?.resendSection?.button}
                  </Button>
                </Grid>
                <Grid item>
                  <Box my={1} p={1} className={style?.privacy || classes.privacy}>
                    <Typography variant="body2">
                      <strong>{signUpPage.privacyNote?.title}</strong>
                    </Typography>
                    <Typography variant="body2">{signUpPage.privacyNote?.description}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          {step === EnumSteps.ERROR_ONBOARDED && (
            <Grid container>
              <Box mt={3} mb={1}>
                <Grid container flexDirection="column" alignContent="center" alignItems="center">
                  <img className={style?.emailImg || classes.emailImg} src={imgSystemErrorSrc} alt="" />
                  <Typography variant="h3" paragraph>
                    {signUpPage.stepErrorOnboard?.title}
                  </Typography>
                </Grid>
                <Typography>
                  {signUpPage.stepErrorOnboard?.subtitle} {submittedValues.email}?
                </Typography>
              </Box>
              <Grid item className={style?.inputField || classes.inputField}>
                <Button
                  type="button"
                  variant="contained"
                  fullWidth={true}
                  color="primary"
                  onClick={goPasswordless}
                  disabled={pswdlessRequested}
                >
                  {signUpPage.stepErrorOnboard?.buttonPrimary}
                </Button>
              </Grid>
              <Grid item className={style?.inputField || classes.inputField}>
                <Button type="button" variant="outlined" fullWidth={true} color="primary" onClick={restart}>
                  {signUpPage.stepErrorOnboard?.buttonSecondary}
                </Button>
              </Grid>
              <Box my={1} p={1} className={style?.privacy || classes.privacy}>
                <Typography variant="body2">
                  <strong>{signUpPage.privacyNote?.title}</strong>
                </Typography>
                <Typography variant="body2">{signUpPage.privacyNote?.description}</Typography>
              </Box>
            </Grid>
          )}
          {step === EnumSteps.CHECK_EMAIL && (
            <Grid container direction="column">
              <Typography variant="h4" color="textPrimary" paragraph>
                {signUpPage.stepCheckEmail?.title}
              </Typography>
              <Typography variant="body1" color="textPrimary" paragraph>
                {signUpPage.stepCheckEmail?.subtitle}
              </Typography>
              <Grid container flexDirection="column" alignContent="center" alignItems="center">
                <img className={style?.emailImg || classes.emailImg} src={imgVerifyEmailSrc} alt="" />
                <Typography color="primary">{submittedValues.email}</Typography>
              </Grid>
              <Grid item className={style?.inputField || classes.inputField}>
                <Button type="button" variant="contained" fullWidth={true} color="primary" onClick={restart}>
                  {signUpPage.stepCheckEmail?.textBottom}
                </Button>
              </Grid>
            </Grid>
          )}
          {step === EnumSteps.ERROR_LINKEXPIRED && (
            <Grid container>
              <Box mt={3} mb={2}>
                <Grid container flexDirection="column" alignContent="center" alignItems="center">
                  <img className={style?.emailImg || classes.emailImg} src={imgHourGlassSrc} alt="" />
                  <Typography variant="h3" paragraph>
                    {signUpPage.stepErrorLinkExpired?.title}
                  </Typography>
                </Grid>
                <Typography>{signUpPage.stepErrorLinkExpired?.subtitle}</Typography>
              </Box>
              <Formik
                initialValues={{ text: '' }}
                validate={(values) => {
                  const errors: Partial<IValues> = {};
                  if (!values.text) {
                    errors.text = 'Required: Please enter your email to sign in.';
                  } else if (!EMAIL_REGEX.test(values.text)) {
                    errors.text = 'Invalid Email: Please enter a valid email to sign in.';
                  }
                  return errors;
                }}
                validateOnBlur={false}
                onSubmit={(values, actions) => {
                  if (onSubmitFormLinkExpired) {
                    onSubmitFormLinkExpired(values.text);
                  }
                }}
              >
                {({ submitForm, isSubmitting }) => (
                  <FormContainer>
                    <Field
                      id="emailInput"
                      component={TextField}
                      name="text"
                      label="Email Address"
                      placeholder="me@domain.com"
                      type="text"
                      fullWidth
                      variant="outlined"
                      data-test="input-email"
                    />
                    <Grid item mt={3} className={style?.inputField || classes.inputField}>
                      <Button type="button" variant="contained" fullWidth onClick={submitForm} disabled={isSubmitting}>
                        {signUpPage.stepErrorLinkExpired?.buttonPrimary}
                      </Button>
                    </Grid>
                  </FormContainer>
                )}
              </Formik>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
}
