import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableMeta, MUIDataTableOptions } from 'mui-datatables';
import React, { useState, useContext } from 'react';
import CloseIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { OrgAuthTypes } from 'verid-shared-lib';
import { useOrgService } from 'lib/api/use-org-service';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { FormContainer } from 'components/form/FormContainer';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import { Selector, Util } from 'ui-lib';
import { dialogClasses } from 'style/sharedCssClasses';
const DEBUG = false;
function EditEmailDomainModal(props: {
  editingId: string;
  isUpdate: boolean;
  initialValues: { type: OrgAuthTypes; emailDomain: string } | null;
  onSubmit: (value: any) => void;
  onClose: () => void;
}) {
  console.log('AuthConfigModal: ', props.initialValues);
  const choices: any = Util.idNamePairsFromEnum(OrgAuthTypes);
  return (
    <>
      <Formik
        initialValues={props.initialValues || {}}
        onSubmit={async (values, actions) => {
          props.onSubmit(values);
        }}
      >
        {({ submitForm, values, setFieldValue, errors, touched, validateForm, setFieldTouched }) => {
          return (
            <Box>
              <FormContainer>
                <DialogTitle display="flex" justifyContent="space-between" alignItems="center" minWidth={'90%'}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h5">{props.isUpdate ? ' Update Org Domain' : 'Create Org Domain'}</Typography>
                  </Box>
                  <IconButton style={{ float: 'right' }} onClick={props.onClose} size="large">
                    <CloseIcon sx={dialogClasses.closeIcon} />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Grid item xs={12}>
                    <Selector name="type" items={choices} label="Select User Type" selectMultiple={false} />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      component={TextField}
                      name="emailDomain"
                      label="Email Domain"
                      placeholder="Enter Email Domain"
                      type="text"
                      fullWidth={true}
                      variant="outlined"
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Button color="primary" onClick={() => props.onClose()}>
                      Cancel
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => {
                        props.onSubmit(values);
                      }}
                    >
                      {' '}
                      {props.isUpdate ? ' Update' : 'Create'}
                    </Button>
                  </Box>
                </DialogActions>
              </FormContainer>
            </Box>
          );
        }}
      </Formik>
    </>
  );
}
interface IProps {
  editingId: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  onClose: () => void;
  emailDomain: any;
}
export const EditOrgDomain = (props: IProps) => {
  const [rowsSelected, setRowsSelected] = useState<number[]>([]);
  const [editingDomain, setEditingDomain] = useState(false);
  const [removeDomain, setRemoveDomain] = useState(false);
  const [selectedDomainType, setSelectedDomainType] = useState<OrgAuthTypes | null>(null);
  const [emailDomain, setEmailDomain] = useState<any>(props.emailDomain);
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditOrg');
  const toastDispatch = useContext(ToastDispatchContext);
  const scenarioDataTableOptions: MUIDataTableOptions = {
    pagination: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
    rowsSelected,
    textLabels: {
      body: {
        noMatch: 'No Mapping Found',
      },
    },
  };
  const columns: MUIDataTableColumnDef[] = [
    { name: 'type', label: 'User Type' },
    { name: 'emailDomain', label: 'Email Domain' },
    {
      name: 'edit',
      label: 'Edit',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          const { rowData } = tableMeta;
          return (
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setSelectedDomainType(rowData[0]);
                setEditingDomain(true);
              }}
            >
              Edit
            </Button>
          );
        },
        customHeadRender: () => <td key="edit"></td>,
      },
    },
    {
      name: 'remove',
      label: 'Remove',
      options: {
        customBodyRender: (value: any, tableMeta: MUIDataTableMeta) => {
          console.log('value and tableMeta: ', { value, tableMeta });
          const { rowData } = tableMeta;
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="center">
              <IconButton
                onClick={() => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setSelectedDomainType(rowData[0]);
                  setRemoveDomain(true);
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        },
        customHeadRender: () => <td key="remove"></td>,
      },
    },
  ];
  const data: any = emailDomain
    ? Object.keys(emailDomain).map((type) => ({
        type: type,
        emailDomain: emailDomain[type] || '',
      }))
    : [];
  async function onAddDomain(value: { type: OrgAuthTypes; emailDomain: string }) {
    if (!value.type) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Must be select any user type` });
      return;
    }
    if (!value.emailDomain) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Must be provide Email Domain` });
      return;
    }
    let newEmailDomain = { ...emailDomain, [value.type]: value.emailDomain };
    setEmailDomain(newEmailDomain);
    onClearDomain();
  }
  function onClearDomain() {
    setSelectedDomainType(null);
    if (editingDomain) setEditingDomain(false);
    if (removeDomain) setRemoveDomain(false);
  }
  function onRemoveDomain() {
    if (selectedDomainType === null || !emailDomain || !emailDomain[selectedDomainType]) {
      toastDispatch({ severity: SEVERITY.ERROR, msg: `Must be select any domain type` });
      return;
    }
    const newEmailDomain = { ...emailDomain };
    delete newEmailDomain[selectedDomainType];
    setEmailDomain(newEmailDomain);
    onClearDomain();
  }
  async function onSubmit() {
    if (props.editingId && emailDomain) {
      const updatedResponse = await orgService.orgUpdate(props.editingId, { emailDomain: { ...emailDomain } });
      DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp: updatedResponse });
      if (!updatedResponse || !updatedResponse.data || updatedResponse.errors) {
        errorHandler.handleErrors({ graphQLErrors: updatedResponse.errors });
        return;
      }
      const { id } = updatedResponse.data.orgUpdate;
      if (id) {
        toastDispatch({ severity: SEVERITY.SUCCESS, msg: `Org Domain updated successfully` });
        props.onClose();
      }
    }
  }
  return (
    <>
      <Grid item xs={12}>
        <IconButton style={{ float: 'right' }} onClick={props.onClose} size="large">
          <CloseIcon sx={dialogClasses.closeIcon} />
        </IconButton>
        <MUIDataTable title="" options={scenarioDataTableOptions} columns={columns} data={data} />
        <DialogActions>
          <IconButton
            style={{ float: 'right', background: '#00539D', color: '#ffffff' }}
            onClick={() => {
              setEditingDomain(true);
              setSelectedDomainType(null);
            }}
            size="large"
          >
            <AddIcon />
          </IconButton>
        </DialogActions>
        <DialogActions>
          <Button onClick={() => props.onClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => onSubmit()} color="primary">
            Save
          </Button>
        </DialogActions>
      </Grid>
      <Dialog open={editingDomain} onClose={() => onClearDomain()} maxWidth="sm" fullWidth>
        <EditEmailDomainModal
          onClose={() => onClearDomain()}
          editingId={props.editingId}
          initialValues={!!selectedDomainType ? { type: selectedDomainType, emailDomain: emailDomain[selectedDomainType] } : null}
          isUpdate={!!selectedDomainType && !!emailDomain[selectedDomainType]}
          onSubmit={onAddDomain}
        />
      </Dialog>
      <Dialog maxWidth="sm" fullWidth open={removeDomain}>
        <DialogContent>Are you sure you want to delete</DialogContent>
        <DialogActions>
          <Button onClick={() => onClearDomain()} color="primary">
            No
          </Button>
          <Button color="primary" autoFocus onClick={() => onRemoveDomain()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
