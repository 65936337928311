import { Util } from '../util';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Typography } from '@mui/material';
import { Clear as CloseIcon } from '@mui/icons-material';
import React, { ChangeEvent, useEffect, useState } from 'react';

const cssClasses = {
  img: {
    width: '80%',
    height: 'auto',
    margin: '8px auto 8px',
  },
};

interface IProps {
  showDialog: boolean;
  closeDialog: () => void;
  title: string;
  fileRef: (ref: string, url: string) => void;
}

export const FileUploadDlog = (props: IProps) => {
  const { showDialog, closeDialog, title, fileRef } = props;
  const [file, setFile] = useState<File>();
  const [uploading, setUploading] = useState(false);
  const [fileUrl, setFileUrl] = useState<string>('');

  useEffect(() => {
    if (!showDialog) {
      setFile(undefined);
      setUploading(false);
    }
  }, [showDialog]);

  useEffect(() => {
    if (file) {
      const url = URL.createObjectURL(file);
      setFileUrl(url);
    }
  }, [file]);

  const handleImg = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : undefined;
    if (file) {
      setFile(file);
    }
  };

  const startUpload = async () => {
    if (!file) return;
    setUploading(true);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('access', 'public-device');

    const uploadUrl = Util.getContentUrl('image');
    const response = await fetch(uploadUrl, {
      method: 'POST',
      body: formData,
      credentials: 'include',
      headers: Util.getContentRequestHeader(),
    });
    const respJson = await response.json();
    const contentRefId = respJson.contentRefId;
    fileRef(contentRefId, fileUrl);
  };

  return (
    <Dialog open={showDialog} onClose={closeDialog} fullWidth maxWidth="sm">
      <Paper variant="outlined">
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h4">{title}</Typography>
            <IconButton aria-label="close" onClick={closeDialog} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <input accept="image/*" type="file" onChange={handleImg} />
          {fileUrl && <Box display="flex" justifyContent="center" component="img" src={fileUrl} sx={cssClasses.img} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="primary" variant="outlined">
            Close
          </Button>
          <Button onClick={startUpload} color="primary" variant="contained" disabled={!file || uploading}>
            Upload
          </Button>
        </DialogActions>
      </Paper>
    </Dialog>
  );
};
