import { FormControl, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

export interface SelectorIdName {
  id: string;
  name: string;
}

interface IProps {
  items: SelectorIdName[];
  label?: string;
  disabled?: boolean;
  onChange?: (item: SelectorIdName) => void;
  value?: string;
  styles?: any;
  rootStyles?: any;
}

const DEBUG = false;

export const SelectorNoForm = (props: IProps) => {
  const { items, label, disabled, onChange, styles } = props;
  const [value, setValue] = useState(props.value);
  DEBUG && console.log('SelectorNoForm', 'render', { value, label });
  if (typeof value === 'object') {
    return null; // prevent warning on initial render with value of object
  }
  return (
    <FormControl margin="none" fullWidth sx={props.rootStyles}>
      <Select
        disabled={disabled}
        fullWidth
        value={value}
        sx={{
          select: {
            '& legend': {
              visibility: 'hidden',
              maxWidth: 0.01,
            },
          },
          ...styles,
        }}
        label={label}
        onChange={(e, v) => {
          const value = e.target.value as string;
          setValue(value);
          if (onChange) {
            const item = props.items.find((i) => i.id === value);
            if (item) {
              onChange(item);
            }
          }
        }}
      >
        {items.map((item: any) => (
          <MenuItem data-cy="menuItem" key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
