import { getDate, getMonth, getYear, isValid } from 'date-fns';

export const DEFAULT_DATE_TIME_FORMAT = 'MMM d, yyyy hh:mm aaaa';
export const DEFAULT_DATE_FORMAT = 'MMM d, yyyy';
export const DEFAULT_TIME_FORMAT = 'hh:mm aaaa';
export const DAY_OF_WEEK = 'EEE';
export const SHORT_DATE_OF_MONTH = 'MMM, do';

export interface IDateParts {
  dateYear: string;
  dateMonth: string;
  dateDate: string;
}

export function datePartsToDate(dob: IDateParts): Date | null {
  const dateStr = `${dob.dateYear}-${dob.dateMonth}-${dob.dateDate}T00:00:00.000Z`;
  const date = new Date(dateStr);
  return date.toString() === 'Invalid Date' ? null : date;
}

export function splitDateToParts(dob: string): IDateParts {
  const dateObj = new Date(dob);
  if (dob?.length && isValid(dateObj)) {
    let month = (getMonth(dateObj) + 1).toString();
    if (month.length === 1) {
      month = '0' + month;
    }
    let day = getDate(dateObj).toString();
    if (day.length === 1) {
      day = '0' + day;
    }
    const year = getYear(dateObj).toString();

    return {
      dateYear: year,
      dateMonth: month,
      dateDate: day,
    };
  }
  return {
    dateYear: '',
    dateMonth: '',
    dateDate: '',
  };
}
