import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BackButton } from './BackButton';
import makeStyles from '@mui/styles/makeStyles';

interface IProps {
  title: string;
}

const useClasses = makeStyles({
  navBar: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1010,
    background: '#fff',
  },
});

export function TopBackBtnBar(props: IProps) {
  const classes = useClasses();
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <Box px={2} py={1} className={classes.navBar}>
      <BackButton goBack={goBack} title={props.title} />
    </Box>
  );
}
