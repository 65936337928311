import React, { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material';
import { Routes } from './Routes';
import { blankUserState, UserContext, UserContextUtils, UserState } from 'lib/auth/user-context';
import { theme } from './style/theme';
import { Toaster } from 'ui-lib';

function App() {
  const [userState, setUserState] = useState<UserState>(blankUserState);

  return (
    <ThemeProvider theme={theme}>
      <Toaster>
        <UserContext.Provider value={{ userState, setUserState, $: new UserContextUtils(userState.me) }}>
          <Routes />
        </UserContext.Provider>
      </Toaster>
    </ThemeProvider>
  );
}

export default App;
