import { Box, Button, FormControl, Grid, LinearProgress, Paper, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Formik, FormikValues } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import verifyEmail from '../../assets/verify-email.svg';

import { FormContainer } from '../../components/form/FormContainer';
import { ClientLogger } from '../../lib/client-logger';

import { useAuthService } from '../../lib/api/use-auth-service';
import { SEVERITY, ToastDispatchContext, EMAIL_REGEX } from 'ui-lib';

import { theme } from '../../style/theme';

interface IValues {
  email: string;
}

const DEBUG = false;

const useClasses = makeStyles({
  formContainer: {
    margin: '20vh auto 0 auto',
    width: 400,
    maxWidth: '90vw',
    position: 'relative',
    minHeight: '20vh',
    padding: 24,
    [theme.breakpoints.down('sm')]: {
      margin: '4px auto',
      padding: '48px 16px 16px 16px',
    },
  },
  itemHolder: {
    marginBottom: 16,
    width: '100%',
  },
  formField: {
    marginBottom: 12,
  },
});

export function ResetPasswordStart() {
  const classes = useClasses();
  const navigate = useNavigate();
  const toastDispatch = useContext(ToastDispatchContext);
  const authApi = useAuthService();
  const [requestSent, setRequestSent] = useState(false);

  const handleSubmit = async (values: FormikValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    const debugLocation = 'ResetPasswordStart.handleSubmit';
    ClientLogger.debug(debugLocation, 'start', DEBUG);

    try {
      const result = await authApi.passwordResetStart(values.email);
      if (result) {
        setRequestSent(true);
      } else {
        toastDispatch({ msg: 'Invalid email or password', severity: SEVERITY.ERROR, autoClose: false });
      }
      setSubmitting(false);
    } catch (e) {
      ClientLogger.error('Login', 'Error', e);
      // toast
      toastDispatch({ msg: 'Login failed', severity: SEVERITY.ERROR });
      setSubmitting(false);
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center">
      <Paper className={classes.formContainer}>
        <Grid container justifyContent="center">
          {!requestSent ? (
            <Grid item className={classes.itemHolder}>
              <Grid container direction="column" justifyContent="center">
                <Box mt={1} mb={3}>
                  <Typography variant="h3">Reset Password</Typography>
                </Box>
                <Formik
                  initialValues={{
                    email: '',
                    password: '',
                  }}
                  validate={(values) => {
                    const errors: Partial<IValues> = {};
                    if (!values.email) {
                      errors.email = 'Required';
                    } else if (!EMAIL_REGEX.test(values.email)) {
                      errors.email = 'Invalid email address';
                    }
                    return errors;
                  }}
                  onSubmit={handleSubmit}
                >
                  {({ submitForm, isSubmitting }) => (
                    <FormContainer>
                      <FormControl fullWidth required variant="filled">
                        <Field
                          component={TextField}
                          name="email"
                          type="text"
                          label="Email Address"
                          data-test="input-email"
                          variant="outlined"
                          className={classes.formField}
                        />
                      </FormControl>
                      {isSubmitting && <LinearProgress />}

                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={isSubmitting}
                        onClick={submitForm}
                        fullWidth
                        data-test="button-sign-in"
                      >
                        Send Reset Link
                      </Button>
                    </FormContainer>
                  )}
                </Formik>
              </Grid>
            </Grid>
          ) : (
            <>
              <Grid container flexDirection="column">
                <Typography variant="h4" color="textPrimary">
                  Check your email
                </Typography>
                <Box my={2}>
                  <Typography variant="body1" color="textPrimary">
                    We have sent you an email to set or change your Healix password.
                  </Typography>
                </Box>
                <Grid container justifyContent="center">
                  <img
                    style={{
                      width: 100,
                      marginBottom: 12,
                    }}
                    src={verifyEmail}
                    alt=""
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
}
