import * as _ from 'lodash';

const DEBUG = false;

export interface PackedFieldValue {
  entityType: string;
  category: string;
  fieldName: string;
}

export class TemplateUtil {
  public static enumDisplayName(enumText: string): string {
    return _.startCase(enumText?.toLowerCase());
  }
  public static allRoles(): any {
    // ToDo: Should reference template revision roles object or Prof Design table

    const oldEnum = ['EXTERNALLY_PROVIDED', 'LOCALLY_PROVIDED', 'OPEN_ID', 'OAUTH', 'SAML'];

    return oldEnum.map((key) => {
      return { id: key, name: TemplateUtil.enumDisplayName(key) };
    });
  }
}

export class AuthConfigUtil {
  public static convertArrayToString(values: string[]): string {
    if (typeof values == 'string') {
      return values;
    } else if (!values.length) {
      return '';
    }
    return values.join(', ');
  }

  public static convertStringToArray(value: string): string[] {
    if (typeof value == 'object') {
      return value;
    } else if (!value) {
      return [];
    }
    const valArray = value.toUpperCase().split(',');
    return valArray.map((value) => {
      return value.trim();
    });
  }
}
