import { ApolloQueryResult, FetchResult, gql, QueryHookOptions, QueryResult } from '@apollo/client';
import {
  Status,
  verificationMethodQuery,
  verificationMethodCreateMutation,
  VerificationMethodCreateInput,
  verificationMethodCreateMutationVariables,
  verificationMethodsQuery,
  verificationMethodsQueryVariables,
  VerificationMethodType,
  verificationMethodUpdateMutation,
  VerificationMethodUpdateInput,
  verificationMethodUpdateMutationVariables,
  verificationMethodQueryVariables,
  Org,
} from './api-types';
import { useApi } from './use-api';
import { useAuthService } from './use-auth-service';
import { ORG_FIELDS } from './use-org-service';

export interface VerificationMethod {
  name: string;
  description: string | null;
  orgId: string;
  org?: Org;
  type: VerificationMethodType;
  status: Status;
  requiresPicture: boolean;
  instructions: string | null;
  idTypeIds: string[] | null;
  openIdConfig?: string | null;
}

export const VERIFICATION_METHOD_FIELDS = gql`
  fragment VerificationMethodFragment on VerificationMethod {
    id
    createdAt
    updatedAt
    name
    description
    orgId
    type
    status
    requiresPicture
    instructions
    openIdConfig
    idTypes {
      id
      description
      label
      placeHolder
      format
      useExpiryDate
      expiryDateFormat
      expiryDateLabel
      expiryDatePlaceHolder
    }
  }
`;

export function useVerificationMethodService() {
  const api = useApi();
  const authService = useAuthService();
  const verificationMethodsQuery = gql`
    ${VERIFICATION_METHOD_FIELDS}
    query verificationMethods($name: String, $orgId: String!, $orderBy: VerificationMethodOrder, $first: Int, $after: String) {
      verificationMethods(query: { name: $name, orgId: $orgId }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...VerificationMethodFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    verificationMethods(orgId: string) {
      return api.query<verificationMethodsQuery, verificationMethodsQueryVariables>({
        query: verificationMethodsQuery,
        variables: {
          orgId: orgId,
          first: 100,
          after: null,
        },
        fetchPolicy: 'network-only',
      });
    },

    useVerificationMethodsQuery(options: QueryHookOptions<verificationMethodsQuery, verificationMethodsQueryVariables> | undefined) {
      function fct(): QueryResult<verificationMethodsQuery, verificationMethodsQueryVariables> {
        return api.useQuery(verificationMethodsQuery, options);
      }
      return fct;
    },

    async verificationMethod(id: string): Promise<ApolloQueryResult<verificationMethodQuery>> {
      return api.query<verificationMethodQuery, verificationMethodQueryVariables>({
        query: gql`
          ${VERIFICATION_METHOD_FIELDS}
          ${ORG_FIELDS}
          query verificationMethod($id: String!) {
            verificationMethod(id: $id) {
              ...VerificationMethodFragment
              org {
                ...OrgFragment
              }
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async verificationMethodCreate(
      verificationMethod: VerificationMethodCreateInput
    ): Promise<FetchResult<verificationMethodCreateMutation>> {
      const retVal = await api.mutate<verificationMethodCreateMutation, verificationMethodCreateMutationVariables>({
        mutation: gql`
          mutation verificationMethodCreate($data: VerificationMethodCreateInput!) {
            verificationMethodCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: verificationMethod },
        refetchQueries: ['verificationMethods'],
      });
      return retVal;
    },

    async verificationMethodUpdate(
      orgId: string,
      org: VerificationMethodUpdateInput
    ): Promise<FetchResult<verificationMethodUpdateMutation>> {
      return api.mutate<verificationMethodUpdateMutation, verificationMethodUpdateMutationVariables>({
        mutation: gql`
          mutation verificationMethodUpdate($id: String!, $data: VerificationMethodUpdateInput!) {
            verificationMethodUpdate(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: { data: org, id: orgId },
        refetchQueries: ['verificationMethods'],
      });
    },
  };
}
