import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useContext, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { dialogClasses, containerClasses } from '../../style/sharedCssClasses';

import { FormContainer } from '../../components/form/FormContainer';
import { ROUTES } from 'Routes';
import { UserContext } from 'lib/auth/user-context';
import { ToastDispatchContext, SEVERITY } from 'ui-lib';
import { useAuthService } from 'lib/api/use-auth-service';
import { ClientLogger } from 'lib/client-logger';

interface IValues {
  password: string;
  confirmPassword: string;
  token: string;
}

const DEBUG = false;

export function ChangePassword() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const toastDispatch = useContext(ToastDispatchContext);
  // const classes = useClasses();
  const authApi = useAuthService();
  const params = useParams();
  const location: any = useLocation();

  const reset_token = location?.state?.reset_token;

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordSet, setPasswordSet] = useState(false);

  const goToLogin = () => {
    navigate(ROUTES.LOGIN);
  };

  DEBUG && ClientLogger.debug('ChangePassword', 'render', { reset_token, params, location });
  return (
    <Grid container flexDirection="column" justifyContent="center">
      <Paper sx={containerClasses.formContainer}>
        <Grid item sx={containerClasses.itemHolder}>
          <Grid container direction="column">
            {!passwordSet ? (
              <>
                <Box mt={1} mb={3}>
                  <Typography variant="h3">Enter a new password</Typography>
                </Box>
                <Formik
                  initialValues={{
                    password: '',
                    confirmPassword: '',
                    token: reset_token || '',
                  }}
                  validate={(values) => {
                    const errors: Partial<IValues> = {};
                    if (values.password != values.confirmPassword) {
                      errors.confirmPassword = 'Passwords do not match';
                    }
                    if (values.password.length < 8) {
                      errors.confirmPassword = 'Password must be at least 8 characters long';
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting }) => {
                    DEBUG && ClientLogger.debug('ChangePassword', 'onSubmit:values', { values });
                    authApi
                      .passwordResetFinish(values.token, values.password)
                      .then((result) => {
                        setSubmitting(false);
                        if (result) {
                          setPasswordSet(true);
                        } else {
                          DEBUG && ClientLogger.debug('changePassword', 'failed');
                          toastDispatch({ msg: 'Failed to change password', severity: SEVERITY.ERROR });
                        }
                      })
                      .catch((e) => {
                        setSubmitting(false);
                        ClientLogger.error('ChangePassword', 'Error', e);
                        toastDispatch({ msg: 'Failed to change password', severity: SEVERITY.ERROR });
                      });
                  }}
                >
                  {({ submitForm, isSubmitting }) => (
                    <FormContainer>
                      <Grid item sx={dialogClasses.inputField}>
                        <Field
                          component={TextField}
                          variant="outlined"
                          type={showNewPassword ? 'text' : 'password'}
                          label="New Password"
                          name="password"
                          fullWidth
                          data-test="input-new-pswd"
                          InputProps={{
                            endAdornment: (
                              <Typography
                                onClick={() => (showNewPassword ? setShowNewPassword(false) : setShowNewPassword(true))}
                                variant="caption"
                              >
                                {showNewPassword ? 'Hide' : 'Show'}
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} sx={dialogClasses.inputField}>
                        <Field
                          fullWidth
                          sx={dialogClasses.inputField}
                          component={TextField}
                          type={showConfirmPassword ? 'text' : 'password'}
                          label="Confirm New Password"
                          name="confirmPassword"
                          variant="outlined"
                          InputProps={{
                            endAdornment: (
                              <Typography
                                onClick={() => (showConfirmPassword ? setShowConfirmPassword(false) : setShowConfirmPassword(true))}
                                variant="caption"
                              >
                                {showConfirmPassword ? 'Hide' : 'Show'}
                              </Typography>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item>
                        <Button type="submit" variant="contained" color="primary" fullWidth disabled={isSubmitting} onClick={submitForm}>
                          Set New Password
                        </Button>
                      </Grid>
                    </FormContainer>
                  )}
                </Formik>
              </>
            ) : (
              <>
                <Box mt={1} mb={2}>
                  <Typography variant="h3">Success</Typography>
                </Box>
                <Box mb={8}>
                  <Typography>Your new password has been successfully set.</Typography>
                </Box>
                <Grid item>
                  <Button type="button" variant="contained" color="primary" fullWidth onClick={goToLogin}>
                    Login
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
