import { useState, ReactNode } from 'react';
import { Button, Grid, Typography, Box, Paper, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ApolloClient } from '@apollo/client';
import { OrgSummary, useVeridService } from '../lib/verid-api/use-verid-service';
import { OrgSelector } from '../selectors';

const DEBUG = false;

interface IStyle {
  container?: any;
  divider?: any;
  separator?: any;
  title?: any;
  containerBtnPrimary?: any;
  btnPrimary?: any;
}

interface IProps {
  veridApolloClient: ApolloClient<any>;
  readFromVerid?: boolean;
  sectionRedirect?: ReactNode;
  theme?: any;
  style?: IStyle;
  onSelectAuthConfig: (orgId: string, authConfig: any) => void;
  titleChooseOrg?: string;
  titleChooseAuth?: string;
}

export function OrgListAuth(props: IProps) {
  const { veridApolloClient, readFromVerid, sectionRedirect, theme, style, onSelectAuthConfig, titleChooseAuth, titleChooseOrg } = props;

  const spacing = theme.spacing(2);

  const useClasses = makeStyles({
    container: {
      maxWidth: '90vw',
      padding: '24px',
      [theme.breakpoints.up('sm')]: {
        marginTop: '48px',
        width: '380px',
      },
    },
    divider: {
      width: '100%',
      margin: '0 10px',
      borderColor: theme.palette.primary.main,
    },
    separator: {
      margin: '12px',
    },
    containerBtnPrimary: {
      width: '100%',
      marginBottom: spacing,
    },
    btnPrimary: {
      padding: `16px 8px`,
    },
  });
  const classes = useClasses();
  const veridService = useVeridService(veridApolloClient);
  const [org, setOrg] = useState<OrgSummary | null>(null);

  const onClickOrg = (selectedOrgId: string) => {
    veridService.getOrg(selectedOrgId).then((response) => {
      setOrg(response.data.org);
    });
  };

  return (
    <Paper elevation={2} className={style?.container || classes.container}>
      {sectionRedirect}
      {sectionRedirect && (
        <Grid container justifyContent="space-around" direction="row" alignItems="center">
          <Divider className={style?.divider || classes.divider} />
          <Typography variant="body1" color="textPrimary" align="center" className={classes.separator}>
            OR
          </Typography>
          <Divider className={classes.divider} />
        </Grid>
      )}
      {org ? (
        org.authConfig && (
          <>
            <Typography variant="h5" color="textPrimary" align="center" fontWeight="bold" className={style?.title}>
              {titleChooseAuth || 'Choose authorization method'}
            </Typography>
            {org.authConfig.map((orgAuthConfig: any) => {
              return (
                <Grid container flexDirection="column" alignItems="center" key={org.id}>
                  <Box my={1} className={style?.containerBtnPrimary || classes.containerBtnPrimary}>
                    <Button
                      className={style?.btnPrimary || classes.btnPrimary}
                      fullWidth={true}
                      key={orgAuthConfig.id}
                      color="primary"
                      variant="contained"
                      disabled={orgAuthConfig.authConfig === null}
                      onClick={() => onSelectAuthConfig(org.id, orgAuthConfig)}
                    >
                      {orgAuthConfig.authName}
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </>
        )
      ) : (
        <>
          <Typography variant="h5" color="textPrimary" align="center" fontWeight="bold" className={style?.title}>
            {titleChooseOrg || 'Choose an Organization'}
          </Typography>
          <OrgSelector
            apolloClientVerid={veridApolloClient}
            readFromVerid={readFromVerid || false}
            selectOrg={(orgId) => onClickOrg(orgId)}
          />
        </>
      )}
    </Paper>
  );
}
