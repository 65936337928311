import { Button, DialogContent, DialogTitle, IconButton, TextField } from '@mui/material';
import React, { useState } from 'react';
import { dialogClasses } from 'style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { PersonOrgSearchResult, SearchCriteria } from './PersonOrgSearchResult';
import { Person } from 'lib/api/use-person-org-serivce';
import { IdName } from 'ui-lib';

interface IProps {
  onClose: () => void;
  orgId?: string;
  onSelect: (person: Person, id?: string) => void;
  buttons?: IdName[];
  onSearch?: (searchCriteria: SearchCriteria) => void;
  role?: string;
  org?: any;
}

export function PersonOrgSearch(props: IProps) {
  const [searchCriteriaEdit, setSearchCriteriaEdit] = useState<SearchCriteria>({});
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({});
  return (
    <>
      <DialogTitle sx={dialogClasses.dialogTitle}>
        Search
        <IconButton onClick={props.onClose} size="large">
          <CloseIcon sx={dialogClasses.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          name="lastName"
          label="Last Name"
          placeholder="Enter letters in the last name"
          type="text"
          fullWidth
          variant="outlined"
          data-test="name-textfield"
          sx={dialogClasses.inputField}
          onChange={(e) => {
            setSearchCriteriaEdit({ ...searchCriteriaEdit, lastName: e.target.value });
          }}
        />
        <TextField
          name="firstName"
          label="First Name"
          placeholder="Enter letters in the first name"
          type="text"
          fullWidth={true}
          variant="outlined"
          data-test="new-patient-middle-name-textfield"
          sx={dialogClasses.inputField}
          onChange={(e) => {
            setSearchCriteriaEdit({ ...searchCriteriaEdit, firstName: e.target.value });
          }}
        />

        <Button
          onClick={() => {
            setSearchCriteria(searchCriteriaEdit);
            if (props.onSearch) {
              props.onSearch(searchCriteriaEdit);
            }
          }}
          variant="contained"
          fullWidth
          color="primary"
          sx={dialogClasses.inputField}
        >
          Search
        </Button>
        <PersonOrgSearchResult
          role={props.role}
          org={props.org}
          searchCriteria={searchCriteria || {}}
          buttons={props.buttons}
          selected={(person, id) => {
            props.onSelect(person, id);
          }}
          onClose={props.onClose}
        />
      </DialogContent>
    </>
  );
}
