import { FetchResult, gql, QueryHookOptions, QueryResult } from '@apollo/client';
import {
  AuthApiKeyCreateInput,
  authApiKeyCreateMutation,
  authApiKeyCreateMutationVariables,
  authApiKeyRevokeMutation,
  authApiKeyRevokeMutationVariables,
  authApiKeysForOrgQuery,
  authApiKeysForOrgQueryVariables,
} from './api-types';
import { authAPiKeys_authApiKeysForOrg_edge_node } from './api-types-parts';
import { useApi } from './use-api';

const DEBUG = false;

export type AuthApiKey = Omit<authAPiKeys_authApiKeysForOrg_edge_node, '__typename'>;

const STANDARD_FIELDS = gql`
  fragment AuthApiKeySummaryStandardFragment on AuthApiKeySummary {
    id
    createdAt
  }
`;

const AUTH_API_KEY_FIELDS = gql`
  fragment AuthApiKeySummaryFragment on AuthApiKeySummary {
    name
    description
    origin
    enabled
    keyType
    expiresAt
  }
`;

export function useAuthApiKeyService() {
  const api = useApi();
  const authApiKeysQuery = gql`
    ${AUTH_API_KEY_FIELDS}
    ${STANDARD_FIELDS}
    query authApiKeysForOrg($query: AuthApiKeySearchInput, $orderBy: AuthApiKeyOrder, $first: Int, $after: String) {
      authApiKeysForOrg(query: $query, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...AuthApiKeySummaryStandardFragment
            ...AuthApiKeySummaryFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useAuthApiKeysForOrgQuery(options: QueryHookOptions<authApiKeysForOrgQuery, authApiKeysForOrgQueryVariables> | undefined) {
      function fct(): QueryResult<authApiKeysForOrgQuery, authApiKeysForOrgQueryVariables> {
        return api.useQuery(authApiKeysQuery, options);
      }
      return fct;
    },

    async authApiKeyCreate(data: AuthApiKeyCreateInput): Promise<FetchResult<authApiKeyCreateMutation>> {
      return await api.mutate<authApiKeyCreateMutation, authApiKeyCreateMutationVariables>({
        mutation: gql`
          mutation authApiKeyCreate($data: AuthApiKeyCreateInput!) {
            authApiKeyCreate(data: $data) {
              id
              value
            }
          }
        `,
        variables: { data: data },
        refetchQueries: ['authApiKeysForOrg'],
      });
    },

    async authApiKeyRevoke(id: string): Promise<FetchResult<authApiKeyRevokeMutation>> {
      return await api.mutate<authApiKeyRevokeMutation, authApiKeyRevokeMutationVariables>({
        mutation: gql`
          mutation authApiKeyRevoke($id: String!) {
            authApiKeyRevoke(id: $id) {
              id
              name
              description
              enabled
            }
          }
        `,
        variables: { id: id },
        refetchQueries: ['authApiKeysForOrg'],
      });
    },
  };
}
