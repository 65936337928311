import { ApolloQueryResult, FetchResult, gql, QueryHookOptions } from '@apollo/client';
import {
  designationQuery,
  designationCreateMutation,
  designationCreateMutationVariables,
  DesignationInput,
  designationsQuery,
  designationsQueryVariables,
  designationUpdateMutation,
  designationUpdateMutationVariables,
  designationQueryVariables,
} from './api-types';

import { designations_designations_edges_node } from './api-types-parts';

import { useApi } from './use-api';

export const DESIGNATION_FIELDS = gql`
  fragment DesignationFragment on Designation {
    id
    createdAt
    updatedAt
    description
    status
  }
`;

export type Designation = Omit<designations_designations_edges_node, '__typename'>;
export type DesignationUpsert = Omit<Designation, 'createdAt' | 'updatedAt'>;

export function useDesignationService() {
  const api = useApi();

  const orgsQuery = gql`
    ${DESIGNATION_FIELDS}
    query designations($id: String, $orderBy: DesignationOrder, $first: Int, $after: String) {
      designations(query: { id: $id }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...DesignationFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useDesignationsQuery(options: QueryHookOptions<designationsQuery, designationsQueryVariables> | undefined) {
      return api.useQuery(orgsQuery, options);
    },

    async designation(id: string): Promise<ApolloQueryResult<designationQuery>> {
      return api.query<designationQuery, designationQueryVariables>({
        query: gql`
          ${DESIGNATION_FIELDS}
          query designation($id: String!) {
            designation(id: $id) {
              ...DesignationFragment
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async designationCreate(designation: DesignationInput): Promise<FetchResult<designationCreateMutation>> {
      return api.mutate<designationCreateMutation, designationCreateMutationVariables>({
        mutation: gql`
          mutation designationCreate($data: DesignationInput!) {
            designationCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: designation },
        refetchQueries: ['designations'],
      });
    },

    async designationUpdate(designation: DesignationInput): Promise<FetchResult<designationUpdateMutation>> {
      return api.mutate<designationUpdateMutation, designationUpdateMutationVariables>({
        mutation: gql`
          mutation designationUpdate($data: DesignationInput!) {
            designationUpdate(data: $data) {
              id
            }
          }
        `,
        variables: { data: designation },
        refetchQueries: ['designations'],
      });
    },
  };
}
