import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AboutIcon from '@mui/icons-material/ErrorOutline';
import HelpIcon from '@mui/icons-material/Help';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Button, Menu, MenuItem, SvgIcon, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ROUTES } from 'Routes';
import MenuIcon from 'assets/hamburger-icon.svg';
import { useAuthService } from 'lib/api/use-auth-service';
import { UserContext } from 'lib/auth/user-context';
import makeStyles from '@mui/styles/makeStyles';

const useClasses = makeStyles({
  buttonOverride: {
    padding: 0,
  },
  menuRow: {
    display: 'grid',
    gridTemplateColumns: '[icon] 20px [spacer] 10px [text] 1fr',
    padding: '20px',
    width: '100%',
    color: 'black',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
      color: '#00539D',
    },
  },
  icon: {
    gridArea: 'icon',
  },
  text: {
    gridArea: 'text',
  },
  logoutWrapper: {
    padding: 16,
  },
  logoutButton: {
    width: '100%',
  },
});

const IconMenuItem = ({ Icon, text, to, ...rest }: { Icon: typeof SvgIcon; text: string; to: ROUTES }) => {
  const classes = useClasses();
  return (
    <Link className={classes.menuRow} to={(to as any) || ''} {...rest}>
      <Icon className={classes.icon} />
      <Typography className={classes.text}>{text}</Typography>
    </Link>
  );
};

export const HamburgerMenu = () => {
  const classes = useClasses();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const authService = useAuthService();
  const userContext = useContext(UserContext);
  const isLoggedIn = userContext.userState.isLoggedIn;
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const menuItemClicked = () => {
    setAnchorEl(null);
  };

  const onHeaderClick = (routes: string) => {
    if (routes == ROUTES.HOME) {
      logOut();
    } else {
      navigate(routes);
    }
  };

  const logOut = () => {
    menuItemClicked();
    authService.logout(ROUTES.HOME);
  };

  return (
    <Box>
      <Button className={classes.buttonOverride} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img src={MenuIcon} alt="Menu" />
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={menuItemClicked}>
        <MenuItem onClick={() => onHeaderClick(ROUTES.PROFILE)}>
          <IconMenuItem Icon={AccountCircleIcon} text="My Profile" to={ROUTES.PROFILE} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.PRIVACY_POLICY)}>
          <IconMenuItem Icon={LockOpenIcon} text="Privacy Policy" to={ROUTES.PRIVACY_POLICY} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.TERMS_OF_USE)}>
          <IconMenuItem Icon={AssignmentIcon} text="Terms of Use" to={ROUTES.TERMS_OF_USE} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.HELP)}>
          <IconMenuItem Icon={HelpIcon} text="Help" to={ROUTES.HELP} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.ABOUT)}>
          <IconMenuItem Icon={AboutIcon} text="About" to={ROUTES.ABOUT} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.SERVER_INFO)}>
          <IconMenuItem Icon={AboutIcon} text="Server Info" to={ROUTES.SERVER_INFO} />
        </MenuItem>
        <MenuItem onClick={() => onHeaderClick(ROUTES.USER_INFO)}>
          <IconMenuItem Icon={AboutIcon} text="User Info" to={ROUTES.USER_INFO} />
        </MenuItem>
        {!isLoggedIn && (
          <MenuItem onClick={() => onHeaderClick(ROUTES.LOGIN)}>
            <IconMenuItem Icon={AboutIcon} text="Login" to={ROUTES.LOGIN} />
          </MenuItem>
        )}
        <Box className={classes.logoutWrapper}>
          <Button className={classes.logoutButton} onClick={() => logOut()} color="primary" variant="contained">
            Sign Out
          </Button>
        </Box>
      </Menu>
    </Box>
  );
};
