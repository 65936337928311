import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const useClasses = makeStyles({
  markdownContainer: {
    fontFamily: "'PoppinsRegular', sans-serif !important",
    padding: 16,
    '& h1, h2, h3, h4, h5': {
      fontWeight: 'bold',
      color: '#005BAA',
    },
    '& img': {
      display: 'block',
      margin: '0 auto',
      maxWidth: '100%',
    },
  },
});

export const MarkdownStyles = ({ children }: { children: React.ReactNode }) => {
  const classes = useClasses();
  return <Box className={classes.markdownContainer}>{children}</Box>;
};
