import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export interface IWndoSize {
  width: number;
  height: number;
}

export const useWindowSize = () => {
  const [wndoSize, setWndoSize] = useState<IWndoSize>({ width: 100, height: 100 });

  useEffect(() => {
    function handleResize() {
      setWndoSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', debounce(handleResize, 200));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return wndoSize;
};
