import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { ApolloClient } from '@apollo/client';
import { useVeridService } from '../lib/verid-api/use-verid-service';
import makeStyles from '@mui/styles/makeStyles';
import { OrgAuthConfigType } from '../lib/verid-api/verid-api-types';

interface IStyle {
  header?: any;
  container?: any;
  title?: any;
  subtitle?: any;
  containerBtnTop?: any;
  containerBtn?: any;
  btnPrimary?: any;
}

interface IProps {
  veridApolloClient: ApolloClient<any>;
  showCreateAccountSection?: boolean;
  theme?: any;
  orgId: string;
  orgAuthId: string;
  style?: IStyle;
  onClickCreateAccount?: () => void;
  onClickSignIn?: (role: string) => void;
}

export function OrgAuth(props: IProps) {
  const { veridApolloClient, theme, orgId, orgAuthId, style, showCreateAccountSection, onClickCreateAccount, onClickSignIn } = props;
  const [orgAuth, setOrgAuth] = useState<any | null>(null);
  const veridService = useVeridService(veridApolloClient);

  const spacing = theme.spacing(2);

  const useClasses = makeStyles({
    header: {
      width: '100%',
      position: 'absolute',
      left: 0,
      right: 0,
      letterSpacing: 0,
    },
    container: {
      maxWidth: '90vw',
      padding: '24px',
      [theme?.breakpoints.up('sm')]: {
        marginTop: '48px',
        width: '380px',
      },
    },
    title: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
    },
    containerBtn: {
      width: '100%',
      marginBottom: spacing,
    },
    btnPrimary: {
      padding: `16px 8px`,
    },
  });

  const classes = useClasses();

  useEffect(() => {
    if (orgId) {
      veridService
        .getOrg(orgId)
        .then((response) => {
          const org = response.data?.org;

          if (org && org.authConfig && org.authConfig.length > 0) {
            const authConfig = org.authConfig.find((item: any) => item.id === orgAuthId);
            setOrgAuth(authConfig);
          }
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    }
  }, []);

  const capName = (name: string) => {
    return name.toLowerCase().charAt(0).toUpperCase() + name.toLowerCase().slice(1);
  };

  return (
    <>
      <Grid item>
        <Typography color="primary" align="center" className={style?.header || classes.header}>
          {orgAuth && orgAuth.landingPage && orgAuth.landingPage.logoSubTitle}
        </Typography>
      </Grid>
      <Paper elevation={2} className={style?.container || classes.container}>
        <Typography align="left" className={style?.title || classes.title}>
          {(orgAuth && orgAuth.landingPage && orgAuth.landingPage.title) || 'Welcome!'}
        </Typography>
        <Typography align="left" className={style?.subtitle}>
          {(orgAuth && orgAuth.landingPage && orgAuth.landingPage.subTitle) || 'Create an account or sign in to continue'}
        </Typography>
        {showCreateAccountSection && (
          <Grid container flexDirection="column" alignItems="center" className={style?.containerBtnTop}>
            <Box my={1} className={style?.containerBtn || classes.containerBtn}>
              <Button
                className={style?.btnPrimary || classes.btnPrimary}
                variant="contained"
                fullWidth={true}
                color="primary"
                onClick={onClickCreateAccount}
              >
                {(orgAuth && orgAuth.landingPage && orgAuth.landingPage.buttonCreateAccount) || 'Create an account'}
              </Button>
            </Box>
          </Grid>
        )}
        {orgAuth &&
          orgAuth.authConfig.type === OrgAuthConfigType.EXTERNALLY_PROVIDED &&
          orgAuth.veridRoles.map((veridRole: any) => {
            return (
              <Grid key={veridRole} container flexDirection="column" alignItems="center">
                <Box my={1} className={style?.containerBtn || classes.containerBtn}>
                  <Button
                    className={style?.btnPrimary || classes.btnPrimary}
                    variant="outlined"
                    fullWidth={true}
                    color="primary"
                    onClick={() => {
                      if (onClickSignIn) {
                        onClickSignIn(veridRole);
                      }
                    }}
                  >
                    {(orgAuth && orgAuth.landingPage && orgAuth.landingPage.buttonSignIn) || 'Sign in as'}
                    {` ${capName(veridRole)}`}
                  </Button>
                </Box>
              </Grid>
            );
          })}
      </Paper>
    </>
  );
}
