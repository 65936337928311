import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql/error/GraphQLError';
import { useContext, useState } from 'react';
import { SEVERITY, ToastDispatchContext } from 'ui-lib';

import { ClientLogger } from './client-logger';

const DEBUG = false;

export function useErrorHandler(location: string) {
  const toastDispatch = useContext(ToastDispatchContext);
  const [errorData, setErrorData] = useState<ErrorData | undefined>(undefined);

  function handleErrors(newErrorData: ErrorData, toastFlag: boolean = true) {
    setErrorData(newErrorData);
    const errors = new ErrorFormatter(newErrorData);
    ClientLogger.error(location, errors.displayErrors());
    toastFlag && toastDispatch({ msg: errors.displayErrors(), severity: SEVERITY.ERROR, autoClose: true });
  }

  function handleQueryResponse(result: ApolloQueryResult<any> | null) {
    if (result) {
      if (result.error) {
        handleErrors({ apolloErrors: result.error, graphQLErrors: result.errors });
      }
      if (result.errors) {
        handleErrors({ graphQLErrors: result.errors });
      }
    } else {
      handleErrors({ error: 'Unexpected null result' });
    }
  }
  function handleMutateResponse(result: FetchResult<any> | null) {
    if (result) {
      if (result.errors) {
        handleErrors({ graphQLErrors: result.errors });
      }
    } else {
      handleErrors({ error: 'Unexpected null result' });
    }
  }

  return { errorData, handleErrors, handleQueryResponse, handleMutateResponse };
}

export interface ErrorData {
  apolloErrors?: ApolloError;
  graphQLErrors?: ReadonlyArray<GraphQLError>;
  errors?: string[];
  error?: any;
}

class ErrorFormatter {
  constructor(public readonly errorData: ErrorData) {}
  public displayErrors(): string {
    const errors: (string | null | undefined)[] = [];
    this.errorData.errors?.forEach((e) => errors.push(JSON.stringify(e)));
    this.errorData.graphQLErrors?.forEach((e) => errors.push(JSON.stringify(e.message)));
    if (this.errorData.apolloErrors) {
      errors.push(ClientLogger.errorToText(this.errorData.apolloErrors));
    }
    if (this.errorData.error) {
      errors.push(ClientLogger.errorToText(this.errorData.error));
    }
    DEBUG && ClientLogger.debug('ErrorFormatter', 'displayErrors', { errorData: this.errorData, errors });
    return errors.join(', ');
  }

  public toastAndLogError(
    toastDispatch: (params: { msg: string; severity: SEVERITY; autoClose?: boolean }) => void,
    location: string,
    whenAttempting: string
  ) {
    if (!this.errorData.errors) {
      this.errorData.errors = [];
    }
    const newMessage = `Error ${whenAttempting} in module ${location}`;
    this.errorData.errors.push(newMessage);
    let msg = this.displayErrors();
    ClientLogger.error(location, msg);
    msg = this.displayErrors();

    toastDispatch({ msg, severity: SEVERITY.ERROR, autoClose: true });
  }
}
