import { Box, Button, Dialog, Grid } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import React, { useContext, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { verificationMethodsQuery } from 'lib/api/api-types';
import { EditVerificationMethod } from './EditVerificationMethod';
import { TopBackBtnBar } from '../../components/navigation/TopBackBtnBar';
import makeStyles from '@mui/styles/makeStyles';
import { tableClasses } from '../../style/sharedCssClasses';
import { SelectorIdName, SelectorNoForm } from 'ui-lib';
import { UserContext } from 'lib/auth/user-context';
import { Loading } from 'components/widgets/Loading';
import { useVerificationMethodService, VerificationMethod } from 'lib/api/use-verification-method-service';

interface IProps {}
const useClasses = makeStyles({ ...tableClasses });

const DEBUG = false;

export const VerificationMethodList = (props: IProps) => {
  const classes = useClasses();
  const verificationMethodService = useVerificationMethodService();
  const [editOrgOpen, setEditOrgOpen] = useState<false | undefined | string>(false); //false not open, undefined open with new, string teamId beiong edited
  const [memberEdit, setMemberEdit] = useState<false | string>(false); //false not open,  string teamId beiong edited
  const userContext = useContext(UserContext);
  const orgs = userContext.$.getOrgs();
  const orgsSelectList: SelectorIdName[] = orgs.map((org) => ({ id: org.id, name: org.name }));
  const [currentOrgId, setCurrentOrgId] = useState<string | undefined>(userContext.$.currentOrgId());
  const [existingOpenIdConfigs, setExistingOpenIdConfigs] = useState<{ id: string; config: string }[]>([]);
  const title = 'Verification Methods';
  const setSelectedOrgId = (orgId: string) => {
    setCurrentOrgId(orgId);
    userContext.$.saveCurrentOrgId(orgId);
  };

  const dataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: true,
    print: true,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    name: { label: 'Name' },
    description: { display: 'excluded' },
    orgId: { display: 'excluded' },
    updatedAt: { display: 'excluded' },
    instructions: { display: 'excluded' },
    requiresPicture: { display: 'excluded' },
    idTypes: { display: 'excluded' },
    createdAt: { label: 'Created At' },
    status: { label: 'Status' },
    type: { label: 'Type' },
  };

  const transformResponseToRows = (resp: verificationMethodsQuery | undefined): Omit<VerificationMethod, 'idTypeIds'> | [] | any => {
    if (!resp || !resp.verificationMethods || !resp.verificationMethods?.edges) {
      return [];
    }
    const configs = resp.verificationMethods.edges
      .map((team) => {
        return {
          id: team.node.id,
          config: team.node.openIdConfig || '',
        };
      })
      .filter((openIdConfig) => !!openIdConfig.config);
    setExistingOpenIdConfigs(configs);

    const retVal: any = resp.verificationMethods.edges.map((team) => ({
      ...team.node,
    }));
    DEBUG && ClientLogger.debug('transformResponseToRows', '', retVal);
    return retVal;
  };

  const rowClicked = (row: any) => {
    setEditOrgOpen(row.id);
  };

  const tableProps: DataTableProps<string, any, verificationMethodsQuery, Omit<VerificationMethod, 'idTypeIds'>> = {
    useQuery: verificationMethodService.useVerificationMethodsQuery({ variables: { orgId: currentOrgId || '' } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: dataTableOptions,
    emptyMessage: 'No teams found. Click the + button to create a new team.',
    onAdd: () => {
      DEBUG && ClientLogger.debug('onAdd', '', {});
      setEditOrgOpen(undefined);
    },
    onRowClick: rowClicked,
  };

  DEBUG && ClientLogger.debug('VerificationMethodList', 'render', { tableProps });

  return (
    <>
      <Grid container>
        <TopBackBtnBar title={title} />
        <Box mx={2} mt={6} mb={1} className={classes.tableHolder}>
          <SelectorNoForm items={orgsSelectList} value={currentOrgId} onChange={(item) => setSelectedOrgId(item.id)} />
          {!currentOrgId ? (
            <Loading />
          ) : (
            <>
              <DataTable {...tableProps} />
            </>
          )}
        </Box>
      </Grid>
      <Dialog open={!(editOrgOpen === false)} onClose={() => setEditOrgOpen(false)} maxWidth="sm" fullWidth>
        <EditVerificationMethod
          onClose={() => {
            setEditOrgOpen(false);
          }}
          editingId={typeof editOrgOpen === 'string' ? editOrgOpen : undefined}
          dialogTitle={title}
          orgId={currentOrgId}
          existingOpenIdConfigs={existingOpenIdConfigs}
        />
      </Dialog>
    </>
  );
};
