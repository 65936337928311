export class Util {
  public static checkValidEmailDomain(orgEmailDomain: any, type: string, email: string | undefined): boolean {
    if (!orgEmailDomain || !orgEmailDomain[type]) {
      return true;
    }
    if (!!orgEmailDomain[type] && !email) {
      return false;
    }
    const enteredDoamin = email.split('@').pop();
    return orgEmailDomain[type] === enteredDoamin;
  }
}
