import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AuthApiKeyCreateInput, authApiKeysForOrgQuery, AuthApiKeyType, OrgType, personOrgsQuery } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useEffect, useRef, useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';
import { dialogClasses, formClasses, tableClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { AuthApiKey, useAuthApiKeyService } from 'lib/api/use-auth-api-key-service';
import { CopyAll } from '@mui/icons-material';
import { ErrorMessage, Field, FieldProps, Formik, FormikHelpers, FormikProps } from 'formik';
import { MUIDataTableOptions } from 'mui-datatables';
import { PersonOrg, PersonOrgsNode, usePersonOrgService } from 'lib/api/use-person-org-serivce';

interface ButtonColumnData {
  id: string;
  enabled: boolean;
}

export interface DisplayRowType extends AuthApiKey {
  buttons: ButtonColumnData;
}

interface IValues {
  name: string;
  description?: string;
  origin: string;
  keyType: string;
  expirationDate?: Date;
  personOrgId: string;
}

interface IProps {
  orgId: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  orgType: OrgType;
  onClose: () => void;
}

const initialValues: IValues = {
  name: '',
  keyType: AuthApiKeyType.ADMIN,
  origin: '',
  personOrgId: '',
};

const DEBUG = false;

const useClasses = makeStyles({ ...formClasses });

export function ManageOrgApiKeys(props: IProps) {
  const { orgId } = props;
  const formRef = useRef<FormikProps<IValues>>(null);
  const authApiKeyService = useAuthApiKeyService();
  const personOrgService = usePersonOrgService();
  const dialogTitle = 'Edit API Keys';
  const [newApiKeyValue, setNewApiKeyValue] = useState<string | undefined>();
  const [newAPiKeyCreatedDialogOpen, setNewApiKeyCreatedDialogOpen] = useState<boolean>(false);
  const [sysAccounts, setSysAccounts] = useState<PersonOrgsNode[]>([]);
  const classes = useClasses();

  const authApiKeysForOrgDataTableOptions: MUIDataTableOptions = {
    pagination: false,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };

  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    name: { label: 'Name' },
    description: { label: 'Description' },
    origin: { label: 'Origin' },
    keyType: { label: 'Key Type' },
    enabled: {
      label: 'Enabled',
      customBodyRender: (value) => {
        return <>{`${value}`}</>;
      },
    },
    createdAt: { label: 'Created At' },
    expiresAt: { label: 'Expires At' },
    buttons: {
      label: 'Action',
      customBodyRender: (buttonData: ButtonColumnData) => {
        if (buttonData !== undefined && buttonData !== null && buttonData.enabled) {
          return (
            <Button
              color="error"
              variant="contained"
              onClick={() => {
                revokeAuthApiKey(buttonData.id);
              }}
            >
              Revoke
            </Button>
          );
        }
      },
    },
  };

  useEffect(() => {
    personOrgService.sysAccountsForOrg(orgId).then((response) => {
      const sysAccounts = transformQueryResultToPersonOrg(response.data);
      setSysAccounts(sysAccounts);
    });
  }, []);

  const transformQueryResultToPersonOrg = (resp: personOrgsQuery): PersonOrgsNode[] => {
    if (!resp || !resp.personOrgs.edges || !resp.personOrgs.edges) {
      return [];
    }

    return resp.personOrgs.edges.map((edge) => ({
      ...edge.node,
    }));
  };

  const revokeAuthApiKey = async (id: string) => {
    await authApiKeyService.authApiKeyRevoke(id);
  };

  const renderSelectApiKeyTypes = () => {
    const authApiKeyTypes = Object.values(AuthApiKeyType);

    return authApiKeyTypes.map((authApiKeyType) => {
      return (
        <MenuItem key={authApiKeyType} value={authApiKeyType}>
          {authApiKeyType}
        </MenuItem>
      );
    });
  };

  const generateNewApiKey = async (values: IValues, { resetForm }: FormikHelpers<IValues>) => {
    DEBUG && ClientLogger.debug('EditOrgApiKeys', `onSubmit values`, values);
    const authAPiKeyCreateInput: AuthApiKeyCreateInput = {
      keyType: AuthApiKeyType[values.keyType],
      name: values.name,
      orgId: orgId,
      origin: values.origin,
      description: values.description,
      personOrgId: values.personOrgId,
    };

    const response = await authApiKeyService.authApiKeyCreate(authAPiKeyCreateInput);
    setNewApiKeyValue(response.data?.authApiKeyCreate.value);
    setNewApiKeyCreatedDialogOpen(true);
    resetForm();
    return;
  };

  const copyNewApiKeyValue = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(newApiKeyValue || '');
    }
  };

  const transformResponseToRows = (resp: authApiKeysForOrgQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.authApiKeysForOrg.edges || !resp.authApiKeysForOrg.edges) {
      return [];
    }
    return resp.authApiKeysForOrg.edges.map((authApiKeyForOrg) => ({
      ...authApiKeyForOrg.node,
      buttons: { id: authApiKeyForOrg.node.id, enabled: authApiKeyForOrg.node.enabled },
    }));
  };

  const validateForm = (values: IValues) => {
    let errors: Partial<IValues> = {};
    if (!values.name) {
      errors.name = 'Required';
    }
    if (!values.origin) {
      errors.origin = 'Required';
    }
    if (!values.keyType) {
      errors.keyType = 'Required';
    }
    if (!values.personOrgId) {
      errors.personOrgId = 'Required';
    }

    return errors;
  };

  const tableProps: DataTableProps<string, any, authApiKeysForOrgQuery, DisplayRowType> = {
    useQuery: authApiKeyService.useAuthApiKeysForOrgQuery({ variables: { query: { orgId: orgId } } }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: {},
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: authApiKeysForOrgDataTableOptions,
  };

  DEBUG && ClientLogger.debug('EditOrgApiKeys', 'render', { props });
  return (
    <Formik innerRef={formRef} initialValues={initialValues} validate={validateForm} onSubmit={generateNewApiKey}>
      {({ submitForm, isSubmitting, errors, values, setFieldValue }) => {
        DEBUG && ClientLogger.debug('EditOrg', 'render form', { errors, values, submitForm });
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h6">Generate New Api Key</Typography>
              <Box mt={2}>
                <Field
                  component={TextField}
                  name="name"
                  label="Name"
                  type="text"
                  fullWidth={true}
                  variant="outlined"
                  sx={dialogClasses.inputField}
                  onChange={(ev) => {
                    setFieldValue('name', ev.target.value);
                  }}
                />
                <ErrorMessage component={Typography} className={classes.formError} name="name" />
              </Box>
              <Box mt={2}>
                <Field
                  component={TextField}
                  name="description"
                  label="Description (Optional)"
                  type="text"
                  fullWidth={true}
                  variant="outlined"
                  sx={dialogClasses.inputField}
                  onChange={(ev) => {
                    setFieldValue('description', ev.target.value);
                  }}
                />
              </Box>
              <Box mt={2}>
                <Field
                  component={TextField}
                  name="origin"
                  label="Origin URL"
                  type="text"
                  fullWidth={true}
                  variant="outlined"
                  sx={dialogClasses.inputField}
                  onChange={(ev) => {
                    setFieldValue('origin', ev.target.value);
                  }}
                />
                <ErrorMessage component={Typography} className={classes.formError} name="origin" />
              </Box>
              <Box mt={2}>
                <Field name="keyType" fullWidth variant="outlined" sx={dialogClasses.inputField}>
                  {({ field }: FieldProps) => (
                    <>
                      <InputLabel id="inputLabelSelectKeyType">Key Type</InputLabel>
                      <Select
                        fullWidth
                        labelId="inputLabelSelectKeyType"
                        defaultValue={AuthApiKeyType.ADMIN}
                        onChange={(ev) => {
                          setFieldValue(field.name, ev.target.value);
                        }}
                      >
                        {renderSelectApiKeyTypes()}
                      </Select>
                    </>
                  )}
                </Field>
                <ErrorMessage component={Typography} className={classes.formError} name="keyType" />
              </Box>
              <Box mt={2}>
                <Field name="personOrgId" fullWidth variant="outlined" sx={dialogClasses.inputField}>
                  {({ field }: FieldProps) => (
                    <>
                      <InputLabel id="inputLabelSelectKeyType">System Account</InputLabel>
                      <Select
                        fullWidth
                        labelId="inputLabelSelectSysAccount"
                        onChange={(ev) => {
                          setFieldValue(field.name, ev.target.value);
                        }}
                      >
                        {sysAccounts.map((sysAccount) => {
                          return (
                            <MenuItem key={sysAccount.id} value={sysAccount.id}>
                              {`${sysAccount.person.firstName} ${sysAccount.person.lastName}`}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </>
                  )}
                </Field>
                <ErrorMessage component={Typography} className={classes.formError} name="personOrgId" />
              </Box>
              <Box mt={2}>
                <Button type="submit" color="primary" disabled={isSubmitting} onClick={submitForm}>
                  Generate
                </Button>
              </Box>
              {newAPiKeyCreatedDialogOpen && (
                <Box mt={2}>
                  <Typography variant="h6">Please store this API Key safely</Typography>
                  <TextField
                    disabled
                    fullWidth
                    value={newApiKeyValue}
                    type="text"
                    InputProps={
                      'clipboard' in navigator
                        ? {
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={copyNewApiKeyValue}>
                                  <CopyAll />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }
                        : {}
                    }
                  ></TextField>
                </Box>
              )}
              <Box mt={12} sx={tableClasses.tableHolder}>
                <Typography variant="h6">Existing Api Keys</Typography>
                <DataTable {...tableProps} />
              </Box>
            </DialogContent>
          </>
        );
      }}
    </Formik>
  );
}
