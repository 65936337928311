import React, { useEffect, useState } from 'react';
import { ROUTES, Routes } from './Routes';
import { ClientLogger } from 'lib/client-logger';
import { useNavigate } from 'react-router-dom';

const DEBUG = false;

function parseQueryString(searchString: string, key: string): string | undefined {
  const query = searchString.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
}
export function QueryChecker() {
  const navigate = useNavigate();

  useEffect(() => {
    const queryStrings = window.location.search;
    const debugLocation = 'Root.useEffect';
    if (queryStrings) {
      DEBUG && ClientLogger.debug(debugLocation, `Detected query strings ${queryStrings}`);
      const reset_token = parseQueryString(queryStrings, 'reset_token');
      if (reset_token) {
        DEBUG && ClientLogger.debug(debugLocation, `Detected reset_token ${reset_token}`);
        navigate(ROUTES.RESET_PASSWORD_FINISH, { state: { reset_token } });
      }
    }
  }, []);

  return <></>;
}
