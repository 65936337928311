import { Button, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Loading } from 'components/widgets/Loading';
import { ErrorMessage, Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { Org, Status, VerificationMethodType, VerificationMethodUpdateInput } from 'lib/api/api-types';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'ui-lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { dialogClasses, formClasses } from '../../style/sharedCssClasses';
import CloseIcon from '@mui/icons-material/Clear';
import { useVerificationMethodService, VerificationMethod } from 'lib/api/use-verification-method-service';
import { VerificationMethodTypeSelector } from 'components/form/VerificationMethodTypeSelector';
import { VerificationOpenIDConfigSelector } from 'components/form/VerificationOpenIDConfigSelector';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'style/reactQuill/style.css';
import { IdTypeSelector } from 'components/form/IdTypeSelector';
import { OrgAuthConfig } from 'verid-shared-lib';
import { useOrgService } from 'lib/api/use-org-service';
import { RichTextField } from 'components/form/RichTextField';

type IValues = Omit<VerificationMethod, 'id' | 'createdAt' | 'updatedAt'>;

interface IProps {
  editingId?: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  onClose: () => void;
  dialogTitle: string;
  orgId?: string;
  existingOpenIdConfigs: { id: string; config: string }[];
}

const DEBUG = false;

const useClasses = makeStyles({ ...formClasses });

export function EditVerificationMethod(props: IProps) {
  const verificationMethodService = useVerificationMethodService();
  const orgService = useOrgService();
  const errorHandler = useErrorHandler('EditTeam');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);
  const [authNames, setAuthNames] = useState<string[]>([]);
  const dialogTitle = props.editingId ? `Edit ${props.dialogTitle} item` : `Add to ${props.dialogTitle}`;
  const classes = useClasses();

  useEffect(() => {
    if (formRef.current) {
      if (props.editingId) {
        verificationMethodService
          .verificationMethod(props.editingId)
          .then((resp) => {
            const verificationMethod: IValues = Util.convertBlanks(resp.data.verificationMethod, null, '');
            delete (verificationMethod as any).__typename;
            const idTypeIds: string[] = [];

            resp.data.verificationMethod.idTypes.forEach((idType) => {
              idTypeIds.push(idType.id);
            });
            verificationMethod.idTypeIds = idTypeIds;
            DEBUG && ClientLogger.debug('EditTeam', 'load verification method', { resp, verificationMethod });
            if (verificationMethod) {
              formRef.current?.setValues(verificationMethod);
            } else {
              errorHandler.handleErrors({ graphQLErrors: resp.errors });
            }
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [props.editingId, formRef.current]);

  useEffect(() => {
    if (props.orgId) {
      orgService
        .org(props.orgId)
        .then((resp) => {
          const org: Org = Util.convertBlanks(resp.data.org, null, '');
          DEBUG && ClientLogger.debug('EditTeam', 'load org authNames', { resp, org: org });
          if (org) {
            const names = getAuthNames(org.authConfig);
            setAuthNames(names);
          } else {
            errorHandler.handleErrors({ graphQLErrors: resp.errors });
          }
          setLoading(false);
        })
        .catch((err) => {
          errorHandler.handleErrors({ error: err });
          setLoading(false);
        });
    }
  }, [props.orgId]);

  async function save(values: IValues) {
    if (props.editingId) {
      const data: VerificationMethodUpdateInput = {
        name: values.name,
        status: values.status,
        description: values.description,
        requiresPicture: values.requiresPicture,
        instructions: VerificationMethodType.MANUAL ? values.instructions : undefined,
        idTypeIds: VerificationMethodType.MANUAL ? values.idTypeIds : undefined,
        openIdConfig: VerificationMethodType.OPEN_ID ? values.openIdConfig : undefined,
      };
      const resp = await verificationMethodService.verificationMethodUpdate(props.editingId, data);

      DEBUG && ClientLogger.debug('EditTean', 'update', { resp });
      if (resp.data?.verificationMethodUpdate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    } else {
      DEBUG && ClientLogger.debug('EditTean', 'creating org');
      const resp = await verificationMethodService.verificationMethodCreate({ ...values });
      DEBUG && ClientLogger.debug('EditTean', 'create', { resp });
      if (resp.data?.verificationMethodCreate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    }
  }

  function getAuthNames(orgAuthConfig: OrgAuthConfig[]) {
    const authNames: string[] = orgAuthConfig.length
      ? orgAuthConfig
          .filter((config: OrgAuthConfig) => config.authConfig?.type === 'OPEN_ID')
          .map((config: OrgAuthConfig) => config.authName)
      : [];
    return authNames;
  }
  DEBUG && ClientLogger.debug('Edit VerificationMethod', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        name: '',
        description: '',
        status: Status.ACTIVE,
        orgId: props.orgId || '',
        requiresPicture: false,
        instructions: '',
        type: VerificationMethodType.PERSONAL_KNOWLEDGE,
        idTypeIds: [],
        openIdConfig: '',
      }}
      validate={(values: IValues) => {
        let errors = {};
        if (values.type === VerificationMethodType.OPEN_ID) {
          const { openIdConfig } = values;
          if (!authNames.length) {
            errors['type'] = 'No OpenID configured to the Org';
          } else if (!openIdConfig) {
            errors['openIdConfig'] = 'Must be selected OpenID configure';
          } else if (!!openIdConfig) {
            const { existingOpenIdConfigs } = props;
            const duplicateConfig = existingOpenIdConfigs.find(
              (existingOpenIdConfig) => props.editingId != existingOpenIdConfig.id && existingOpenIdConfig.config === openIdConfig
            );
            if (duplicateConfig != undefined) {
              errors['openIdConfig'] = 'OpenID configure is already existed';
            }
          }
        }
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('EditTeam', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        DEBUG && ClientLogger.debug('EditTeam', 'render form', { isSubmitting, errors, values, touched, dirty, submitForm });
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle sx={dialogClasses.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon sx={dialogClasses.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="name"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="name-textfield"
                sx={dialogClasses.inputField}
              />
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                placeholder="Enter a description of this organization"
                type="text"
                fullWidth={true}
                variant="outlined"
                data-test="new-patient-middle-name-textfield"
                sx={dialogClasses.inputField}
              />
              <VerificationMethodTypeSelector name="type" disabled={!!props.editingId} />
              <ErrorMessage component={Typography} className={classes.formError} name="type" />
              {values.type === VerificationMethodType.MANUAL && (
                <>
                  <RichTextField name="instructions" label="Instructions" />
                  <IdTypeSelector selectMultiple name="idTypeIds" />
                </>
              )}
              {values.type === VerificationMethodType.OPEN_ID && !!authNames.length && (
                <>
                  <VerificationOpenIDConfigSelector authNames={authNames} name="openIdConfig" />
                  <ErrorMessage component={Typography} className={classes.formError} name="openIdConfig" />
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => submitForm()}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
