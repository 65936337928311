import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import * as React from 'react';

interface IProps {
  messages?: string[];
  showItem?: 'spinner' | 'none';
}

const classes = {
  spinner: {
    marginTop: 36,
    marginBottom: 18,
    alignSelf: 'center',
  },
};
export function Loading(props: IProps) {
  const { messages, showItem } = props;
  const anim = showItem || 'spinner';
  return (
    <Grid container direction="column" alignContent="center">
      {anim === 'spinner' && (
        <Box sx={classes.spinner}>
          <CircularProgress />
        </Box>
      )}
      {messages && (
        <Box>
          {messages.map((message) => {
            return (
              <Typography key={message} align="center">
                {message}
              </Typography>
            );
          })}
        </Box>
      )}
    </Grid>
  );
}
