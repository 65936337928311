import { Typography } from '@mui/material';
import { Field } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

interface IProps {
  name?: string;
  fullWidth?: boolean;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  dataTest?: string;
  required?: boolean;
}

export function Identifier(props: IProps) {
  return (
    <Field
      component={TextField}
      type={'text'}
      label={props.label || 'Identifier'}
      placeholder={props.placeholder}
      name={props.name}
      variant="outlined"
      fullWidth={props.fullWidth || false}
      autoCapitalize="none"
      disabled={props.disabled}
      autoComplete="off"
      data-test={props.dataTest || 'input-work-queue-name'}
      required={props.required}
      onKeyPress={(e: any) => {
        // Prevent anything except letters, numbers, hyphen and underscore from being entered
        if (!e.key.match(/^[a-zA-Z0-9-_]$/)) {
          e.preventDefault();
        }
      }}
      inputProps={{ pattern: '[a-z]{1,15}' }}
    />
  );
}
