import React from 'react';
import { Box } from '@mui/material';

interface IProps {
  sorted: boolean;
  label: string;
}

const cssClasses = {
  hoverLabel: {
    paddingRight: '20px',
    whiteSpace: 'nowrap',
    ':hover': {
      ':after': {
        content: 'url("/assets/sort-asc.svg")',
        position: 'absolute',
        right: '10px',
        top: 0,
      },
    },
  },
};

export const SortableHeaderLabel = (props: IProps) => {
  const { sorted, label } = props;
  if (sorted) {
    return <Box sx={cssClasses.hoverLabel}>{label}</Box>;
  }
  return <Box sx={{ whiteSpace: 'nowrap' }}>{label}</Box>;
};
