import { Grid, Switch as MUISwitch, Typography } from '@mui/material';
import { Field, FieldProps } from 'formik';
import _ from 'lodash';
import * as React from 'react';

interface IProps {
  name: string;
  label: string;
  disabled?: boolean;
}

const DEBUG = false;

export const Switch = (props: IProps) => {
  const { label, name } = props;
  DEBUG && console.debug('Switch', 'render', props);
  return (
    <Grid container direction="row" justifyContent="space-between">
      <Grid>
        <Typography>{label}</Typography>
      </Grid>
      <Grid>
        <Field name={name}>
          {(formikProps: FieldProps) => {
            DEBUG && console.debug('Switch', 'render field', { formikProps });
            const { form } = formikProps;
            return (
              <MUISwitch
                checked={_.get(form.values, name)}
                onChange={(e) => {
                  DEBUG && console.debug('Switch', 'onChange!!', { e });
                  form.setFieldValue(name, e.target.checked);
                }}
                color="primary"
                name={name}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            );
          }}
        </Field>
      </Grid>
    </Grid>
  );
};
