import { ApolloQueryResult, FetchResult, gql, QueryHookOptions, QueryResult } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import {
  teamQuery,
  teamCreateMutation,
  TeamCreateInput,
  teamCreateMutationVariables,
  teamsQuery,
  teamsQueryVariables,
  teamUpdateMutation,
  TeamUpdateInput,
  teamUpdateMutationVariables,
  teamQueryVariables,
} from './api-types';
import { teams_teams_edges_node } from './api-types-parts';
import { useApi } from './use-api';
import { useAuthService } from './use-auth-service';

const DEBUG = true;

export type Team = Omit<teams_teams_edges_node, '__typename'>;
export type TeamEditData = Omit<Team, 'id' | 'createdAt' | 'updatedAt'>;

export const TEAM_FIELDS = gql`
  fragment TeamFragment on Team {
    id
    createdAt
    updatedAt
    name
    description
    orgId
    status
  }
`;

export function useTeamService() {
  const api = useApi();
  const authService = useAuthService();
  const teamsQuery = gql`
    ${TEAM_FIELDS}
    query teams($name: String, $orgId: String!, $orderBy: TeamOrder, $first: Int, $after: String) {
      teams(query: { name: $name, orgId: $orgId }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...TeamFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useTeamQuery(options: QueryHookOptions<teamsQuery, teamsQueryVariables> | undefined) {
      function fct(): QueryResult<teamsQuery, teamsQueryVariables> {
        return api.useQuery(teamsQuery, options);
      }
      return fct;
    },

    async team(id: string): Promise<ApolloQueryResult<teamQuery>> {
      return api.query<teamQuery, teamQueryVariables>({
        query: gql`
          ${TEAM_FIELDS}
          query team($id: String!) {
            team(id: $id) {
              ...TeamFragment
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async teamCreate(team: TeamCreateInput): Promise<FetchResult<teamCreateMutation>> {
      const retVal = await api.mutate<teamCreateMutation, teamCreateMutationVariables>({
        mutation: gql`
          mutation teamCreate($data: TeamCreateInput!) {
            teamCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: team },
        refetchQueries: ['teams'],
      });
      await authService.getMe();
      return retVal;
    },

    async teamUpdate(orgId: string, org: TeamUpdateInput): Promise<FetchResult<teamUpdateMutation>> {
      return api.mutate<teamUpdateMutation, teamUpdateMutationVariables>({
        mutation: gql`
          mutation teamUpdate($id: String!, $data: TeamUpdateInput!) {
            teamUpdate(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: { data: org, id: orgId },
        refetchQueries: ['teams'],
      });
    },
  };
}
