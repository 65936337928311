import { Box } from '@mui/material';
import React from 'react';
import { Header } from './Header';
import { LayoutProps } from './layout-props';
import { layoutStyles } from './layout-styles';
import makeStyles from '@mui/styles/makeStyles';
const useClasses = makeStyles({ ...layoutStyles });

export const LayoutResource = ({ children }: LayoutProps) => {
  const classes = useClasses();
  // TODO: as new UI design is rolled out throughout the app, NoNavbar layout & styling should be replaced with this
  return (
    <Box
      sx={{
        '&::-webkit-scrollbar': {
          display: 'null',
        },
      }}
      id="resources"
    >
      <Header signinLink={false} />
      <Box className={classes.baseBg} />
      <Box>{children}</Box>
    </Box>
  );
};
