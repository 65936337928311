import { Button, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Clear';
import { Loading } from 'components/widgets/Loading';
import { Field, Formik, FormikProps } from 'formik';
import { TextField } from 'formik-mui';
import { Status } from 'lib/api/api-types';
import { Designation, DesignationUpsert, useDesignationService } from 'lib/api/use-designation-service';
import { UserContext } from 'lib/auth/user-context';
import { ClientLogger } from 'lib/client-logger';
import { useErrorHandler } from 'lib/use-error-handler';
import { Util } from 'ui-lib';
import React, { useContext, useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { dialogClasses } from 'verid-ui/src/style/sharedCssClasses';

type IValues = Omit<Designation, 'createdAt' | 'updatedAt'>;

interface IProps {
  editingId?: string; // if editing an existing org, this is the id. Otherwise we're creating a new one
  onClose: () => void;
}

const useClasses = makeStyles({
  ...dialogClasses,
});

const DEBUG = true;

export function EditDesignation(props: IProps) {
  const classes = useClasses();
  const designationService = useDesignationService();
  const errorHandler = useErrorHandler('EditOrg');
  const formRef = useRef<FormikProps<IValues>>(null);
  const [loading, setLoading] = useState(true);
  const userContext = useContext(UserContext);

  useEffect(() => {
    if (formRef.current) {
      if (props.editingId) {
        designationService
          .designation(props.editingId)
          .then((resp) => {
            const designation: DesignationUpsert = Util.convertBlanks(resp.data.designation, null, '');
            delete (designation as any).__typename;
            delete (designation as any).createdAt;
            delete (designation as any).updatedAt;
            DEBUG && ClientLogger.debug('EditDesignation', 'load designation', { resp, org: designation });
            if (designation) {
              formRef.current?.setValues(designation);
            } else {
              errorHandler.handleErrors({ graphQLErrors: resp.errors });
            }
            setLoading(false);
          })
          .catch((err) => {
            errorHandler.handleErrors({ error: err });
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    }
  }, [props.editingId, formRef.current]);

  async function save(values: IValues) {
    if (props.editingId) {
      const resp = await designationService.designationUpdate(values);
      DEBUG && ClientLogger.debug('EditOrg', 'update org', { resp });
      if (resp.data?.designationUpdate.id) {
        props.onClose();
      } else {
        errorHandler.handleErrors({ graphQLErrors: resp.errors });
      }
    } else {
      const jurisdictionId = userContext.$.currentOrgId();
      if (!jurisdictionId) {
        errorHandler.handleErrors({ error: 'No current org' });
      } else {
        const resp = await designationService.designationCreate({ ...values });
        DEBUG && ClientLogger.debug('EditOrg', 'create org', { resp });
        if (resp.data?.designationCreate.id) {
          props.onClose();
        } else {
          errorHandler.handleErrors({ graphQLErrors: resp.errors });
        }
      }
    }
  }

  const dialogTitle = props.editingId ? 'Edit Designation' : 'Add Designation';

  DEBUG && ClientLogger.debug('EditDesignation', 'render', { props, formRef: JSON.stringify(formRef.current) });
  return (
    <Formik
      innerRef={formRef}
      initialValues={{
        id: '',
        description: '',
        status: Status.ACTIVE,
      }}
      validate={(values: IValues) => {
        let errors: Partial<IValues> = {};
        return errors;
      }}
      onSubmit={(values, actions) => {
        DEBUG && ClientLogger.debug('CreatePatient', `onSubmit values`, values);
        save(values);
      }}
    >
      {({ submitForm, isSubmitting, errors, values, setFieldValue, touched, setFieldError, setFieldTouched, dirty }) => {
        if (loading) {
          return <Loading />;
        }
        return (
          <>
            <DialogTitle className={classes.dialogTitle}>
              {dialogTitle}
              <IconButton onClick={props.onClose} size="large">
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Field
                component={TextField}
                name="id"
                label="Name (required)"
                placeholder="Enter name"
                type="text"
                fullWidth
                variant="outlined"
                className={classes.inputField}
                data-test="name-textfield"
                disabled={props.editingId ? true : false}
              />
              <Field
                component={TextField}
                name="description"
                label="Description (optional)"
                placeholder="Enter a description of this organization"
                type="text"
                fullWidth
                variant="outlined"
                className={classes.inputField}
                data-test="new-patient-middle-name-textfield"
              />
            </DialogContent>
            <DialogActions>
              <Button color="primary" disabled={isSubmitting} onClick={() => props.onClose()}>
                Cancel
              </Button>
              <Button type="submit" color="primary" disabled={isSubmitting} onClick={() => save(values)}>
                Save
              </Button>
            </DialogActions>
          </>
        );
      }}
    </Formik>
  );
}
