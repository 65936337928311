import { Selector, IdName, Util } from 'ui-lib';
import { TeamRole } from 'lib/api/api-types';
import * as React from 'react';

interface IProps {
  name: string;
}
export function TeamRoleSelector(props: IProps) {
  const choices: IdName[] = Util.idNamePairsFromEnum(TeamRole);

  return <Selector name={props.name} items={choices} label="Role" />;
}
