import { IdName, Selector, Util } from 'ui-lib';
import * as React from 'react';

interface IProps {
  name: string;
  authNames: string[];
}
export function VerificationOpenIDConfigSelector(props: IProps) {
  const choices: IdName[] = Util.idNamePairsFromArray(props.authNames);

  return <Selector name={props.name} items={choices} label="Verification OpenID Config" />;
}
