import { useContext } from 'react';
import { useLocation, useNavigate, Location, useSearchParams } from 'react-router-dom';
import { ClientLogger } from '../../lib/client-logger';
import { Routes, ROUTES } from '../../Routes';
import { tokenHolder, useAuthService } from '../../lib/api/use-auth-service';
import { SEVERITY, ToastDispatchContext, OrgLogin } from 'ui-lib';
import { theme } from '../../style/theme';
import { getVeridApolloClientWithoutContext } from 'lib/api/apollo-client';

const DEBUG = false;

export function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const toastDispatch = useContext(ToastDispatchContext);
  const authApi = useAuthService();
  const [searchParams, setSearchParams] = useSearchParams();
  const orgId = searchParams.get('orgId');
  const authConfigId = searchParams.get('authConfigId');
  const role = searchParams.get('role');
  const veridApolloClient = getVeridApolloClientWithoutContext();

  const handleSubmit = async (email: string, password: string, setSubmitting: (isSubmitting: boolean) => void) => {
    const debugLocation = 'Login.handleSubmit';
    ClientLogger.debug(debugLocation, 'start', DEBUG);

    try {
      const result = await authApi.login({
        email,
        password,
        orgId,
        authConfigId,
        role,
      });
      if (result) {
        const userId = tokenHolder.jwt?.userId || tokenHolder.jwt?.sub;
        DEBUG && ClientLogger.debug(debugLocation, 'success', { userId, result, tokenHolder });
        if (!userId) {
          toastDispatch({ severity: SEVERITY.ERROR, msg: 'Login did not set user id' });
        } else {
          const me = await authApi.getMe();
        }
        // Check if user was redirected to login - if current location has 'from' in state, send user back where they came from
        const redirectedFrom: Location | undefined = (location.state as { from?: Location })?.from;
        if (!!redirectedFrom) {
          DEBUG && ClientLogger.debug(debugLocation, 'redirecting to', redirectedFrom.pathname);
          navigate(redirectedFrom.pathname, redirectedFrom.state as any);
          return;
        }

        ClientLogger.debug('Login.handleSubmit', 'redirect HOME', DEBUG);
        navigate(ROUTES.HOME);
        return;
      } else {
        toastDispatch({ msg: 'Invalid email or password', severity: SEVERITY.ERROR, autoClose: false });
      }
    } catch (e) {
      ClientLogger.error('Login', 'Error', e);
      // toast
      toastDispatch({ msg: 'Login failed', severity: SEVERITY.ERROR });
      setSubmitting(false);
    }
  };

  return (
    <OrgLogin
      veridApolloClient={veridApolloClient}
      orgId={orgId}
      authConfigId={authConfigId}
      theme={theme}
      onSubmit={handleSubmit}
      showResetPassword
    />
  );
}
