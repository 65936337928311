import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import React, { useState } from 'react';
import { ColumnAttributes, DataTable, DataTableProps } from 'ui-lib';

import { ClientLogger } from 'lib/client-logger';
import { Org, useOrgService } from 'lib/api/use-org-service';
import { OrgRelation, OrgRelationSearchInput, OrgType, orgsQuery } from 'lib/api/api-types';
import { tableClasses } from '../../style/sharedCssClasses';
import { OrgSelector } from 'components/form/OrgSelector';

interface ButtonColumnData {
  id: string;
  name: string;
}

export interface DisplayRowType extends Org {
  buttons: ButtonColumnData;
}

interface OrgRelationWhere {
  relation?: OrgRelationSearchInput;
}

interface IProps {
  org1Id?: string;
  org2Id?: string;
  orgRelation: OrgRelation;
}

const DEBUG = true;

export const OrgRelationList = (props: IProps) => {
  const orgsService = useOrgService();
  const [removeOpen, setRemoveOpen] = useState<false | ButtonColumnData>(false); //false not open, ButtonColumnData  thing being removed
  const [addOrgOpen, setAddOrgOpen] = useState<boolean>(false);
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>(undefined);
  const orgType: OrgType = ((orgRelation: OrgRelation) => {
    switch (orgRelation) {
      case OrgRelation.PROVIDER1_APP2: {
        if (props.org2Id) {
          return OrgType.PROVIDER;
        } else {
          return OrgType.APP;
        }
      }
      case OrgRelation.PROVIDER1_JURISDICTION2: {
        if (props.org2Id) {
          return OrgType.PROVIDER;
        } else {
          return OrgType.JURISDICTION;
        }
      }
      default:
        throw new Error(`OrgRelationList: unknown orgRelation ${orgRelation}`);
    }
  })(props.orgRelation);

  async function addRelation(addedOrgId: string) {
    const org1Id = props.org1Id || addedOrgId;
    const org12d = props.org2Id || addedOrgId;
    const resp = await orgsService.orgOrgCreate(props.orgRelation, org1Id, org12d);
  }

  async function removeRelation(addedOrgId: string) {
    const org1Id = props.org1Id || addedOrgId;
    const org12d = props.org2Id || addedOrgId;
    const resp = await orgsService.orgOrgDelete(props.orgRelation, org1Id, org12d);
  }

  const orgsDataTableOptions: MUIDataTableOptions = {
    pagination: true,
    download: false,
    print: false,
    filter: false,
    search: false,
    viewColumns: false,
    sort: true,
    selectableRows: 'none',
  };
  const columns: ColumnAttributes = {
    id: { display: 'excluded' },
    name: { label: 'Name' },
    updatedAt: { display: 'excluded' },
    emailDomain: { display: 'excluded' },
    externalId: { display: 'excluded' },
    isTest: { display: 'excluded' },
    attributes: { display: 'excluded' },
    authConfig: { display: 'excluded' },
    extMessageConfig: { display: 'excluded' },
    type: { display: 'excluded' },
    autoOnboard: { display: 'excluded' },
    description: { display: 'excluded' },
    createdAt: { label: 'Created At' },
    status: { display: 'excluded' },
    buttons: {
      label: '',
      customBodyRender: (value: ButtonColumnData) => {
        if (value !== undefined && value !== null) {
          return (
            <Box display="flex" justifyContent="space-evenly" alignItems="center">
              <IconButton
                onClick={() => {
                  DEBUG && ClientLogger.debug('custom render click', '', value);
                  setRemoveOpen(value);
                }}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          );
        } else {
          return <></>;
        }
      },
      customHeadRender: () => <td key="edit"></td>,
    },
  };

  const transformResponseToRows = (resp: orgsQuery | undefined): DisplayRowType[] => {
    if (!resp || !resp.orgs || !resp.orgs?.edges) {
      return [];
    }
    return resp.orgs.edges.map((org) => ({ ...org.node, buttons: { id: org.node.id, name: org.node.name } }));
  };

  const tableProps: DataTableProps<OrgRelationWhere, any, orgsQuery, DisplayRowType> = {
    useQuery: orgsService.useOrgsQueryFactory({
      variables: { relation: { type: props.orgRelation, org1Id: props.org1Id, org2Id: props.org2Id } },
      fetchPolicy: 'network-only',
    }) as any, // https://github.com/tannerlinsley/react-query/issues/1675
    initialQuery: { where: { relation: { type: props.orgRelation, org1Id: props.org1Id, org2Id: props.org2Id } } },
    transformResponseToRows,
    columnAttributes: columns,
    tableAttributes: orgsDataTableOptions,
  };

  DEBUG && ClientLogger.debug('OrgList', 'render', { tableProps, props });

  return (
    <>
      <Grid container>
        <Box sx={tableClasses.tableHolder}>
          <DataTable {...tableProps} />
          <DialogActions>
            <IconButton style={{ float: 'right', background: '#00539D', color: '#ffffff' }} size="large">
              <AddIcon
                onClick={() => {
                  setAddOrgOpen(true);
                }}
              />
            </IconButton>
          </DialogActions>
        </Box>
      </Grid>
      <Dialog open={!!removeOpen} onClose={() => setRemoveOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Remove {removeOpen && removeOpen.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Are you sure you want to remove {removeOpen && removeOpen.name}?</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setRemoveOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={(item) => {
              DEBUG && ClientLogger.debug('OrgList', 'org remove related', { item });
              setRemoveOpen(false);
              if (removeOpen) {
                removeRelation(removeOpen.id);
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addOrgOpen} onClose={() => setRemoveOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Add a {orgType}</DialogTitle>
        <DialogContent>
          <OrgSelector
            onChange={(selected) => {
              DEBUG && ClientLogger.debug('OrgList', 'org add related', { selected });
              setSelectedOrgId(selected.id);
            }}
            orgType={orgType}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddOrgOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedOrgId}
            onClick={(item) => {
              DEBUG && ClientLogger.debug('OrgList', 'org add related', { item });
              setAddOrgOpen(false);
              if (selectedOrgId) {
                addRelation(selectedOrgId);
              }
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
