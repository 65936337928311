import { ApolloQueryResult, FetchResult, gql, QueryHookOptions, QueryResult } from '@apollo/client';
import { ClientLogger } from 'lib/client-logger';
import {
  teamMemberQuery,
  teamMemberCreateMutation,
  TeamMemberCreateInput,
  teamMemberCreateMutationVariables,
  teamMembersQuery,
  teamMembersQueryVariables,
  teamMemberUpdateMutation,
  TeamMemberUpdateInput,
  teamMemberUpdateMutationVariables,
  teamMemberQueryVariables,
} from './api-types';

import { teamMembers_teamMembers_edges_node } from './api-types-parts';
import { useApi } from './use-api';
import { useAuthService } from './use-auth-service';

const DEBUG = true;

export type TeamMember = Omit<teamMembers_teamMembers_edges_node, '__typename'>;
export type TeamMemberEditData = 'status' | 'role';

export const TEAM_MEMBER_FIELDS = gql`
  fragment TeamMemberFragment on TeamMember {
    id
    createdAt
    updatedAt
    status
    role
    title
    firstName
    middleName
    lastName
    orgName
  }
`;

export function useTeamMemberService() {
  const api = useApi();
  const authService = useAuthService();
  const teamMembersQuery = gql`
    ${TEAM_MEMBER_FIELDS}
    query teamMembers($firstName: String, $lastName: String, $teamId: String!, $orderBy: TeamMemberOrder, $first: Int, $after: String) {
      teamMembers(query: { firstName: $firstName, lastName: $lastName, teamId: $teamId }, orderBy: $orderBy, first: $first, after: $after) {
        edges {
          cursor
          node {
            ...TeamMemberFragment
          }
        }
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        totalCount
      }
    }
  `;
  return {
    useTeamMemberQuery(options: QueryHookOptions<teamMembersQuery, teamMembersQueryVariables> | undefined) {
      function fct(): QueryResult<teamMembersQuery, teamMembersQueryVariables> {
        return api.useQuery(teamMembersQuery, options);
      }
      return fct;
    },

    async teamMember(id: string): Promise<ApolloQueryResult<teamMemberQuery>> {
      return api.query<teamMemberQuery, teamMemberQueryVariables>({
        query: gql`
          query teamMember($id: String!) {
            teamMember(id: $id) {
              status
              role
            }
          }
        `,
        variables: { id },
        fetchPolicy: 'network-only',
      });
    },

    async teamMemberCreate(team: TeamMemberCreateInput): Promise<FetchResult<teamMemberCreateMutation>> {
      const retVal = await api.mutate<teamMemberCreateMutation, teamMemberCreateMutationVariables>({
        mutation: gql`
          mutation teamMemberCreate($data: TeamMemberCreateInput!) {
            teamMemberCreate(data: $data) {
              id
            }
          }
        `,
        variables: { data: team },
        refetchQueries: ['teamMembers'],
      });
      await authService.getMe();
      return retVal;
    },

    async teamMemberUpdate(teamMemberId: string, teamMember: TeamMemberUpdateInput): Promise<FetchResult<teamMemberUpdateMutation>> {
      return api.mutate<teamMemberUpdateMutation, teamMemberUpdateMutationVariables>({
        mutation: gql`
          mutation teamMemberUpdate($id: String!, $data: TeamMemberUpdateInput!) {
            teamMemberUpdate(id: $id, data: $data) {
              id
            }
          }
        `,
        variables: { data: teamMember, id: teamMemberId },
        refetchQueries: ['teamMembers'],
      });
    },
  };
}
